// extracted by mini-css-extract-plugin
export var Modal = "styles-module--Modal--24TFM";
export var modalBackgroundOverride = "styles-module--modalBackgroundOverride--x1WgC";
export var widthCopySpan = "styles-module--widthCopySpan--1qDy_";
export var playerControlButton = "styles-module--playerControlButton--2RIDY";
export var productButton = "styles-module--productButton--303m6";
export var iconText = "styles-module--iconText--x8VnU";
export var productIconText = "styles-module--productIconText--1gUVU";
export var copyButton = "styles-module--copyButton--2dNcw";
export var shareIconBase = "styles-module--shareIconBase--DOe0A";
export var heightProductIcon = "styles-module--heightProductIcon--2qQIA";
export var shareCard = "styles-module--shareCard--3eDhN";
export var closeButton = "styles-module--closeButton--jAm00";
export var shareIconsContainer = "styles-module--shareIconsContainer--3ZJHl";
export var shareTitle = "styles-module--shareTitle--PCgvY";
export var iconContainer = "styles-module--iconContainer--3QDoe";
export var shareIcon = "styles-module--shareIcon--37zJd";
export var shareInfoContainer = "styles-module--shareInfoContainer--26vBu";
export var shareDetailsContainer = "styles-module--shareDetailsContainer--izVfD";
export var shareImageContainer = "styles-module--shareImageContainer--2nf-5";
export var shareTextGroup = "styles-module--shareTextGroup--2lPD7";
export var shareText = "styles-module--shareText--38yUQ";
export var shareLinkContainer = "styles-module--shareLinkContainer--1IVYL";
export var shareLinkIconContainer = "styles-module--shareLinkIconContainer--3Oc8u";
export var shareSuccessIconContainer = "styles-module--shareSuccessIconContainer--2q6hx";
export var copySuccess = "styles-module--copySuccess--1dhtw";
export var shareLinkIcon = "styles-module--shareLinkIcon--1gIR-";
export var copyInput = "styles-module--copyInput--3uUdM";