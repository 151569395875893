import { BrandUtil, CartItemProps, Util } from '@sharedLib/index';
import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { v4 as uuidv4 } from 'uuid';
import { ClientLogger } from '../ClientLogger';
import { CartItem } from '@sharedLib/product';

const DEBUG = false;

interface OfflineDb extends DBSchema {
  cartItem: {
    value: CartItem;
    key: string;
  };
}

export class ClientStorage {
  private static getClient = async () => {
    const { brandId, branchId } = BrandUtil.getSiteInfo();

    const dbName = `${brandId}-${branchId}-2`;

    DEBUG && ClientLogger.debug('ClientStorage.getClient', `dbName = ${dbName}`);

    // connecting to offline indexedDB
    const db = await openDB<OfflineDb>(dbName, 1, {
      upgrade(db) {
        // creating offline stores
        db.createObjectStore('cartItem'); // Name cycled since we tweaked the id's
      },
    });
    DEBUG && ClientLogger.debug('ClientStorage.getClient', `createStores`);

    return db;
  };

  public static addToCart = async ({
    productId,
    variantId,
    variantName,
    title,
    description,
    price,
    imageUrl,
    quantity,
  }: CartItemProps): Promise<boolean> => {
    const location = 'ClientStorage.addToCart';
    // generating cart item id
    const id = uuidv4();
    const db: IDBPDatabase<OfflineDb> = await ClientStorage.getClient();

    if (!db) {
      // Error Handling if db not connected
      DEBUG && ClientLogger.debug('ClientStorage.addToCart', `could not connect to offline database`);
    }

    // Convert productId to encoded ProductId
    const encodedProductId = Util.convertShopifyTextIdToNumberAndString(atob, productId, 'Product').encoded || '';
    const encodedVariantId = Util.convertShopifyTextIdToNumberAndString(atob, variantId, 'ProductVariant').encoded || '';

    const cartItem = await ClientStorage.findVariant(variantId);
    if (cartItem) {
      return ClientStorage.updateCartItemInBrowserCart(cartItem.id, cartItem.variantId, cartItem.variantName, cartItem.quantity + quantity);
    }

    // Check if product existing in offline store (if yes increment quantity)
    DEBUG && ClientLogger.debug(location, `check if product exists in offline store for variantId = ${variantId}`);
    const data = await db.get('cartItem', id);
    DEBUG && ClientLogger.debug(location, `check if product exists in offline store for variantId = ${variantId}`);
    if (data) {
      // Logging needed
      quantity += data.quantity;
    }

    // Add products to productStore
    const product = await db
      .put(
        'cartItem',
        {
          id,
          productId: encodedProductId,
          variantId: encodedVariantId,
          variantName,
          title,
          description,
          price,
          imageUrl,
          quantity,
        },
        id
      )
      .then((result): string => {
        DEBUG && ClientLogger.debug('ClientStorage.addToCart', `cart item added to offline store = ${result}`);
        return result;
      })
      .catch((error): any => {
        ClientLogger.error('ClientStorage.addToCart', 'error caught', error);
      });

    if (!product) {
      // Error handling for product not added to store
      return false;
    }

    return !!product;
  };

  private static findVariant = async (variantId: string): Promise<CartItem | undefined> => {
    const items = await ClientStorage.getCartItems();
    const cartItem = items.find(i => i.variantId === variantId);
    DEBUG && ClientLogger.debug('ClientStorage.findVariant', '', { variantId, cartItem: cartItem || 'not found', items });
    if (!cartItem) {
      return undefined;
    }
    return cartItem;
  };

  public static removeCartItemInBrowserCart = async (variantId: string): Promise<boolean> => {
    const location = 'ClientStorage.removeCartItemInBrowserCart';
    const db: IDBPDatabase<OfflineDb> = await ClientStorage.getClient();

    if (!db) {
      // Error Handling if db not connected
      DEBUG && ClientLogger.debug(location, `could not connect to offline database`);
    }

    // // Convert productId to encoded ProductId
    // const encodedVariantId = Util.convertShopifyTextIdToNumberAndString(atob, variantId, 'ProductVariant').encoded || '';
    const cartItem = await ClientStorage.findVariant(variantId);
    if (!cartItem) {
      const errorMessage = `Expected to find variant id ${variantId} in cart. Cannot remove ${JSON.stringify(
        { variantId, items: await ClientStorage.getCartItems() },
        null,
        2
      )}`;
      ClientLogger.error(location, errorMessage);
      return false;
    }
    const cartItemId = cartItem.id;

    DEBUG && ClientLogger.debug(location, 'deleting', { cartItem, cartItemId });

    // Remove products from productStore
    const result = await db
      .delete('cartItem', cartItemId)
      .then(result => {
        DEBUG && ClientLogger.debug(location, `result = ${JSON.stringify(result)}`);
        return true;
      })
      .catch(error => {
        ClientLogger.error(location, 'error caught', error);
        return false;
      });

    return result;
  };

  public static updateCartItemInBrowserCart = async (cartItemId: string, variantId: string, variantName: string, quantity: number) => {
    const db: IDBPDatabase<OfflineDb> = await ClientStorage.getClient();

    if (!db) {
      // Error Handling if db not connected
      DEBUG && ClientLogger.debug('ClientStorage.updateCartItemInBrowserCart', `could not connect to offline database`);
    }

    // Check if product existing in offline store (if yes increment quantity)
    DEBUG &&
      ClientLogger.debug(
        'ClientStorage.updateCartItemInBrowserCart',
        `check if product exists in offline store for variantId = ${cartItemId}`,
        DEBUG
      );
    let data = await db.get('cartItem', cartItemId);
    DEBUG &&
      ClientLogger.debug(
        'ClientStorage.updateCartItemInBrowserCart',
        `check if product exists in offline store for variantId = ${cartItemId}`,
        DEBUG
      );

    if (!data) {
      ClientLogger.error(
        'ClientStorage.updateCartItemInBrowserCart',
        `product does not exists in offline store for variantId = ${cartItemId}`
      );
      return false;
    }

    // update data for item
    data.variantId = variantId;
    data.quantity = quantity;
    data.variantName = variantName;

    // check if this variant is already present in cart
    const allItems = await db.getAll('cartItem');
    const existingItems = allItems.filter(item => {
      return item.id !== data?.id && item.productId === data?.productId && item.variantId === data.variantId;
    });

    // if present, merge their quantities and remove the redundant one
    if (existingItems.length) {
      const item = existingItems[0];
      item.quantity = item.quantity + data.quantity;

      const product = await db.put('cartItem', { ...item }, item.id);
      await db.delete('cartItem', data.id);
      return Boolean(product);
    }

    // no existing variant found, continue updating item
    const product = await db
      .put(
        'cartItem',
        {
          ...data,
        },
        cartItemId
      )
      .then(result => {
        DEBUG && ClientLogger.debug('ClientStorage.updateCartItemInBrowserCartResp', `result = ${JSON.stringify(result)}`);
        return result;
      })
      .catch(error => {
        ClientLogger.error('ClientStorage.updateCartItemInBrowserCartResp', 'error caught', error);
      });

    if (!product) {
      // Error handling for product not updated in store
      return false;
    }
    return true;
  };

  public static getCartItems = async (): Promise<CartItem[]> => {
    const db: IDBPDatabase<OfflineDb> = await ClientStorage.getClient();

    if (!db) {
      // Error Handling if db not connected
      DEBUG && ClientLogger.debug('ClientStorage.getCartItems', `could not connect to offline database`);
    }

    // Fetch cart items from offline store
    try {
      const resp = await db.getAll('cartItem');

      if (!resp) {
        // Error Handling if failed to fetch data from store
        DEBUG && ClientLogger.debug('ClientStorage.getCartItems', `failed to fetch data from store`);
      }

      DEBUG && ClientLogger.debug('ClientStorage.getCartItems', `resp`, DEBUG, resp);
      return resp || [];
    } catch (e) {
      DEBUG && ClientLogger.debug('ClientStorage.getCartItems', `error caught ${ClientLogger.errorToText(e)}`);
      return [];
    }
  };
}
