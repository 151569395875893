import React from 'react';
import { DatabaseVideo, BrandUtil, Util } from '@sharedLib/index';
import { VideoPlayButton } from '@components/VideoPreviewCard/VideoPlayButton';
import {
  seriesVideoCardWrapper,
  seriesVideoCard,
  videoCardImage,
  videoThumbnail,
  seriesText,
  videoTitle,
  videoDescription,
} from './styles.module.scss';

interface Props {
  video: DatabaseVideo;
  onClick: (video: DatabaseVideo) => void;
}

const DEBUG = false;

export const SeriesVideoCard = ({ video, onClick }: Props) => {
  const brandInfo = BrandUtil.getSiteInfo();
  const baseImageUrl = brandInfo.imageBaseUrl;
  const thumbnailUrl = video.thumbnailUrl ? `${baseImageUrl}/videos/${video.thumbnailUrl}` : `${brandInfo.siteUrl}/fallback.png`;

  return (
    <div className={seriesVideoCardWrapper} data-tooltip={video.description} data-cy-video-id={video.id}>
      <div className={seriesVideoCard} data-cy={`series-video-card-${video.id}`}>
        <div className={videoCardImage} onClick={() => onClick(video)} data-cy={`series-video-card-image-container-${video.id}`}>
          <VideoPlayButton iconProps={{ height: '60px' }} />

          <img className={videoThumbnail} src={thumbnailUrl} alt="" />
        </div>
        <div className={seriesText} data-cy={`series-video-card-text-container-${video.id}`}>
          <p className={videoTitle} data-cy={`series-video-card-title-${video.id}`}>
            {video.label || video.title}
          </p>
          <p className={videoDescription} data-cy={`series-video-card-description-${video.id}`}>
            {video.description}
          </p>
        </div>
      </div>
    </div>
  );
};
