import { Util } from '../../src-shared-lib';
import { isBrowser } from './build';
import { serializeError } from 'serialize-error';

export class ClientLogger {
  public static debug(source: string, message: string, DEBUG: boolean | any = true, objectOut?: any) {
    if (DEBUG) {
      if (objectOut && typeof DEBUG === 'boolean') {
        console.log(`${source}:${message}`, objectOut);
      } else if (typeof DEBUG === 'boolean' && !objectOut) {
        console.log(`${source}:${message}`);
      } else if (DEBUG) {
        console.log(`${source}:${message}`, DEBUG);
      } else {
        console.log(`${source}:${message}`);
      }
    }
  }

  public static log(source: string, message: string): void {
    console.log(`${source}: ${message}`);
  }

  public static warning(source: string, message: string): void {
    console.warn(`Warning: ${source} ${message}`);
  }

  public static error(source: string, message: string, e?: any) {
    console.error(`${source}:${message} `);
    if (e) {
      console.error(e);
    }
    if (isBrowser) {
      window.analytics.track('Error Occurred', {
        // Standard
        source,
        message,
        error: JSON.stringify(e),
        brandId: Util.getBrandId(),
        gitBranch: Util.getBranch(),
      });
    }
  }

  public static errorToText(e: any): string {
    try {
      if (e instanceof Error) {
        return JSON.stringify(serializeError(e));
      }
      return JSON.stringify(e);
    } catch (_e) {
      return JSON.stringify(e);
    }
  }
}
