import { ClientLogger } from '@lib/ClientLogger';
import React, { useEffect, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { Formik, FieldArray, Field } from 'formik';
import { Button, ButtonStyles } from '@components/Buttons';
import {
  flexContainer,
  applyFlex,
  spacer,
  errorMessage,
  fullWidth,
  collectionList,
  successfulMessage,
  adminLoader,
  flexRow,
  adminTitle,
  adminText,
  defaultSelectControl,
  alignCenter,
  adminCheckbox,
  adminSubText,
  tableCell,
  halfWidthCell,
  tenthWidthCell,
  gridRightDecorator,
  gridBottomDecorator,
  gridTable,
  fifthWidthCell,
  editorVideoLabel,
  noWrapButton,
} from './styles.module.scss';
import { BrandUtil } from '@sharedLib/util';
import classNames from 'classnames';
import { useProductData } from '@lib/shopify/useProductData';
import { useVideoData } from '@lib/video-query/useVideoData';
import { ShopifyProduct, IndividualAssignment, formatFromSeconds } from '@sharedLib/index';
import { Loading } from '@components/Loading';
import Select from 'react-select';

const DEBUG = false;

interface SuggestedAd {
  productId: string;
  value: number;
  startTime: number;
  videoId: string;
  name: string;
  rank: number;
  source: string;
  approved: boolean;
  conflicts: {
    productId: string;
    id: string;
    name: string;
    message: string;
    start: number;
    projectedEnd: number;
    assignmentId?: string;
    source: string;
  }[];
}

interface ObjectTime {
  timeStamp: number;
  labels: string[];
}

interface ProductMatch {
  time: number;
  matches: {
    productId: string;
    tags: string[];
  }[];
}

const getVideoDataSchema = yup.object().shape({
  video: yup.string().required('Please select a video'),
  collections: yup.array().of(yup.string()),
  includeUncollected: yup.boolean(),
});

export const AutoAdPlacement = () => {
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const allProducts = useProductData().getAllProducts();
  const productCollections = useProductData().getAllProductCollections();
  const videoData = useVideoData().videos;
  const videoIds = videoData.map(video => video.id);

  const [analyzedVideos, setAnalyzedVideos] = useState<string[]>([]);
  const [adSuggestions, setAdSuggestions] = useState<SuggestedAd[]>([]);
  const [adsToDelete, setAdsToDelete] = useState<string[]>([]);
  const [existingAssignments, setExistingAssignments] = useState<IndividualAssignment[]>([]);
  const [loadingError, setLoadingError] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [commitFeedback, setCommitFeedback] = useState('');
  const defaultAdDuration = 30; //seconds

  const videoOptions = analyzedVideos.map(video => {
    return {
      label: video,
      value: video,
    };
  });

  useEffect(() => {
    getAnalyzedVideos();
    getExistingAssignments();
  }, []);

  const getExistingAssignments = async () => {
    // const existingAssignmentsResp = await assignmentsHook.getIndividualAssignments();
    // if (existingAssignmentsResp.success) {
    //   setExistingAssignments(existingAssignmentsResp.individualAssignments);
    //   setLoadingError('');
    // } else {
    //   setExistingAssignments([]);
    //   setLoadingError('Could not load existing individual assignments');
    // }
  };

  // get list of videos with analyses from the S3 bucket
  const getAnalyzedVideos = async () => {
    const bucketObjectsResp = await api.getS3BucketContents('rekognition-video-alibitech', brandInfo.brandId);
    if (!bucketObjectsResp.data?.getS3BucketContents.success) {
      ClientLogger.error(
        'AutoAdPlacement:getAnalyzedVideos',
        `Could not get analyzed videos: returned error ${bucketObjectsResp.data?.getS3BucketContents.errorMessages}`
      );
    } else {
      const bucketContents = bucketObjectsResp.data?.getS3BucketContents.contents;
      const fileNames = bucketContents
        .filter(file => file.fileName?.split('.').pop() === 'json')
        .map(file => file.fileName?.split('.')[0] || '');

      const videoMatches = fileNames.filter(name => videoIds.includes(name));
      setAnalyzedVideos(videoMatches);
    }
  };

  // get the processed data from the analysis and generate ads
  const getVideoData = async (videoId: string, products: ShopifyProduct[]) => {
    setAdSuggestions([]); //reset ads array
    setLoading(true);

    const resp = await api.getProcessedObjectData(brandInfo.brandId, `${videoId}.json`);
    if (resp.data?.getProcessedObjectData.success) {
      const timeline = resp.data?.getProcessedObjectData.timeline;
      generateAds(videoId, products, timeline);
    } else {
      setLoading(false);
      ClientLogger.error(
        'AutoAdPlacement:getVideoData',
        `Could not get video data: returned error ${resp.data?.getProcessedObjectData.errorMessages}`
      );
    }
  };

  //generate ads by matching product tags with labels
  const generateAds = (videoId: string, products: ShopifyProduct[], timeline: ObjectTime[]) => {
    //get existing ads to check for conflicts
    const existingBannerAds = existingAssignments.filter(ad => ad.adType === 'banner');

    let productTimeline: ProductMatch[] = [];

    //at each time in the timeline, check if each product's tags matches its label
    for (const time of timeline) {
      let matches = [];

      for (const product of products) {
        const tags = product.adTags || [];

        for (const tag of tags) {
          const labelIndex = time.labels.findIndex(label => label === tag);
          if (labelIndex !== -1) {
            //if the product is already included in the matches at this time, add to tags array
            //if not included, add a new entry
            const matchIndex = matches.findIndex(match => match.productId === product.id);
            if (matchIndex !== -1) {
              matches[matchIndex].tags.push(tag);
            } else {
              matches.push({
                productId: product.id,
                tags: [tag],
              });
            }
          }
        }
      }
      productTimeline.push({ time: time.timeStamp, matches });
    }

    //sort timeline
    productTimeline.sort((a: { time: number }, b: { time: number }) => a.time - b.time);

    //for each increment, check how many times a product is recommended and how many objects in frame it matches
    let currentInterval = 0;
    let recommendedAds = [];
    while (currentInterval * defaultAdDuration * 1000 < productTimeline[productTimeline.length - 1].time) {
      const productCount: any = {};
      for (const timeStamp of productTimeline) {
        if (timeStamp.time < currentInterval * defaultAdDuration * 1000) continue; // not there yet
        if (timeStamp.time >= (currentInterval + 1) * defaultAdDuration * 1000) break; //too far

        timeStamp.matches.map((entry: any) => {
          if (entry.productId in productCount) {
            productCount[entry.productId] += entry.tags.length;
          } else {
            productCount[entry.productId] = entry.tags.length;
          }
        });
      }

      //convert and sort map object to get top recommendations
      const productCountKeys = [...Object.keys(productCount)];
      const productCountArray = productCountKeys.map(key => ({
        name: allProducts.find(product => product.id === key)?.title || key,
        productId: key,
        value: productCount[key],
        startTime: currentInterval * defaultAdDuration * 1000,
        videoId,
      }));
      productCountArray.sort((productA, productB) => productB.value - productA.value);
      //take top 1
      const intervalAds = productCountArray.slice(0, 1).map(product => {
        const rank = 1;
        const conflicts = doesAdConfict(existingBannerAds, { rank, startTime: product.startTime / 1000 });

        return {
          ...product,
          rank,
          source: 'automatic',
          approved: conflicts.length === 0,
          conflicts,
        };
      });

      recommendedAds.push(...intervalAds);

      currentInterval++;
    }
    setLoading(false);
    setAdSuggestions(recommendedAds);
  };

  const doesAdConfict = (existingAds: IndividualAssignment[], candidate: { rank: number; startTime: number }) => {
    const conflicts = [];
    for (const ad of existingAds) {
      if (ad.rank === candidate.rank) {
        const existingStart = ad.startTimeIndex;
        const existingEnd = ad.startTimeIndex + defaultAdDuration;
        const candidateStart = candidate.startTime;
        const candidateEnd = candidate.startTime + defaultAdDuration;

        //conflict with preceding
        if (candidateStart > existingStart && candidateStart < existingEnd) {
          conflicts.push({
            productId: ad.productId,
            id: ad.id,
            name: ad.name,
            message: 'Conflicts with preceding ad by interrupting it before the default ad duration',
            start: existingStart,
            projectedEnd: existingEnd,
            source: ad.source || 'manual',
          });
        }

        //conflict with following
        if (candidateStart <= existingStart && candidateEnd > existingStart) {
          conflicts.push({
            productId: ad.productId,
            id: ad.id,
            name: ad.name,
            message: 'Conflicts with following ad by being interrupted by it before the default ad duration',
            start: existingStart,
            projectedEnd: existingEnd,
            source: ad.source || 'manual',
          });
        }
      }
    }

    return conflicts;
  };

  //mark a conflicting ad to be deleted or not deleted
  const toggleDeletion = (adIndex: number, conflictIndex: number) => {
    const conflicts = adSuggestions[adIndex].conflicts;
    const id = conflicts[conflictIndex].id;

    if (!id) {
      ClientLogger.error(
        `AutoAdPlacement:toggleDeletion`,
        `Could not toggle deletion status of conflicting ad ${conflicts[conflictIndex].name}, missing fileName`
      );
      return;
    }

    const markedAds = [...adsToDelete];
    const markedIndex = markedAds.indexOf(id);
    if (markedIndex !== -1) {
      markedAds.splice(markedIndex, 1);
    } else {
      markedAds.push(id);
    }
    setAdsToDelete(markedAds);
  };

  // approve an ad
  const toggleApproval = (i: number) => {
    const ads = [...adSuggestions];
    ads[i].approved = !ads[i].approved;
    setAdSuggestions(ads);
  };

  const commitAds = async () => {
    if (!adSuggestions.length) return;

    const approvedAds = adSuggestions.filter(ad => ad.approved);
    const approvedAssignments: { content?: IndividualAssignment; id: string; action: 'create' }[] = approvedAds.map(ad => {
      return {
        id: `${ad.videoId}-${formatFromSeconds(Math.floor(ad.startTime / 1000))}`,
        content: {
          adType: 'banner',
          id: `${ad.videoId}-${formatFromSeconds(Math.floor(ad.startTime / 1000))}`,
          name: ad.name,
          rank: ad.rank,
          productId: ad.productId,
          startTimeIndex: Math.floor(ad.startTime / 1000),
          videoId: ad.videoId,
          source: 'automatic',
          status: 'active',
        },
        action: 'create',
      };
    });

    const deleteAssignments: { content?: IndividualAssignment; id: string; action: 'delete' }[] = adsToDelete.map(id => {
      return {
        id,
        action: 'delete',
      };
    });

    // const commitResp = await assignmentHook.writeIndividualAssignmentsById([...deleteAssignments, ...approvedAssignments]);
    // if (commitResp.success) {
    //   setCommitFeedback('The commit was successful');
    //   setTimeout(() => {
    //     setCommitFeedback('');
    //   }, 5000);
    // } else {
    //   setCommitFeedback(`The commit was unsuccessful ${commitResp.errorMessage}`);
    // }
  };

  return (
    <div className={flexContainer}>
      <div className={adminTitle}>Automatic Ad Placement</div>
      {loadingError && <div className={classNames(spacer, errorMessage)}>{loadingError}</div>}
      <Formik
        initialValues={{
          video: '',
          collections: productCollections.map(col => col.id),
          includeUncollected: true,
        }}
        validationSchema={getVideoDataSchema}
        onSubmit={values => {
          const filteredProducts: ShopifyProduct[] = [];
          values.collections.map(collection => {
            filteredProducts.push(...allProducts.filter(product => product.collectionIds.includes(collection)));
          });

          //include products with no collection
          values.includeUncollected && filteredProducts.push(...allProducts.filter(product => product.collectionIds.length === 0));

          getVideoData(values.video, filteredProducts);
        }}
      >
        {props => (
          <>
            <label className={classNames(spacer, flexRow, alignCenter)}>
              <div className={adminText}>Video ID:</div>
              <Select
                value={videoOptions.filter(opt => opt.value === props.values.video)}
                name={`video`}
                onChange={opt => {
                  props.setFieldValue(`video`, opt?.value);
                }}
                isClearable
                options={videoOptions}
                className={defaultSelectControl}
                classNamePrefix="productSelect"
              />
            </label>

            {props.errors.video && props.touched.video && <div className={classNames(spacer, errorMessage)}>{props.errors.video}</div>}

            <FieldArray name="collections">
              {arrayHelpers => (
                <div className={collectionList}>
                  <div className={classNames(spacer, adminText)}>Collections to include in ad generation</div>
                  {productCollections.map((collection, i) => (
                    <label key={i} className={classNames(flexRow, alignCenter)}>
                      <input
                        type="checkbox"
                        name="collections"
                        value={collection.id}
                        checked={props.values.collections.includes(collection.id)}
                        onChange={e => {
                          if (e.target.checked) {
                            arrayHelpers.push(collection.id);
                          } else {
                            const idx = props.values.collections.indexOf(collection.id);
                            arrayHelpers.remove(idx);
                          }
                        }}
                        className={adminCheckbox}
                      />
                      <div className={adminText}>{collection.title}</div>
                    </label>
                  ))}
                </div>
              )}
            </FieldArray>
            <label className={classNames(flexRow, alignCenter)}>
              <Field type="checkbox" name="includeUncollected" checked={props.values.includeUncollected} className={adminCheckbox} />
              <div className={adminText}>Include Products Without Collections</div>
            </label>
            <Button type="submit" onClick={props.handleSubmit} className={spacer}>
              GENERATE ADS
            </Button>
          </>
        )}
      </Formik>

      <div className={classNames(spacer, alignCenter, gridTable)}>
        {isLoading && (
          <div className={adminLoader}>
            <Loading />
          </div>
        )}
        {adSuggestions.map((ad, i) => (
          <div key={i} className={classNames(flexRow, fullWidth)}>
            <div className={classNames(tableCell, fifthWidthCell)}>
              <div className={adminText}>{ad.name}</div>
              <div className={editorVideoLabel}>Product</div>
            </div>
            <div className={classNames(tableCell, tenthWidthCell)}>
              <div className={adminText}>
                {`${Math.floor(ad.startTime / (60 * 1000))}:${Math.round((ad.startTime % (60 * 1000)) / 1000)
                  .toString()
                  .padEnd(2, '0')}`}
              </div>
              <div className={editorVideoLabel}>Time</div>
            </div>
            <div className={classNames(tableCell, tenthWidthCell)}>
              <div className={adminText}>{ad.rank}</div>
              <div className={editorVideoLabel}>Rank</div>
            </div>
            <div className={classNames(tableCell, tenthWidthCell)}>
              <div className={adminText}>{ad.value}</div>
              <div className={editorVideoLabel}>Score</div>
            </div>
            <div className={classNames(tableCell, halfWidthCell)}>
              {ad.approved ? (
                <div className={flexRow}>
                  <div className={classNames(successfulMessage, adminText)}>APPROVED</div>
                  <Button onClick={() => toggleApproval(i)} className={noWrapButton}>
                    UNAPPROVE
                  </Button>
                </div>
              ) : (
                <div className={flexRow}>
                  <div className={classNames(errorMessage, adminText)}>NOT APPROVED</div>
                  <Button onClick={() => toggleApproval(i)} className={noWrapButton}>
                    APPROVE ANYWAY
                  </Button>
                </div>
              )}
              {ad.conflicts.map((conflict, j) => (
                <div key={j} className={spacer}>
                  <div className={adminSubText}>{conflict.message}</div>
                  <div className={classNames(adminSubText, spacer)}>Conflicts with: {conflict.id}</div>
                  <div className={adminSubText}>Name: {conflict.name}</div>
                  <div className={adminSubText}>StartTime: {conflict.start}</div>
                  <div className={classNames(flexRow, spacer)}>
                    <Button style={ButtonStyles.Greyscale} onClick={() => toggleDeletion(i, j)} className={noWrapButton}>
                      {conflict.id && adsToDelete.includes(conflict.id) ? `REVERT` : `DELETE CONFLICTING FILE`}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className={gridRightDecorator} />
        <div className={gridBottomDecorator} />
      </div>

      <Button className={classNames(spacer, applyFlex)} onClick={commitAds} disabled={Boolean(loadingError) || adSuggestions.length === 0}>
        COMMIT APPROVED ADS
      </Button>
      {commitFeedback && <div className={adminSubText}>{commitFeedback}</div>}
    </div>
  );
};
