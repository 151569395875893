// extracted by mini-css-extract-plugin
export var tabText = "styles-module--tabText--2Ay1_";
export var panelTabsContainer = "styles-module--panelTabsContainer--bYQIL";
export var panelTabsContainerClosed = "styles-module--panelTabsContainerClosed--vVCPw";
export var panelTabToggle = "styles-module--panelTabToggle--3GNWR";
export var panelTabToggleClosed = "styles-module--panelTabToggleClosed--3Ov2x";
export var activeTabStyle = "styles-module--activeTabStyle--221LH";
export var tabGroupContainer = "styles-module--tabGroupContainer--Gn16d";
export var tabGroupLabel = "styles-module--tabGroupLabel--3B0Ch";
export var tabItemLabel = "styles-module--tabItemLabel--riVIp";
export var hideText = "styles-module--hideText--3urap";
export var tabItemContainer = "styles-module--tabItemContainer--sP6E8";
export var tabItemIcon = "styles-module--tabItemIcon--9vVSX";