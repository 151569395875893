export const isEmbedded = (): boolean => {
  try {
    if (
      typeof window !== 'undefined' &&
      (window.top?.location !== window.location || window.top?.location.pathname.indexOf('series-embed') !== -1)
    ) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export default isEmbedded;
