import React from 'react';
import { Link } from 'gatsby';
import { link as linkStyle, alreadyMemberLogin } from './styles.module.scss';

interface props {
  content: string;
  link: string;
  linkText: string;
}

export const AlreadyMemberLogIn = ({ content, link, linkText }: props) => (
  <div className={alreadyMemberLogin}>
    {content}
    <Link className={linkStyle} to={link}>
      <b>{linkText}</b>
    </Link>
  </div>
);
