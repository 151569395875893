import * as yup from 'yup';
import { Formik } from 'formik';
import React, { useState, useContext } from 'react';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Button, ButtonStyles } from '@components/Buttons';
import { TextField, FormContainer, Checkbox } from '@components/Forms';
import { useMyAccountResource } from '@src/pageComponents/MyAccount/useMyAccountResources';
import {
  deleteAccountForm,
  textError,
  formHeader,
  formSmallHeader,
  successMessage,
  checkboxLabels,
  buttonsContainer,
  deleteAccountButton,
} from './styles.module.scss';

const DEBUG = false;

interface DeleteAccountFormErrors {
  deleteAccountUnchecked: string;
}

interface ReasonForAccountDeletion {
  name: string;
  value: string;
}

const validationSchema = (error: DeleteAccountFormErrors) => {
  return yup.object().shape({
    deleteAccountCheck: yup
      .boolean()
      .required()
      .oneOf([true], error.deleteAccountUnchecked),
    otherCheck: yup.boolean(),
    otherTextArea: yup.string(),
  });
};

interface Props {
  closeModal: () => void;
  updateModalState: (val: boolean) => void;
}

export const DeleteAccountForm: React.FC<Props> = ({ closeModal, updateModalState }) => {
  const api = usePvepApi();
  const { email } = api.state;
  const nav = useContext(NavStateContext);
  const content = useMyAccountResource();

  const reasons = content.membershipTabContent.deleteAccountPage.reasonsForDeletion;
  const errors = content.membershipTabContent.deleteAccountPage.deleteAccountErrors;

  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const checksObject = Object.create({});

  reasons.forEach((reason: ReasonForAccountDeletion) => {
    checksObject[reason.name] = false;
  });

  const initialValues = {
    deleteAccountCheck: false,
    otherCheck: false,
    otherTextArea: '',
    checksObject,
  };

  // toggle value in check object
  const handleCheck = (name: string) => {
    checksObject[name] = !checksObject[name];
  };

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        // get all reasons for deleting their account
        const filtered = reasons.filter((reason: ReasonForAccountDeletion) => {
          return checksObject[reason.name];
        });

        const reasonsForDeletion = filtered.map((reason: ReasonForAccountDeletion) => {
          return reason.value;
        });

        // if other checked, add to reasons array
        if (values.otherCheck) {
          reasonsForDeletion.push(values.otherTextArea);
        }

        DEBUG && ClientLogger.debug('DeleteAccount.onSubmit', 'delete_reasons:', DEBUG, reasonsForDeletion);

        // api call
        const resp = await api.softDeleteUserAccount(email, reasonsForDeletion);
        DEBUG && ClientLogger.debug('ChangePassword.response', '', DEBUG, resp);

        // if successful, show success message and log out the user
        if (resp.data?.softDeleteUserAccount.success) {
          setErrorMessage('');
          setShowSuccessMessage(true);
          setTimeout(() => {
            nav.setRoot({ path: '/logout' });
          }, 1500);
        } else {
          setErrorMessage(`Something went wrong, please try again.`);
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema(errors)}
    >
      {props => {
        DEBUG && ClientLogger.debug('DeleteAccountForm:', 'render', DEBUG, props);
        return (
          <FormContainer
            className={deleteAccountForm}
            onSubmit={props.handleSubmit}
            errorMessage={errorMessage && <div className={textError}>{errorMessage}</div>}
          >
            {showSuccessMessage ? (
              <div className={successMessage}>Account deleted successfully. You will be logged out.</div>
            ) : (
              <>
                <h1 className={formHeader}>
                  <button type="button" onClick={() => closeModal()} className="delete is-pulled-right mb-4 mt-0" />
                  Delete Account
                </h1>
                <h4 className={formSmallHeader}>Why are you choosing to delete your account?</h4>

                {reasons.length > 0 &&
                  reasons.map((reason: ReasonForAccountDeletion, i: number) => (
                    <Checkbox
                      key={i}
                      fieldName={i.toString()}
                      onChange={e => {
                        e.persist();
                        handleCheck(reason.name);
                        props.handleChange(e);
                      }}
                      checked={checksObject[reason.name]}
                    >
                      <div className={checkboxLabels}>{reason.value}</div>
                    </Checkbox>
                  ))}

                <Checkbox
                  fieldName="otherCheck"
                  onChange={props.handleChange}
                  checked={props.values.otherCheck}
                  errorMessage={props.errors.otherCheck}
                >
                  <div className={checkboxLabels}>Other, please specify</div>
                </Checkbox>

                <TextField placeholder="" fieldType="text" fieldName="otherTextArea" rows={3} optional />

                <Checkbox
                  fieldName="deleteAccountCheck"
                  onChange={e => {
                    e.persist();
                    props.handleBlur(e);
                    props.handleChange(e);
                  }}
                  checked={props.values.deleteAccountCheck}
                  errorMessage={props.touched.deleteAccountCheck ? props.errors.deleteAccountCheck : ''}
                >
                  <div className={checkboxLabels}>Yes, please delete my account and viewing history.</div>
                </Checkbox>

                <div className={buttonsContainer}>
                  <Button type="button" onClick={() => updateModalState(false)}>
                    Cancel
                  </Button>
                  <Button
                    dataCY="deleteAccountSubmit"
                    type="submit"
                    disabled={!(props.isValid && props.dirty)}
                    style={ButtonStyles.Greyscale}
                    className={deleteAccountButton}
                  >
                    Delete Account
                  </Button>
                </div>
              </>
            )}
          </FormContainer>
        );
      }}
    </Formik>
  );
};
