import { Images } from '@components/Img/useFluidImage';
import { ClientLogger } from '@lib/ClientLogger';
import { graphql, useStaticQuery } from 'gatsby';

/**
 * A convenience function for pages to use an image defined in the Netlify CMS
 *
 * @param pathAndFilename - full path and file name as set by CMS
 */

function useFluidImagePages(): Images[] {
  const images = useStaticQuery(graphql`
    query useFluidImagePages {
      allFile(filter: { ext: { in: [".jpg", ".png", ".jpeg"] }, relativePath: { glob: "pages/images/**" } }) {
        nodes {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  return images.allFile.nodes;
}

export function usePageImage(pathAndFilename: string): Images {
  const parts = pathAndFilename.split('/');
  const filename = parts[parts.length - 1];
  const images = useFluidImagePages();
  const match = `pages/images/${filename}`;
  const image = images.find(f => f.relativePath === match);
  if (!image) {
    ClientLogger.error(
      'usePageImage',
      `Cannot find ${pathAndFilename} debug = ${JSON.stringify(
        {
          filename,
          images,
        },
        null,
        2
      )}`
    );
    return { childImageSharp: {} as any } as any;
  }

  return image;
}
