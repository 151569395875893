// extracted by mini-css-extract-plugin
export var videoUpNextContainer = "styles-module--videoUpNextContainer--XLyWz";
export var finishedContainer = "styles-module--finishedContainer--3ApcW";
export var videoUpNextContainerMini = "styles-module--videoUpNextContainerMini--2qu0F";
export var playButtonGroup = "styles-module--playButtonGroup--f-jif";
export var playButtonGroupMini = "styles-module--playButtonGroupMini--3KTXt";
export var videoButton = "styles-module--videoButton--1GIk7";
export var videoButtonMini = "styles-module--videoButtonMini--3-_Z3";
export var cancelButton = "styles-module--cancelButton--1VXJ7";
export var cancelButtonMini = "styles-module--cancelButtonMini--Frxto";
export var videoTitleGroup = "styles-module--videoTitleGroup--3Z1TK";
export var finishedVideoTitleGroup = "styles-module--finishedVideoTitleGroup--26O2Y";
export var finishedVideoTitleGroupMini = "styles-module--finishedVideoTitleGroupMini--N_l-Z";
export var nextTitle = "styles-module--nextTitle--QfM_C";
export var nextTitleMini = "styles-module--nextTitleMini--1kuGf";
export var nextVideoName = "styles-module--nextVideoName--6oKZE";
export var nextEpisodeNumber = "styles-module--nextEpisodeNumber--16Wr_";
export var nextVideoTextMini = "styles-module--nextVideoTextMini--2mB-z";
export var finishedTextMini = "styles-module--finishedTextMini--3hjii";
export var homeButton = "styles-module--homeButton--2nT7E";
export var homeButtonMini = "styles-module--homeButtonMini--3b9Zn";
export var hide = "styles-module--hide--3sMm6";
export var spin = "styles-module--spin--FAnhZ";