import { UserState, usePvepApi } from '@apiClient/usePvepApi';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { ShopifyUtil } from '@lib/shopify';
import { Util, ShopifyProduct, BrandUtil, AdStrategy } from '@sharedLib/index';
import { v4 as uuidv4 } from 'uuid';
import { useVideoData } from './video-query/useVideoData';

const DEBUG = false;

type ProductDisplayType = 'banner' | 'featured' | undefined;

interface VideoEvent {
  videoSessionId: string | null;
  videoId: string | undefined;
  videoName: string | undefined;
  event: any;
  isFullscreen?: boolean;
  position?: number;
  adStrategy?: AdStrategy;
}

interface VideoWatchedEvent {
  videoSessionId: string | null;
  videoId: string | undefined;
  videoName: string | undefined;
  startPosition: number;
  endPosition: number;
  elapsedTime: number;
  totalDuration: number;
  adStrategy?: AdStrategy;
}

interface VideoPercentileEvent {
  videoSessionId: string | null;
  videoId: string | undefined;
  videoName: string | undefined;
  quarter: number;
  pauseCount: number;
  seekCount: number;
  position: number;
  totalDuration: number;
  adStrategy?: AdStrategy;
}

interface ShareEvent {
  type: string;
  videoId?: string;
  productId?: string;
  searchString?: string;
  shareMethod: string;
  videoName?: string;
  productName?: string;
}

interface InView {
  videoId: string | undefined;
  videoName: string | undefined;
  videoPosition: number | undefined;
  productId: string | undefined;
  productName: string | undefined;
  location: string | undefined;
}

export const inView: InView = {
  videoId: undefined,
  videoName: undefined,
  videoPosition: undefined,
  productId: undefined,
  productName: undefined,
  location: undefined,
};

type HitType = 'view' | 'add-to-cart' | 'affiliate-click' | 'shop-now' | 'view-in-video';

export const useAnalyticsCapture = () => {
  const api = usePvepApi();
  const videoData = useVideoData();
  if (isBrowser) {
    // @ts-ignore
    window.pvep_state = inView;
  }
  /**
   * Read current page title (leverages efforts to set reasonable title)
   */
  function getCurrentPageTitle(): string {
    let title = '';
    try {
      title = document.getElementsByTagName('title')[0].innerHTML;
    } catch (e) {
      ClientLogger.error('AnalyticsCapture.recordPage', 'Error calculating title', e);
    }
    return title;
  }

  function inViewDimensions(): { Video: string | undefined; Product: string | undefined } {
    return { Video: inView.videoName || 'no-video-in-view', Product: inView.productName || 'no-product-in-view' };
  }

  const clearVideoInView = (): void => {
    DEBUG && ClientLogger.debug('AnalyticsCapture.clearVideoInView', 'called');
    inView.videoId = undefined;
    inView.videoName = undefined;
    inView.videoPosition = undefined;
  };

  const getAdStrategyData = (adStrategy?: AdStrategy) => {
    if (adStrategy) {
      return {
        strategy_id: adStrategy.strategyId,
        strategy_title: adStrategy.strategyTitle,
        experiment_id: adStrategy.experimentId,
        experiment_title: adStrategy.experimentTitle,
        range_percentage: adStrategy.rangePercentage,
      };
    }
    return undefined;
  };

  const recordPage = async (location: Location): Promise<void> => {
    if (!isBrowser) {
      return;
    }

    const userState = api.state;

    let appSessionId = userState?.appSession?.id || '';
    if (userState.appSession?.updated && userState.appSession.updated + 1000 * 60 * 60 * 2 < Date.now()) {
      // new session if more than 2h has gone by with no activity
      appSessionId = `u:${userState.jwt?.sub || 'Unknown user'}, d:${api.getDeviceId() || 'Unknown device'}, s:${uuidv4()}`;
      // To do: call api to calc session id
      await api.updateUserSession(appSessionId, Date.now());
    } else {
      // activity within session, extend expiry
      await api.updateUserSession(appSessionId, Date.now());
    }

    window.analytics.debug(false); // set debugging mode for segment
    inView.location = location.pathname;

    await new Promise(resolve => {
      setTimeout(resolve, 64);
    }); // Wait needed because page title not correct when page navigates

    const { pathname } = location;
    const parts = pathname.split('/');

    // remove first path part if it is the git branch name, as in develop environments
    if (parts.length > 0 && parts[1] === Util.getBranch()) {
      parts.shift();
    }
    const topLevelPath = parts.length > 0 ? parts[1] : undefined;
    const secondLevel = parts.length > 1 ? parts[2] : undefined;
    const secondLevelNumeric = Number(secondLevel);
    const title = getCurrentPageTitle();
    DEBUG &&
      ClientLogger.debug('AnalyticsCapture', 'recordPage', {
        location,
        inView,
        title,
        parts,
        topLevelPath,
        appSession: userState?.appSession,
      });
    const baseProps = { brandId: Util.getBrandId(), gitBranch: Util.getBranch(), session_id: appSessionId };
    switch (topLevelPath) {
      case 'series': {
        const series = secondLevel ? videoData.getSeries(secondLevel) : undefined;
        window.analytics &&
          window.analytics.page(title || 'Series', {
            ...baseProps,
            collection_id: secondLevelNumeric,
            collection_name: series?.title,
            page_type: 'series',
            ...inViewDimensions(),
          });
        inView.productName = undefined;
        inView.productId = undefined;
        break;
      }
      case 'product':
        window.analytics &&
          window.analytics.page(title || 'Product', {
            ...baseProps,
            product_id: secondLevel,
            ...inViewDimensions(),
            page_type: 'product',
          });
        break;
      case 'video': {
        const video = secondLevel ? videoData.getVideo(secondLevel) : undefined;
        inView.productName = undefined;
        inView.productId = undefined;
        inView.videoId = video?.id;
        inView.videoName = video?.title || title;
        window.analytics &&
          window.analytics.page(video?.title || title || 'Video', {
            ...baseProps,
            video_id: secondLevelNumeric,
            ...inViewDimensions(),
            page_type: 'video',
            video_type: video?.type,
          });
        break;
      }
      case 'app':
        inView.productName = undefined;
        inView.productId = undefined;
        window.analytics && window.analytics.page(title || 'Home', { ...baseProps, ...inViewDimensions(), page_type: 'home' });
        break;
      case 'admin':
      case 'db-admin':
        inView.productName = undefined;
        inView.productId = undefined;
        window.analytics && window.analytics.page(title || 'Admin', { ...baseProps, ...inViewDimensions(), page_type: 'admin' });
        break;
      default:
        inView.productName = undefined;
        inView.productId = undefined;
        window.analytics && window.analytics.page(title || topLevelPath, { ...baseProps, ...inViewDimensions(), page_type: 'other' });
    }
  };

  const productHit = (
    hitType: HitType,
    shopifyProduct: ShopifyProduct,
    options?: {
      displayType?: ProductDisplayType;
      position?: number;
      adStrategy?: AdStrategy;
    }
  ) => {
    const displayType = options?.displayType;
    const position = options?.position;
    let adStrategy = options?.adStrategy;

    const eventNameCalc = (hitTypeFct: HitType) => {
      switch (hitTypeFct) {
        case 'view':
          return 'Product Viewed';
        case 'add-to-cart':
          return 'Product Added to Cart';
        case 'affiliate-click':
          return 'Product Affiliate Clicked';
        case 'shop-now':
          return 'Product Shop Now Clicked';
        case 'view-in-video':
          return 'Product Viewed in Video';
        default:
          ClientLogger.error('AnalyticsCapture', `productHit - hitType not recognized ${hitTypeFct}`);
          return 'Unknown';
      }
    };
    const eventName = eventNameCalc(hitType);
    const id = shopifyProduct.id;
    const title = getCurrentPageTitle();
    const userState = api.state;
    const appSessionId = userState.appSession.id;

    // add strategy/experiment data if video is in view
    if (!inView.videoId) {
      adStrategy = undefined;
    }

    DEBUG &&
      ClientLogger.debug('AnalyticsCapture', 'productHit', {
        product_id: id,
        hitType,
        shopifyProduct,
        displayType,
        position: position || inView.videoPosition,
        title,
      });
    const firstImage = shopifyProduct.imageOriginalSrc;
    inView.productName = shopifyProduct.title;
    inView.productId = id;
    window.analytics.track(eventName, {
      // Standard
      product_id: id,
      video_id: inView.videoId,
      name: shopifyProduct.title,
      price: ShopifyUtil.getPrice(shopifyProduct),
      image_url: firstImage,
      position: position || inView.videoPosition,
      url: shopifyProduct.targetUrl,
      brand: shopifyProduct.vendor,
      category: shopifyProduct.productType,
      title,
      // Custom
      eCommerceType: shopifyProduct.eCommerceType,
      handle: shopifyProduct.handle,
      displayType,
      brandId: Util.getBrandId(),
      gitBranch: Util.getBranch(),
      session_id: appSessionId,
      strategy: getAdStrategyData(adStrategy),
      ...inViewDimensions(),
    });
  };

  function vimeoEventTypeToSegmentEventType(vimeoType: string): string {
    switch (vimeoType) {
      case 'ended':
        return 'Video Playback Completed';
      case 'pause':
        return 'Video Playback Paused';
      case 'play':
        return 'Video Playback Started';
      case 'seeking':
        return 'Video Playback Seek Started';
      case 'seeked':
        return 'Video Playback Seek Completed';
      case 'resume':
        return 'Video Playback Resumed';
      case 'startbuffering':
        return 'Video Playback Buffer Started';
      case 'endbuffering':
        return 'Video Playback Buffer Completed';
      default: {
        return `Vimeo Event: ${vimeoType}`;
      }
    }
  }

  let lastEventStr = ''; // Work around crappy Vimeo that sends same event multiple times

  const videoEvent = (videoEventGiven: VideoEvent) => {
    const userState = api.state;
    const appSessionId = userState.appSession.id;

    const { videoSessionId, videoId, event, isFullscreen, position, videoName, adStrategy } = videoEventGiven;
    const title = getCurrentPageTitle();
    const analyticsType = vimeoEventTypeToSegmentEventType(event.type);
    inView.videoId = videoId;
    inView.videoName = videoName;
    inView.videoPosition = position;
    const strVersion = JSON.stringify({ inView, videoSessionId, videoId, eventType: event.type, isFullscreen, position, title });
    if (strVersion === lastEventStr) {
      DEBUG &&
        ClientLogger.debug('AnalyticsCapture', 'videoEvent - duplicate detected not sending to Segment', {
          inView,
          analyticsType,
          videoSessionId,
          event,
          videoId,
        });
      return;
    }
    lastEventStr = strVersion;
    DEBUG &&
      ClientLogger.debug('AnalyticsCapture', 'videoEvent', {
        inView: JSON.stringify(inView),
        analyticsType,
        videoSessionId,
        event,
        videoId,
        title,
      });
    window.analytics.track(analyticsType, {
      // Standard
      session_id: appSessionId,
      video_session_id: videoSessionId,
      video_id: videoId,
      full_screen: isFullscreen,
      position,
      brandId: Util.getBrandId(),
      gitBranch: Util.getBranch(),
      title,
      strategy: getAdStrategyData(adStrategy),
      ...inViewDimensions(),
    });
  };

  const videoWatchedEvent = (event: VideoWatchedEvent) => {
    const { videoSessionId, videoId, startPosition, endPosition, totalDuration, elapsedTime, adStrategy } = event;
    const userState = api.state;
    const appSessionId = userState.appSession.id;

    const title = getCurrentPageTitle();
    inView.videoPosition = endPosition || startPosition;
    DEBUG &&
      ClientLogger.debug('AnalyticsCapture', 'videoWatchedEvent', {
        inView: JSON.stringify(inView),
        event,
      });
    if (elapsedTime < 0.75) {
      // Vimeo spews multiple events for things like pause causing tons of little videoWatchedEvent events
      // Filter small events
      ClientLogger.debug(
        'AnalyticsCapture',
        'videoWatchedEvent not recorded dur to short duration',
        DEBUG,

        {
          event,
        }
      );
      return;
    }
    window.analytics.track('Video Playback Counted', {
      // Standard
      session_id: appSessionId,
      video_session_id: videoSessionId,
      video_id: videoId,
      startPosition,
      endPosition,
      totalDuration,
      elapsedTime,
      brandId: Util.getBrandId(),
      gitBranch: Util.getBranch(),
      title,
      strategy: getAdStrategyData(adStrategy),
      ...inViewDimensions(),
    });
  };

  const videoPercentile = (event: VideoPercentileEvent) => {
    const { videoSessionId, videoId, position, totalDuration, pauseCount, seekCount, quarter, videoName, adStrategy } = event;
    const userState = api.state;
    const appSessionId = userState.appSession.id;

    inView.videoId = videoId;
    inView.videoName = videoName;
    const title = getCurrentPageTitle();
    ClientLogger.debug(
      'AnalyticsCapture',
      'videoWatchedEvent',
      DEBUG,

      {
        event,
      }
    );
    window.analytics.track('Video Playback Percentile', {
      // Standard
      session_id: appSessionId,
      video_session_id: videoSessionId,
      video_id: videoId,
      position,
      totalDuration,
      pauseCount,
      seekCount,
      quarter,
      brandId: Util.getBrandId(),
      gitBranch: Util.getBranch(),
      title,
      strategy: getAdStrategyData(adStrategy),
      ...inViewDimensions(),
    });
  };

  const shareEvent = (event: ShareEvent) => {
    const { type, videoId, productId, searchString, shareMethod, productName, videoName } = event;
    const userState = api.state;
    const appSessionId = userState.appSession.id;

    DEBUG && ClientLogger.debug('AnalyticsCapture', 'ShareEvent', DEBUG, { event });

    inView.productName = productName;
    inView.videoName = videoName;
    inView.videoId = videoId;
    inView.productId = productId;

    window.analytics.track('Share Event', {
      session_id: appSessionId,
      shareType: type,
      video_id: videoId,
      product_id: productId,
      searchString,
      shareMethod,
      ...inViewDimensions(),
    });
  };

  return {
    clearVideoInView,
    recordPage,
    productHit,
    videoEvent,
    videoWatchedEvent,
    videoPercentile,
    shareEvent,
  };
};
