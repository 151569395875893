import { apolloTracer } from '@apiClient/apollo-tracer';
import { NavStateContext } from '@lib/navContext';
import { ClientLogger } from '@lib/ClientLogger';
import IndexLayout from '@src/layouts';
import React, { useContext, useEffect, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Button } from '@components/Buttons';
import {
  adminTitle,
  borderSeparator,
  flexContainer,
  fillPage,
  spacer,
  adminPageContainer,
  adminPageContent,
  adminPadBottom,
  dataAnalyticsPage,
  adminText,
  flexRow,
  adminCheckbox,
  livePreviewContainer,
} from './styles.module.scss';
import { ObjectRecognitionForm } from './ObjectRecognitionForm';
import { AutoAdPlacement } from './AutoAdPlacement';
import { PanelTabs, PanelTabGroup } from '@src/components/Tabs';
import { CouponCodes } from './CouponCodes';
import { VideoUploader } from './VideoUploader';
import { MetadataUpdateForm } from './MetadataUpdateForm';
import { Icon } from '@iconify/react';
import { BrandUtil } from '@sharedLib/index';
import { ObjectRecognitionIcon } from '@assets/ObjectRecognitionIcon';
import { VideoMetadata } from './VideoMetadata';
import { ProductAssignments } from './ProductAssignments';
import { DataAnalytics } from './DataAnalytics';
import {
  VideoJsonUploader,
  VideoDataExport,
  VideoDataImport,
  VideoCollectionJsonUploader,
  VideoCollectionExport,
  VideoCollectionImport,
  BranchCommit,
  StrategyImport,
  StrategyExport,
  ExperimentExport,
  ExperimentImport,
} from '@components/SuperAdmin';
import classNames from 'classnames';

const DEBUG = false;

const mainTabGroup: PanelTabGroup = {
  label: 'Admin Panel',
  items: [
    {
      label: 'Video Assignment',
      id: 'videoAssignments',
      icon: <Icon icon="bi:play-btn-fill" />,
    },
    {
      label: 'Product Assignment',
      id: 'productAssignments',
      icon: <Icon icon="mdi:clipboard-text" />,
    },
    {
      label: 'Brand Management',
      id: 'brandManagement',
      icon: <Icon icon="bi:joystick" />,
    },
    {
      label: 'Data Analytics',
      id: 'dataAnalytics',
      icon: <Icon icon="carbon:chart-line-data" />,
    },
    {
      label: 'Users',
      id: 'users',
      icon: <Icon icon="carbon:user-avatar-filled-alt" />,
    },
  ],
};

const objectRecognitionTabGroup: PanelTabGroup = {
  label: 'Additional Tools',
  items: [
    {
      label: 'Object Recognition',
      id: 'objectRecognition',
      icon: <ObjectRecognitionIcon />,
    },
    {
      label: 'Ad Generation',
      id: 'adGeneration',
      icon: <Icon icon="bi:badge-ad-fill" />,
    },
  ],
};

const superAdminTabGroup: PanelTabGroup = {
  label: 'Admin',
  items: [
    {
      label: 'Super Admin',
      id: 'superAdmin',
      icon: <Icon icon="whh:superman" />,
    },
  ],
};

export const AdminPage = () => {
  ClientLogger.debug('Admin', 'render', DEBUG);
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const [trace, setTrace] = useState(apolloTracer.isTracing());
  const [livePreviewChecked, setLivePreviewChecked] = useState(api.state.useRuntimeData);
  const nav = useContext(NavStateContext);

  // set up panel nav tabs
  const [activeTab, setActiveTab] = useState('videoAssignments');
  const tabGroups = [mainTabGroup];
  if (brandInfo.adminConfig.hasObjectRecognition) {
    tabGroups.push(objectRecognitionTabGroup);
  }
  if (api.state.isAdmin) {
    tabGroups.push(superAdminTabGroup);
  }

  const toggleTracing = () => {
    if (apolloTracer.isTracing()) {
      apolloTracer.stop();
      console.log(JSON.stringify(apolloTracer.getTraceEVents(), null, 2));
    } else {
      apolloTracer.start();
    }
    setTrace(apolloTracer.isTracing());
  };

  useEffect(() => {
    if (!api.state.isAdmin && !api.state.isManager) {
      ClientLogger.error('Admin.useEffect', 'Admin page accessed by non admin');
      nav.setRoot({ path: '/', title: 'Home' });
    }
  }, []);

  if (!api.state.isAdmin && !api.state.isManager) {
    return <div>Unauthorized</div>;
  }
  return (
    <IndexLayout needsLogin noVideo hideScrollBar hideFeedback>
      <div className={adminPageContainer}>
        <PanelTabs groups={tabGroups} onTabClick={id => setActiveTab(id)} activeTab={activeTab} />

        <div className={adminPageContent}>
          {activeTab === 'videoAssignments' && (
            <div className={fillPage}>
              <div className={fillPage}>
                <VideoMetadata />
              </div>
            </div>
          )}

          {activeTab === 'productAssignments' && (
            <div className={fillPage}>
              <div className={fillPage}>
                <ProductAssignments />
              </div>
            </div>
          )}

          {activeTab === 'brandManagement' && (
            <div className={fillPage}>
              <div className={borderSeparator} />
              <MetadataUpdateForm />
              <div className={borderSeparator} />
              <CouponCodes />
            </div>
          )}

          {activeTab === 'dataAnalytics' && (
            <div className={dataAnalyticsPage}>
              <DataAnalytics />
            </div>
          )}

          {activeTab === 'superAdmin' && (
            <div className={fillPage}>
              <div className={borderSeparator} />
              <div className={flexContainer}>
                <div className={adminTitle}>Tracing</div>
                <div className={adminPadBottom}>
                  The tracing tool can be toggled for debugging graphQL server queries. Traces will appear in the console when turned on.
                </div>

                <Button className={spacer} onClick={toggleTracing}>
                  TOGGLE TRACING (CURRENTLY {trace ? 'ON' : 'OFF'})
                </Button>
              </div>

              <div className={borderSeparator} />
              <div className={flexContainer}>
                <div className={adminTitle}>Cloud Build (Commit)</div>
                <div className={adminPadBottom}>
                  This tool will create an empty commit in Gitlab. Use this tool to rebuild your branch after making changes in Netlify. If
                  you are on a protected branch, use the non-commit version of this tool.
                </div>
                <BranchCommit />
              </div>

              <div className={borderSeparator} />
              <div className={flexContainer}>
                <div className={adminTitle}>Video JSON Uploader</div>
                <div className={adminPadBottom}>
                  The JSON uploader can be user to mass-import the pre-existing VHX-format videos.json file to the database. Note this is
                  not the same as the exported file, as used in the contols below.
                </div>
                <VideoJsonUploader />

                <div className={borderSeparator} />
                <div className={adminTitle}>Video Data Export</div>
                <div className={adminPadBottom}>
                  The video data exporter will download a JSON file which is a snapshot of the database. It can be used to seed other
                  branches with the same images and video information. Note that this file is different than the pre-existing videos.json
                  file used by the control above.
                </div>
                <VideoDataExport />

                <div className={spacer}></div>

                <div className={adminTitle}>Video Data Import</div>
                <div className={adminPadBottom}>
                  Data exported from another branch using the above exprter tool can be imported here to seed this branch.
                </div>
                <VideoDataImport />
              </div>

              <div className={borderSeparator} />

              <div className={flexContainer}>
                <div className={adminTitle}>Video Collection JSON Uploader</div>
                <div className={adminPadBottom}>
                  The JSON uploader can be user to mass-import the pre-existing VHX-format video-colletions.json file to the database. Note
                  this is not the same as the exported file, as used in the contols below.
                </div>
                <VideoCollectionJsonUploader />

                <div className={borderSeparator} />
                <div className={adminTitle}>Video Collection Export</div>
                <div className={adminPadBottom}>
                  The video collection exporter will download a JSON file which is a snapshot of the database. It can be used to seed other
                  branches with the same images and video collection information. Note that this file is different than the pre-existing
                  video-collections.json file used by the control above.
                </div>
                <VideoCollectionExport />

                <div className={spacer}></div>

                <div className={adminTitle}>Video Collection Import</div>
                <div className={adminPadBottom}>
                  Data exported from another branch using the above exporter tool can be imported here to seed this branch.
                </div>
                <VideoCollectionImport />
              </div>

              <div className={borderSeparator} />
              <div className={flexContainer}>
                <div className={adminTitle}>Strategy Export</div>
                <div className={adminPadBottom}>
                  The strategy exporter will download a JSON file which is a snapshot of the database. It can be used to seed other branches
                  with the same information.
                </div>
                <StrategyExport />

                <div className={spacer}></div>

                <div className={adminTitle}>Strategy Import</div>
                <div className={adminPadBottom}>
                  Data exported from another branch using the above exporter tool can be imported here to seed this branch.
                </div>
                <StrategyImport />
              </div>

              <div className={borderSeparator} />
              <div className={flexContainer}>
                <div className={adminTitle}>Experiment Export</div>
                <div className={adminPadBottom}>
                  The experiment exporter will download a JSON file which is a snapshot of the database. It can be used to seed other
                  branches with the same information.
                </div>
                <ExperimentExport />

                <div className={spacer}></div>

                <div className={adminTitle}>Experiment Import</div>
                <div className={adminPadBottom}>
                  Data exported from another branch using the above exporter tool can be imported here to seed this branch.
                </div>
                <ExperimentImport />
              </div>
            </div>
          )}

          {activeTab === 'objectRecognition' && (
            <div className={fillPage}>
              <div className={borderSeparator} />
              <VideoUploader />
              <div className={borderSeparator} />
              <ObjectRecognitionForm />
            </div>
          )}

          {activeTab === 'adGeneration' && (
            <div className={fillPage}>
              <div className={borderSeparator} />
              <AutoAdPlacement />
            </div>
          )}
        </div>
      </div>
    </IndexLayout>
  );
};
