import * as yup from 'yup';
import { Formik } from 'formik';
import React, { useState, useRef } from 'react';
import { ClientLogger } from '@lib/ClientLogger';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Button } from '@components/Buttons';
import { TextField, FormContainer } from '@components/Forms';
import { useMyAccountResource } from '@src/pageComponents/MyAccount/useMyAccountResources';
import { changePasswordForm, textError, formHeader, successMessage } from './styles.module.scss';

const DEBUG = false;

interface ChangePasswordFormErrors {
  emptyCurrentPasswordField: string;
  emptyNewPasswordField: string;
  emptyConfirmPasswordField: string;
  passwordIsTooShort: string;
  passwordsDoNotMatch: string;
}

const validationSchema = (error: ChangePasswordFormErrors) => {
  return yup.object().shape({
    currentPassword: yup
      .string()
      .required(error.emptyCurrentPasswordField)
      .min(8, error.passwordIsTooShort),
    newPassword: yup
      .string()
      .required(error.emptyNewPasswordField)
      .min(8, error.passwordIsTooShort),
    confirmNewPassword: yup
      .string()
      .required(error.emptyConfirmPasswordField)
      .min(8, error.passwordIsTooShort)
      .oneOf([yup.ref('newPassword')], error.passwordsDoNotMatch),
  });
};

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const initialValues: FormData = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

interface Props {
  closeModal: () => void;
  updateModalState: (val: boolean) => void;
}

export const ChangePasswordForm: React.FC<Props> = ({ closeModal, updateModalState }) => {
  const api = usePvepApi();
  const { email } = api.state;
  const content = useMyAccountResource();

  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);

  const handleEnterKey = (event: any) => {
    if (submitRef && event.key === 'Enter') {
      // @ts-ignore
      submitRef.current.click();
    }
  };

  return (
    <Formik
      onSubmit={async (values: FormData, { setSubmitting }) => {
        setSubmitting(true);
        DEBUG && ClientLogger.debug('ChangePassword.onSubmit', '', DEBUG, values);

        const resp = await api.updateUserPassword(email, values.newPassword, values.currentPassword);
        DEBUG && ClientLogger.debug('ChangePassword.response', '', DEBUG, resp);

        if (resp.data?.updateUserPassword.success) {
          setErrorMessage('');
          setShowSuccessMessage(true);
          setTimeout(() => {
            updateModalState(false);
          }, 1000);
        } else {
          setErrorMessage(`Password incorrect.`);
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema(content.accountTabErrors)}
    >
      {props => {
        DEBUG && ClientLogger.debug('ChangePassword:', 'render', DEBUG, props);
        return (
          <FormContainer
            className={changePasswordForm}
            onSubmit={props.handleSubmit}
            errorMessage={errorMessage && <div className={textError}>{errorMessage}</div>}
          >
            {showSuccessMessage ? (
              <div className={successMessage}>Password changed successfully.</div>
            ) : (
              <>
                <h1 className={formHeader}>
                  <button type="button" onClick={() => closeModal()} className="delete is-pulled-right mb-4 mt-0" />
                  Change Password
                </h1>
                <TextField
                  dataCY="currentPassword"
                  handleenter={handleEnterKey}
                  placeholder="Current Password"
                  fieldType="password"
                  fieldName="currentPassword"
                />
                <TextField
                  dataCY="newPassword"
                  handleenter={handleEnterKey}
                  placeholder="New Password"
                  fieldType="password"
                  fieldName="newPassword"
                />
                <TextField
                  dataCY="confirmNewPassword"
                  handleenter={handleEnterKey}
                  placeholder="Confirm New Password"
                  fieldType="password"
                  fieldName="confirmNewPassword"
                />
                <Button dataCY="submitNewPassword" type="submit" disabled={!(props.isValid && props.dirty)} refs={submitRef}>
                  Submit
                </Button>
              </>
            )}
          </FormContainer>
        );
      }}
    </Formik>
  );
};
