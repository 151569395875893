import { ClientLogger } from '@lib/ClientLogger';
import { IPageProps } from '@src/cms/cms-types';
import { IFrontMatterLegal } from '@templates/legal/model';
import React from 'react';
import IndexLayout from '@src/layouts';
import { pageContainer, pageTitle, pageContent } from './styles.module.scss';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const DEBUG = false;

const LegalPage = (props: IPageProps<IFrontMatterLegal>) => {
  const pageContext = props.pageContext.frontMatter;
  DEBUG && ClientLogger.debug('Legal Page', 'render', props);
  return (
    <IndexLayout noVideo>
      <div className={pageContainer}>
        {pageContext.title && <h1 className={pageTitle}>{pageContext.title}</h1>}
        <ReactMarkdown
          className={pageContent}
          children={pageContext.content}
          // @ts-ignore
          rehypePlugins={[rehypeRaw]}
        />
      </div>
    </IndexLayout>
  );
};

export default LegalPage;
