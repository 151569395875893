import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { useProductData } from '@lib/shopify/useProductData';
import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import IndexLayout from '@layouts/.';
import Page from '@components/Page';
import { VideoPlaceHolder } from '@components/VideoPlayer/VideoPlaceHolder';
import { useAnalyticsCapture } from '@lib/AnalyticsCapture';
import { ClientLogger } from '@lib/ClientLogger';
import { isMobile, isTablet } from '@lib/responsive';
import { useVideoState, VideoPlayerSize, VideoStateContext } from '@lib/useVideoState';
import { BrandUtil, VideoSource } from '@sharedLib/index';
import { BreadCrumbs } from '@components/BreadCrumbs';
import { NavStateContext } from '@lib/navContext';
import { ProductDetails } from '@components/ProductDetails';
import { SignUpPrompt } from '@components/SignUpPrompt';
import { usePvepApi } from '@apiClient/usePvepApi';
import classnames from 'classnames';
import { useProductResource } from '@templates/product/useProductResource';
import { compactScreen } from './styles.module.scss';
import { isBrowser } from '@src/lib/build';

interface ProductTemplateProps {
  pageContext: {
    productId: string;
  };
}

const DEBUG = false; // Causes huge output during build
const DEBUG_ANALYTICS = false;

const ProductTemplatePage = ({ pageContext }: ProductTemplateProps) => {
  const { productId } = pageContext;
  const productData = useProductData();
  const product = productData.getProduct(productId);
  const videoState = useVideoState();
  const { state } = useContext(VideoStateContext);
  const { videoPlayerSize } = state;
  const isMobileScreenSize = isMobile();
  const isTabletSize = isTablet();
  const analyticsCapture = useAnalyticsCapture();
  const lastProductId = useRef<string | undefined>(undefined);

  const api = usePvepApi();
  const userState = api.state;
  const { isLoggedIn } = userState;

  const { productNeedsRegistration, hasSignup } = BrandUtil.getSiteInfo();

  const content = useProductResource();

  const nav = useContext(NavStateContext);
  const navData = nav.navState.pageData;
  // @ts-ignore
  const options = navData[navData.length - 1]?.state;
  DEBUG && ClientLogger.debug('ProductTemplatePage', 'render', { options: options || 'undefined', navData, state });

  // ClientLogger.debug('ProductTemplatePage', 'render', DEBUG, { state, video, videoPlayerSize, isMobileScreenSize });
  useEffect(() => {
    if (state.video) {
      // set player to medium embedded if screen shrinks
      if (videoPlayerSize !== VideoPlayerSize.MEDIUM_EMBEDDED) {
        ClientLogger.debug('ProductTemplatePage', 'useEffect: setting to MEDIUM_EMBEDDED', DEBUG);
        videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
      }
    } else {
      ClientLogger.debug('ProductTemplatePage', 'useEffect: setting to NOT_SHOWN', DEBUG);
      videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
    }
  }, [isTabletSize, isMobileScreenSize]);

  useEffect(() => {
    const recordProductHit = product && product.id !== lastProductId.current;
    DEBUG_ANALYTICS &&
      ClientLogger.debug('ProductTemplatePage', 'useEffect Analytics', { product, recordProductHit, last: lastProductId.current });
    if (product && recordProductHit) {
      analyticsCapture.productHit('view', product, { adStrategy: videoState.state.currentAdStrategy });
      lastProductId.current = product.id;
    }
  }, [product, analyticsCapture]);

  if (!isBrowser) {
    return <></>;
  }
  if (!product) {
    return <ErrorDisplay errorData={{ errors: [`Cannot find product ${productId}`] }} />;
  }

  // show prompt if logged out and no video is playing, don't show prompt if logged in or if the video playing is free/from youtube
  const showPrompt = isLoggedIn ? false : state.video ? !state.video?.isFree && state.video?.type === VideoSource.VIMEO_OTT : true;

  return (
    <IndexLayout
      needsLogin={productNeedsRegistration}
      needsSubscription={productNeedsRegistration}
      hideFeedback
      showStrategyPreview={userState.isAdmin || userState.isManager}
    >
      <Helmet>
        <title>{product.title}</title>
      </Helmet>
      <Page>
        <VideoPlaceHolder videoId={state.video?.id || ''} />
        <BreadCrumbs productCrumb={{ label: product.title }} />

        <div data-cy-product-page={product.id} className={classnames(showPrompt ? compactScreen : '')}>
          <ProductDetails applyPlayerStyling={false} video={state.video} adPlacements={state.adPlacements} productId={product.id} />
        </div>
        {showPrompt && hasSignup && (
          <SignUpPrompt
            primaryText={content.signUpPrompt?.promptText.primary}
            secondaryText={content.signUpPrompt?.promptText.secondary}
            ctaText={content.signUpPrompt?.promptCTA}
            destinationUrl={`/product/${productId}/`}
          />
        )}
      </Page>
    </IndexLayout>
  );
};

export default ProductTemplatePage;
