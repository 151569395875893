// extracted by mini-css-extract-plugin
export var subHeaderTextWrapper = "styles-module--subHeaderTextWrapper--2CAMO";
export var accountDetailsWrapper = "styles-module--accountDetailsWrapper--G5FU9";
export var accountInfoForm = "styles-module--accountInfoForm--2WEk8";
export var infoWrapper = "styles-module--infoWrapper--2vh-o";
export var headerText = "styles-module--headerText--387vr";
export var subHeaderText = "styles-module--subHeaderText--37HhA";
export var pper = "styles-module--pper--319K0";
export var section = "styles-module--section--1Te_N";
export var link = "styles-module--link--1qF3w";
export var hideLinks = "styles-module--hideLinks--3xbA1";
export var info = "styles-module--info--zuSQB";
export var currentPlanName = "styles-module--currentPlanName--1ZOin";
export var currentPlanDescription = "styles-module--currentPlanDescription--BUowQ";
export var currentPlanExpiry = "styles-module--currentPlanExpiry--O2UFa";