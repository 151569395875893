import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import React, { useContext, useState } from 'react';
import { Button } from '@components/Buttons';
import classNames from 'classnames';
import { GoogleLoginButton, FacebookLoginButton } from '@components/Buttons/LoginButton';
import { isBrowser } from '@lib/build';
import { Checkbox, FormContainer } from '@components/Forms';
import { AlreadyMemberLogIn } from './AlreadyMemberLogIn';
import { formHeader, signUpForm, textError, signUpButtonWrapper, checkboxLabels, link } from './styles.module.scss';
import { useSignUpResource } from './useSignUpResources';
import { BrandUtil } from '@sharedLib/util';
import { Formik } from 'formik';
import { Link } from 'gatsby';
import * as yup from 'yup';

interface Props {
  onClickSignupWithEmail: () => void;
  onSignUpWithProviderSuccess: () => void;
}

const DEBUG = false;

interface SignUpFormErrors {
  emptyTermsField: string;
}

const validationSchema = (error: SignUpFormErrors) => {
  return yup.object().shape({
    termsOfUse: yup.bool().oneOf([true], error.emptyTermsField),
  });
};

interface FormData {
  termsOfUse: boolean;
}

const initialValues = {
  termsOfUse: false,
};

export const FacebookGoogleSignUp = ({ onClickSignupWithEmail, onSignUpWithProviderSuccess }: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageRedirection, setErrorMessageRedirection] = useState('');
  const api = usePvepApi();
  const nav = useContext(NavStateContext);
  const content = useSignUpResource();

  const onClickLogin = () => {
    if (isBrowser) {
      nav.setRoot({ path: '/login' });
    }
  };

  const onClickSignupEmail = () => {
    onClickSignupWithEmail();
  };

  const onSuccessProviderLogin = (response: any, provider: 'FACEBOOK' | 'GOOGLE') => {
    DEBUG && ClientLogger.debug('FacebookGoogleSignUp', 'onSuccessProviderLogin', { response, provider });
    api
      .loginWithProvider(response, provider)
      .then(result => {
        DEBUG && ClientLogger.debug('FacebookGoogleSignUp', 'loginWithProvider', { result });
        if (result.data) {
          if (result.data.exchangeProviderCodeForAuth.success) {
            onSignUpWithProviderSuccess();
          } else {
            setErrorMessage(
              result.data.exchangeProviderCodeForAuth.errorMessages
                ? result.data.exchangeProviderCodeForAuth.errorMessages.map(e => e.errorMessage).join(', ')
                : 'unknown error'
            );
          }
        } else {
          setErrorMessage(result.errors ? result.errors.map(e => e.message).join(', ') : 'unknown error');
        }
      })
      .catch(e => {
        ClientLogger.error('FacebookGoogleSignUp', 'Error caught', e);
        setErrorMessage(JSON.stringify(e));
      });
  };

  const onFailLoginProvider = (error: any) => {
    ClientLogger.error('FacebookGoogleSignUp', `onFailLoginProvider ${ClientLogger.errorToText(error)}`, error);
    setErrorMessage('Something went wrong. Please try again or ');
    setErrorMessageRedirection('Login');
  };

  return (
    <Formik
      onSubmit={async (_values: FormData, { setSubmitting }) => {
        setSubmitting(true);
      }}
      initialValues={initialValues}
      validationSchema={validationSchema(content.signUpEmailPage.errors)}
    >
      {props => {
        return (
          <FormContainer
            className={signUpForm}
            errorMessage={
              errorMessage && (
                <div className={textError}>
                  {errorMessage}
                  {errorMessageRedirection !== '' && <span onClick={onClickLogin}>{errorMessageRedirection}</span>}
                </div>
              )
            }
          >
            <h1 className={classNames(formHeader)}>{content.mainPage.header}</h1>
            <div>
              <Checkbox
                fieldName="termsOfUse"
                checked={props.values.termsOfUse}
                onChange={props.handleChange}
                errorMessage={props.errors.termsOfUse}
              >
                <div className={checkboxLabels}>
                  {content.signUpEmailPage.checkListTwo.start}
                  <Link to={`${BrandUtil.getSiteInfo().siteUrl}/terms-and-conditions`} target="_blank" className={link} rel="noreferrer">
                    {content.signUpEmailPage.checkListTwo.linkOne}
                  </Link>
                  {content.signUpEmailPage.checkListTwo.middle}
                  <Link to={`${BrandUtil.getSiteInfo().siteUrl}/privacy`} target="_blank" className={link} rel="noreferrer">
                    {content.signUpEmailPage.checkListTwo.linkTwo}
                  </Link>
                </div>
              </Checkbox>
            </div>

            <FormContainer onSubmit={props.handleSubmit} className={signUpButtonWrapper}>
              <Button dataCY="signupWithEmail" onClick={onClickSignupEmail} disabled={!(props.isValid && props.dirty)}>
                {content.mainPage.signUpCTA.emailSignUp}
              </Button>
              <GoogleLoginButton
                onSuccess={resp => {
                  onSuccessProviderLogin(resp, 'GOOGLE');
                }}
                onFailure={onFailLoginProvider}
                text={content.mainPage.signUpCTA.googleSignUp}
                disabled={!(props.isValid && props.dirty)}
              />
              {BrandUtil.getSiteInfo().enableFacebookLogin && (
                <FacebookLoginButton
                  callback={resp => {
                    onSuccessProviderLogin(resp, 'FACEBOOK');
                  }}
                  onFailure={onFailLoginProvider}
                  text={content.mainPage.signUpCTA.facebookSignUp}
                  disabled={!(props.isValid && props.dirty)}
                />
              )}
              <AlreadyMemberLogIn content={content.mainPage.member.message} link="/login" linkText={content.mainPage.member.loginLink} />
            </FormContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
