import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';
import { ClientLogger } from '@src/lib/ClientLogger';
import { usePvepApi } from '@apiClient/usePvepApi';
import IndexLayout from '@src/layouts';
import Background from '@src/components/Background';
import { TextField } from '@components/Forms';
import { SubmitButton } from '@components/Buttons';
import ThankYouComponent from '@pageComponents/ThankYou';
import { useContactUsResource } from '@pageComponents/ContactUs/useContactUsResources';
import {
  contactUs,
  contactUsWrapper,
  formHeader,
  formSubHeader,
  contactUsButtonWrapper,
  supportEmail,
  supportMessage,
  columns,
  column,
} from './styles.module.scss';

const DEBUG = false;

interface ContactUsFormErrors {
  emptyNameField: string;
  invalidNameError: string;
  emptyEmailField: string;
  invalidEmailError: string;
  emptyMessageField: string;
}

const contactUsSchema = (error: ContactUsFormErrors) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[A-Za-z]+$/, error.invalidNameError)
      .required(error.emptyNameField),
    lastName: yup
      .string()
      .matches(/^[A-Za-z]+$/, error.invalidNameError)
      .required(error.emptyNameField),
    email: yup
      .string()
      .email(error.invalidEmailError)
      .required(error.emptyEmailField),
    message: yup.string().required(error.emptyMessageField),
  });
};

const ContactUs = () => {
  const api = usePvepApi();
  const content = useContactUsResource();
  const [thankYouState, setThankYouState] = useState<boolean>(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  };

  return (
    <IndexLayout noVideo>
      <Background />
      {thankYouState && <ThankYouComponent setThankYouState={setThankYouState} />}
      <div className={contactUsWrapper}>
        <Formik
          initialValues={initialValues}
          validationSchema={contactUsSchema(content.errors)}
          onSubmit={async (values, actions) => {
            ClientLogger.debug('ContactUs.onSubmit', '', DEBUG, values);
            const resp = await api.submitContactForm(
              values.email,
              values.message,
              `${values.firstName} ${(values.firstName, values.lastName)}`
            );
            ClientLogger.debug('ContactUs.onSubmit', `resp = ${JSON.stringify(resp)}`, DEBUG, values);
            if (resp) {
              setThankYouState(true);
              actions.resetForm();
            } else {
              alert('Sorry, something went wrong with your submission');
            }
          }}
          render={props => (
            <form onSubmit={props.handleSubmit} data-cy="contactUsForm" className="standard-form__wrapper">
              <div className={classNames('standard-form__container', contactUs)}>
                <div className={classNames('standard-form__header', formHeader)}>{content.mainHeader}</div>
                <div className={classNames('standard-form__header', formSubHeader)}>{content.subHeader}</div>
                <div className={classNames(columns, 'is-vcentered')}>
                  <TextField
                    dataCY="firstName"
                    headerText="First Name"
                    fieldType="text"
                    fieldName="firstName"
                    className={classNames(column, 'is-half')}
                  />
                  <TextField
                    dataCY="lastName"
                    headerText="Last Name"
                    fieldType="text"
                    fieldName="lastName"
                    className={classNames(column, 'is-half')}
                  />
                </div>
                <div className={columns}>
                  <TextField
                    dataCY="email"
                    headerText="Email"
                    fieldType="email"
                    fieldName="email"
                    className={classNames(column, 'is-full')}
                  />
                </div>
                <div className={columns}>
                  <TextField
                    dataCY="message"
                    fieldType="text"
                    rows={5}
                    fieldName="message"
                    headerText="Message"
                    className={classNames(column, 'is-full')}
                  />
                </div>
                <div className={classNames(columns, contactUsButtonWrapper)}>
                  <SubmitButton disabled={props.isSubmitting || !props.isValid}>{content.cta}</SubmitButton>
                </div>
                {content.additionalMessages.additionalMessage && content.additionalMessages?.additionalEmail && (
                  <div className={supportMessage}>
                    {content.additionalMessages.additionalMessage}
                    <a href={`mailto:${content.additionalMessages.additionalEmail}`} className={supportEmail}>
                      {` ${content.additionalMessages.additionalEmail}`}
                    </a>
                  </div>
                )}

                <div className={supportMessage}>
                  {content.supportMessage}
                  <a href={`mailto:${content.supportEmail}`} className={supportEmail}>
                    {` ${content.supportEmail}`}
                  </a>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </IndexLayout>
  );
};

export default ContactUs;
