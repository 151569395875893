import { ApolloError } from '@apollo/client';
import { ClientLogger } from '@lib/ClientLogger';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { ErrorDetails } from '@apiClient/types';

const DEBUG = false;

export interface ErrorData {
  commonResponseErrorDetails?: ErrorDetails[] | null;
  apolloErrors?: ApolloError;
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  errors?: string[];
  error?: any;
}

export class ErrorFormatter {
  constructor(public readonly errorData: ErrorData) {}

  public displayErrors(): string {
    const errors: (string | null | undefined)[] = [];
    this.errorData.commonResponseErrorDetails?.forEach(e => errors.push(JSON.stringify(e.errorMessage)));
    this.errorData.errors?.forEach(e => errors.push(JSON.stringify(e)));
    this.errorData.graphQLErrors?.forEach(e => errors.push(JSON.stringify(e.message)));
    if (this.errorData.error) {
      errors.push(ClientLogger.errorToText(this.errorData.error));
    }
    ClientLogger.debug('ErrorFormatter', 'displayErrors', DEBUG, { errorData: this.errorData, errors });
    return errors.join(', ');
  }
}
