import React from 'react';
import { AppProvider, Page, DisplayText, TextContainer, List, Layout, Link, TextStyle } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { pageBackground } from './styles.module.scss';
export const ShopifyApp = () => (
  <AppProvider i18n={enTranslations}>
    <link rel="stylesheet" href="https://unpkg.com/@shopify/polaris@7.5.0/build/esm/styles.css" />
    <div className={pageBackground}>
      <Page title=" ">
        <Layout>
          <Layout.Section>
            <TextContainer>
              <DisplayText size="large">Thanks for installing the Alibi Technologies Shopify App!</DisplayText>
              <DisplayText size="small">
                The Alibi Tech platform merges content and e-commerce into one video experience. Our app makes it possible for you to embed
                your Shopify product collection into our interactive shopping banner on your video. With the app installed, you may log in
                to your Alibi Tech account to continue with brand management.
              </DisplayText>
            </TextContainer>
          </Layout.Section>

          <Layout.Section>
            <img style={{ height: '300px' }} src="../AT_images/shopify_sample_image.png" alt="" />
          </Layout.Section>

          <Layout.Section>
            <TextContainer>
              <DisplayText size="small">A few things to note:</DisplayText>
            </TextContainer>
          </Layout.Section>

          <Layout.Section>
            <List type="bullet">
              <List.Item>
                <DisplayText size="small">This app is free</DisplayText>
              </List.Item>
              <List.Item>
                <DisplayText size="small">
                  Installing this app creates an API key that gives our platform permission to see products in your collection.
                </DisplayText>
              </List.Item>
              <List.Item>
                <DisplayText size="small">
                  It also provides the Shopify store name which is necessary for us to configure your brand.
                </DisplayText>
              </List.Item>
            </List>
          </Layout.Section>

          <Layout.Section>
            <TextContainer>
              <DisplayText size="small" element="p">
                You are able to uninstall the app at any time by clicking uninstall on the apps page. Please be mindful that uninstalling
                will disrupt any product collections in your video.
              </DisplayText>
              <DisplayText size="small">
                We have taken every effort to ensure that this app is secure. If you require support or have further questions about app
                performance or security, please contact us at{' '}
                <TextStyle variation="strong">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link external url={'mailto:support@alibitech.com'}>
                    support@alibitech.com
                  </Link>
                </TextStyle>
              </DisplayText>
            </TextContainer>
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  </AppProvider>
);
