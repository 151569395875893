import React from 'react';
import { Formik } from 'formik';
import Profile from '@src/pageComponents/MyAccount/AccountDetails/Name';
import Email from '@src/pageComponents/MyAccount/AccountDetails/Email';
import ResetPassword from '@src/pageComponents/MyAccount/AccountDetails/ResetPassword';
import DeleteAccount from '@src/pageComponents/MyAccount/AccountDetails/DeleteAccount';

const AccountDetails = () => {
  return (
    <Formik
      onSubmit={async (_values, { setSubmitting }) => {
        setSubmitting(false);
      }}
      initialValues={{}}
    >
      {_props => {
        return (
          <div>
            <Profile />
            <Email />
            <ResetPassword />
            <DeleteAccount />
          </div>
        );
      }}
    </Formik>
  );
};

export default AccountDetails;
