import { ClientLogger } from '@lib/ClientLogger';
import React, { useEffect, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button } from '@components/Buttons';
import { useVideoData } from '@lib/video-query/useVideoData';
import {
  spacer,
  flexContainer,
  fullWidth,
  errorMessage,
  halfWidthCell,
  borderSeparator,
  adminTitle,
  adminText,
  flexRow,
  adminCheckbox,
  gridTable,
  recognitionField,
  recognitionFieldRow,
  defaultSelectControl,
  alignCenter,
  tableCell,
  gridRightDecorator,
  gridBottomDecorator,
  fifthWidthCell,
  editorVideoLabel,
} from './styles.module.scss';
import { BrandUtil } from '@sharedLib/util';
import Select from 'react-select';
import classNames from 'classnames';

const DEBUG = false;

interface ObjectRecognitionJob {
  jobId: string;
  jobType: string;
  createdAt: any;
  updatedAt: any;
  status: string;
  brandId: string;
  payload: {
    fileName: string;
  };
}

const recognitionValidationSchema = yup.object().shape({
  brand: yup.string().required('Please select a brand'),
  video: yup.string().required('Please select a video'),
});

export const ObjectRecognitionForm = () => {
  DEBUG && ClientLogger.debug('ObjectRecognitionForm', 'render');
  const api = usePvepApi();
  const brandsInfo = BrandUtil.getAllBrands();
  const [bucketVideos, setBucketVideos] = useState<string[]>([]);
  const [bucketJson, setBucketJson] = useState<string[]>([]);
  const [recognitionJobs, setRecognitionJobs] = useState<ObjectRecognitionJob[]>([]);
  const [currentInterval, setCurrentInterval] = useState<NodeJS.Timeout | undefined>();
  const [showAnalyzed, setShowAnalyzed] = useState(false);
  const [pollingMessage, setPollingMessage] = useState('');
  const [startJobMessage, setStartJobMessage] = useState('');
  const [jobMessage, setJobMessage] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const videoData = useVideoData().videos;

  const brandOptions = brandsInfo.map(brand => {
    return {
      label: brand.brandId,
      value: brand.brandId,
    };
  });
  const videoOptions = bucketVideos
    .filter(video => {
      if (!showAnalyzed) {
        const analysisName = `${video.split('.').shift()}.json`;
        return bucketJson.indexOf(analysisName) === -1;
      } else {
        return true;
      }
    })
    .map(video => {
      return {
        value: video,
        label: video,
      };
    });

  //get db job data on mount
  useEffect(() => {
    getJobs();

    //clean up updating interval before unmount
    return () => {
      setCurrentInterval(undefined);
    };
  }, []);

  const getJobs = async () => {
    const jobData = await api.getObjectRecognitionJobs();
    if (jobData.data?.getObjectRecognitionJobs.success) {
      const recogJobs = jobData.data?.getObjectRecognitionJobs.jobs
        .map(job => {
          const payload = job.payload ? JSON.parse(job.payload) : undefined;
          return {
            jobId: job.jobId,
            jobType: job.jobType,
            createdAt: job.createdAt,
            updatedAt: job.updatedAt,
            status: job.status,
            brandId: job.brandId,
            payload,
          };
        })
        .sort((job1, job2) => {
          if (job1.updatedAt < job2.updatedAt) {
            return 1;
          } else if (job1.updatedAt > job2.updatedAt) {
            return -1;
          } else {
            return 0;
          }
        });
      setRecognitionJobs(recogJobs);
    } else {
      ClientLogger.error(
        'AdminPage:getJobs',
        `unable to get jobs from db, returned ${JSON.stringify(jobData.data?.getObjectRecognitionJobs.errorMessages)}`
      );
    }
  };

  //pull contents of the s3 bucket for the particular brand
  const handleRekognitionBrandChange = async (value: string) => {
    if (!value) return;

    const objectsResp = await api.getS3BucketContents('rekognition-video-alibitech', value);

    if (objectsResp.data?.getS3BucketContents.success) {
      let jsonFiles: string[] = [];
      let videoFiles: string[] = [];
      objectsResp.data?.getS3BucketContents.contents.forEach(entry => {
        if (entry.fileName) {
          const extension = entry.fileName.split('.').pop();
          if (extension === 'json') {
            jsonFiles.push(entry.fileName);
          } else {
            videoFiles.push(entry.fileName);
          }
        }
      });

      setBucketVideos(videoFiles);
      setBucketJson(jsonFiles);
    } else {
      ClientLogger.error(
        'AdminPage:handleRekognitionBrandChange',
        `could not get s3 bucket contents for selected brand, returned ${JSON.stringify(
          objectsResp.data?.getS3BucketContents.errorMessages
        )}`
      );
      setBucketVideos([]);
      setBucketJson([]);
    }
  };

  const getObjectData = async (jobId: string) => {
    const job = recognitionJobs.filter(job => job.jobId === jobId);
    const fileName = job[0].payload.fileName.split('/');
    if (job.length && fileName.length) {
      const resp = await api.getObjectRecognitionData(job[0].jobId, fileName[0], fileName[1].split('.')[0]);
      if (resp.data?.getObjectRecognitionData.success) {
        setUploadMessage(`Data uploaded successfully for job ID:${jobId}`);
        setTimeout(() => {
          setUploadMessage('');
        }, 3000);
      } else {
        setUploadMessage(`Data upload failed for job ID:${jobId}`);
      }
      getJobs();
    }
  };

  const toggleUpdate = () => {
    if (currentInterval) {
      clearInterval(currentInterval);
      setCurrentInterval(undefined);
      setPollingMessage('');
    } else {
      checkForUpdates();
    }
  };

  //set an interval to poll status of jobs
  const checkForUpdates = () => {
    const currentJobIds = recognitionJobs.reduce((filtered: string[], job) => {
      if (job.status === 'STARTED') {
        filtered.push(job.jobId);
      }
      return filtered;
    }, []);

    let count = 0;
    const inter = setInterval(async () => {
      const resp = await api.queryObjectRecognitionStatus(currentJobIds);

      //if jobs are done then get their data
      const finishedJobs = resp.data?.queryObjectRecognitionStatus.jobs;
      if (finishedJobs) {
        setJobMessage(`Found ${finishedJobs.length} finished jobs`);
        setTimeout(() => {
          setJobMessage('');
        }, 3000);

        finishedJobs.map(job => {
          if (job.jobStatus === 'SUCCEEDED') {
            getObjectData(job.jobId);
          }
        });
      }
      count++;

      setPollingMessage(`Polling AWS. Trying again in 5s, total time running: ${count * 5}s`);
      //max 5 minutes
      if (count >= 60) {
        clearInterval(inter);
        setCurrentInterval(undefined);
        setPollingMessage('');
      }
    }, 5000);
    setCurrentInterval(inter);
  };

  //start an object recognition job on form submission
  const handleObjectRecognitionSubmit = async (values: any) => {
    const fileName = `${values.brand}/${values.video}`;

    const startJobResp = await api.startObjectRecognition('rekognition-video-alibitech', fileName);
    if (!startJobResp.data?.startObjectRecognition.success) {
      ClientLogger.error(
        'AdminPage:handleObjectRecognitionSubmit',
        `Unable to start object recognition, returned ${JSON.stringify(startJobResp.data?.startObjectRecognition.errorMessages)}`
      );
      return;
    }

    const jobId = startJobResp.data?.startObjectRecognition.jobId;
    if (jobId) {
      setStartJobMessage(`Started Job with ID:${jobId}`);
      setTimeout(() => {
        setStartJobMessage(``);
      }, 3000);
    }

    getJobs();
  };

  return (
    <>
      <div className={flexContainer}>
        <Formik
          initialValues={{
            brand: '',
            video: '',
          }}
          validationSchema={recognitionValidationSchema}
          onSubmit={values => {
            handleObjectRecognitionSubmit(values);
          }}
        >
          {props => (
            <>
              <div className={adminTitle}>Video Object Recognition</div>
              <div className={classNames(recognitionFieldRow, flexRow)}>
                <label className={classNames(spacer, flexRow, recognitionField)}>
                  <div className={adminText}>Brand:</div>
                  <Select
                    value={brandOptions.filter(opt => opt.value === props.values.brand)}
                    name={`brand`}
                    onChange={opt => {
                      props.setFieldValue(`brand`, opt?.value);
                      handleRekognitionBrandChange(opt?.value || '');
                    }}
                    isClearable
                    options={brandOptions}
                    className={defaultSelectControl}
                    classNamePrefix="productSelect"
                  />
                </label>
                {props.errors.brand && props.touched.brand && <div className={classNames(spacer, errorMessage)}>{props.errors.brand}</div>}

                <label className={classNames(spacer, flexRow, recognitionField)}>
                  <div className={adminText}>Video ID:</div>

                  <Select
                    value={videoOptions.filter(opt => opt.value === props.values.video)}
                    name={`video`}
                    onChange={opt => {
                      props.setFieldValue(`video`, opt?.value);
                    }}
                    isClearable
                    options={videoOptions}
                    className={defaultSelectControl}
                    classNamePrefix="productSelect"
                    isDisabled={!props.values.brand}
                  />
                </label>
              </div>

              <label className={classNames(spacer, flexRow, alignCenter)}>
                <input type="checkbox" checked={showAnalyzed} onChange={() => setShowAnalyzed(!showAnalyzed)} className={adminCheckbox} />
                <div className={adminText}>Include previously analyzed videos</div>
              </label>
              {props.errors.video && props.touched.video && <div className={classNames(spacer, errorMessage)}>{props.errors.video}</div>}

              <Button type="submit" onClick={props.handleSubmit} className={spacer}>
                START RECOGNITION
              </Button>
              {startJobMessage && <div className={spacer}>{startJobMessage}</div>}
            </>
          )}
        </Formik>
      </div>

      <div className={borderSeparator} />

      <div className={flexContainer}>
        <div className={adminTitle}>Completed analyses for selected brand</div>

        <div className={gridTable}>
          {bucketJson.map((entry, i) => {
            return (
              <div key={i} className={classNames(flexRow, fullWidth)}>
                <div className={classNames(tableCell, halfWidthCell)}>
                  <div className={adminText}>{videoData.find(video => entry.split('.').shift() === video.id)?.title || ''}</div>
                </div>
                <div className={classNames(tableCell, halfWidthCell)}>
                  <div className={adminText}>{entry.split('.').shift()}</div>
                  <div className={editorVideoLabel}>Video ID</div>
                </div>
              </div>
            );
          })}
          <div className={gridRightDecorator} />
          <div className={gridBottomDecorator} />
        </div>
      </div>

      <div className={borderSeparator} />

      <div className={flexContainer}>
        <div className={adminTitle}>Object recognition jobs on this branch</div>
        <Button onClick={() => toggleUpdate()} className={spacer}>
          {currentInterval ? 'STOP CHECKING FOR UPDATES' : 'START CHECKING FOR UPDATES'}
        </Button>
        {pollingMessage && <div className={classNames(spacer, adminText)}>{pollingMessage}</div>}
        {jobMessage && <div className={classNames(spacer, adminText)}>{jobMessage}</div>}
        {uploadMessage && <div className={classNames(spacer, adminText)}>{uploadMessage}</div>}
        <div className={classNames(spacer, gridTable)}>
          {recognitionJobs.map(job => {
            return (
              <div key={job.jobId} className={classNames(flexRow, fullWidth)}>
                <div className={classNames(tableCell, fifthWidthCell)}>
                  <div className={adminText}>
                    {videoData.find(
                      video =>
                        job.payload?.fileName
                          .split('/')
                          .pop()
                          ?.split('.')
                          .shift() === video.id
                    )?.title || ''}
                  </div>
                </div>
                <div className={classNames(tableCell, fifthWidthCell)}>
                  <div className={adminText}>{job.status}</div>
                  <div className={editorVideoLabel}>Status</div>
                </div>
                <div className={classNames(tableCell, fifthWidthCell)}>
                  <div className={adminText}>{job.payload?.fileName}</div>
                  <div className={editorVideoLabel}>S3 Filename</div>
                </div>
                <div className={classNames(tableCell, fifthWidthCell)}>
                  <div className={adminText}>{new Date(job.createdAt).toLocaleDateString()}</div>
                  <div className={editorVideoLabel}>Created</div>
                </div>
                <div className={classNames(tableCell, fifthWidthCell)}>
                  <div className={adminText}>{new Date(job.updatedAt).toLocaleDateString()}</div>
                  <div className={editorVideoLabel}>Updated</div>
                </div>
              </div>
            );
          })}
          <div className={gridRightDecorator} />
          <div className={gridBottomDecorator} />
        </div>
      </div>
    </>
  );
};
