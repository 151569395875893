// extracted by mini-css-extract-plugin
export var feedbackContainer = "styles-module--feedbackContainer--2GwFm";
export var feedbackContainerOpen = "styles-module--feedbackContainerOpen--fjX8N";
export var feedbackContainerMobile = "styles-module--feedbackContainerMobile--3vX9U";
export var feedbackContainerMobileHidden = "styles-module--feedbackContainerMobileHidden--1cNWI";
export var feedbackContentSpacer = "styles-module--feedbackContentSpacer--1OdR9";
export var feedbackContentContainer = "styles-module--feedbackContentContainer--2AF4x";
export var feedbackTab = "styles-module--feedbackTab--1b85V";
export var feedbackModal = "styles-module--feedbackModal--15e6r";
export var feedbackModalOpen = "styles-module--feedbackModalOpen--34G36";
export var feedbackMain = "styles-module--feedbackMain--8Xv8E";
export var closeButton = "styles-module--closeButton--2SzS9";
export var logoContainer = "styles-module--logoContainer--2TRSv";
export var flexRow = "styles-module--flexRow--1vETX";
export var feedbackSelection = "styles-module--feedbackSelection--25Oya";
export var feedbackSelectionTitle = "styles-module--feedbackSelectionTitle--i6mtW";
export var feedbackSelectionImage = "styles-module--feedbackSelectionImage--2rDbu";
export var feedbackSelectionText = "styles-module--feedbackSelectionText--2lZCm";
export var smallText = "styles-module--smallText--1PYYA";
export var generalOption = "styles-module--generalOption--2bqg6";
export var specificOptionContainer = "styles-module--specificOptionContainer--p3fcn";
export var radioButton = "styles-module--radioButton--1nEeb";
export var feedbackTextarea = "styles-module--feedbackTextarea--2WnGm";
export var feedbackButton = "styles-module--feedbackButton--25yix";
export var errorMessage = "styles-module--errorMessage--3gkt5";
export var feedbackMessage = "styles-module--feedbackMessage--1_msi";