import React, { useEffect, useState } from 'react';
import { FormContainer } from '@src/components/Forms';
import { Formik } from 'formik';
import classNames from 'classnames';
import { ButtonStyles, SubmitButton } from '@src/components/Buttons';
import { useVideoState, VideoPlayerSize } from '@src/lib/useVideoState';
import {
  accountInfoForm,
  infoWrapper,
  subHeaderText,
  tabContainer,
  submitButton,
  leftButton,
  myAccountModal,
  myAccountModalBackground,
  myAccountModalCard,
} from '../styles.module.scss';

import { DeleteAccountForm } from './DeleteAccountForm';

const DeleteAccount = () => {
  const videoState = useVideoState();
  useEffect(() => {
    if (videoState.state.videoPlayerSize !== VideoPlayerSize.NOT_SHOWN) {
      videoState.pause();
      videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
    }
  });

  const [showModal, setShowModal] = useState(false);

  // child components can call this method to close the modal
  const updateModalFromChild = (val: boolean) => {
    setShowModal(val);
  };

  return (
    <>
      <Formik
        onSubmit={async _values => {
          setShowModal(true);
        }}
        initialValues={{}}
      >
        {props => {
          return (
            <div className={classNames('column is-half', tabContainer)}>
              <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
                <div className={classNames(infoWrapper)}>
                  <h1 className={subHeaderText}>Account</h1>
                </div>
                <div className={classNames(submitButton, leftButton)}>
                  <SubmitButton dataCY="deleteAccountBtn" disabled={!props.isValid} style={ButtonStyles.Greyscale}>
                    Delete Account
                  </SubmitButton>
                </div>
              </FormContainer>
            </div>
          );
        }}
      </Formik>

      {showModal && (
        <div className={myAccountModal}>
          <div className={myAccountModalBackground} onClick={() => setShowModal(false)} />
          <div className={myAccountModalCard}>
            <DeleteAccountForm closeModal={() => setShowModal(false)} updateModalState={updateModalFromChild} />
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteAccount;
