// extracted by mini-css-extract-plugin
export var accountInfoForm = "styles-module--accountInfoForm--1HT4i";
export var changePasswordForm = "styles-module--changePasswordForm--3RrOX";
export var deleteAccountForm = "styles-module--deleteAccountForm--WIIuI";
export var textError = "styles-module--textError--TiZvg";
export var formHeader = "styles-module--formHeader--1YjOg";
export var formSmallHeader = "styles-module--formSmallHeader--2MHsg";
export var successMessage = "styles-module--successMessage--176AU";
export var checkboxLabels = "styles-module--checkboxLabels--1VZg9";
export var buttonsContainer = "styles-module--buttonsContainer--3UWsL";
export var deleteAccountButton = "styles-module--deleteAccountButton--ug6Kd";