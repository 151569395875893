// extracted by mini-css-extract-plugin
export var subHeaderTextWrapper = "styles-module--subHeaderTextWrapper--4JHnK";
export var forgotPasswordForm = "styles-module--forgotPasswordForm--SUUB3";
export var formHeader = "styles-module--formHeader--3gXG9";
export var formText = "styles-module--formText--33vIy";
export var formTextSmall = "styles-module--formTextSmall--dPu1u";
export var textError = "styles-module--textError--3ECO2";
export var link = "styles-module--link--2pZGu";
export var narrowButton = "styles-module--narrowButton--25Zzv";
export var info = "styles-module--info--3yJKm";
export var hidden = "styles-module--hidden--3LUQu";
export var returnButton = "styles-module--returnButton--3geiN";