// extracted by mini-css-extract-plugin
export var logo = "styles-module--logo--1d--w";
export var landingGreeting = "styles-module--landingGreeting--iVoMc";
export var formFix = "styles-module--formFix--nOFqD";
export var embeddedVideo = "styles-module--embeddedVideo--3nNwD";
export var product = "styles-module--product--1z2JO";
export var compactScreen = "styles-module--compactScreen--2TU3E";
export var productContainer = "styles-module--productContainer--34lqV";
export var imgFull = "styles-module--imgFull--46wdo";
export var productCardContainer = "styles-module--productCardContainer--3FeWE";
export var productCard = "styles-module--productCard--1oJqX";
export var productImageWrapper = "styles-module--productImageWrapper--FMmgS";
export var productImage = "styles-module--productImage--1qKur";
export var productContent = "styles-module--productContent--1rQwT";
export var productTitle = "styles-module--productTitle--1Z4sQ";
export var productPrice = "styles-module--productPrice--2lBWD";
export var productDescription = "styles-module--productDescription--fHvgk";
export var buyNow = "styles-module--buyNow--1_F4G";
export var addButton = "styles-module--addButton--NCJ01";
export var selectorContainer = "styles-module--selectorContainer--i_x9-";
export var label = "styles-module--label--1TLK_";
export var variantSelector = "styles-module--variantSelector--VQQk3";
export var iconAffliate = "styles-module--iconAffliate--3BoNh";
export var floatRight = "styles-module--floatRight--1l_if";
export var mediaObject = "styles-module--mediaObject--1zVbt";