import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useVideoState, VideoPlayerSize } from '@src/lib/useVideoState';
import IndexLayout from '@src/layouts';
import AccountDetails from '@src/pageComponents/MyAccount/AccountDetails';
import Membership from '@src/pageComponents/MyAccount/Membership';
import { BrandUtil } from '@sharedLib/util/Brand';
import { useMyAccountResource } from '@src/pageComponents/MyAccount/useMyAccountResources';
import {
  container,
  leftPanelContainer,
  rightPanelContainer,
  verticalTabs,
  activeTabContentContainer,
  inActiveTabContentContainer,
  flexColumns,
} from './styles.module.scss';

const MyAccount = () => {
  const videoState = useVideoState();
  const config = BrandUtil.getSiteInfo(process.env.GATSBY_BRAND_ID);

  useEffect(() => {
    if (videoState.state.videoPlayerSize !== VideoPlayerSize.NOT_SHOWN) {
      videoState.pause();
      videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
    }
  });

  const content = useMyAccountResource();
  const [activeTab, setActiveTab] = useState<string>(content.accountTabName);

  return (
    <IndexLayout needsLogin noMargins noVideo>
      <div className={classNames(flexColumns, container)}>
        <div className={leftPanelContainer}>
          <div className={classNames(`tabs is-toggle is-rounded`, verticalTabs)}>
            <ul>
              {config.userSettings.account && (
                <li className={content.accountTabName === activeTab ? activeTab : ''} onClick={() => setActiveTab(content.accountTabName)}>
                  <div>{content.accountTabName}</div>
                </li>
              )}
              {config.userSettings.membership && (
                <li
                  className={content.membershipTabName === activeTab ? activeTab : ''}
                  onClick={() => setActiveTab(content.membershipTabName)}
                >
                  <div>{content.membershipTabName}</div>
                </li>
              )}
              {config.userSettings.payment && (
                <li className={content.paymentTabName === activeTab ? activeTab : ''} onClick={() => setActiveTab(content.paymentTabName)}>
                  <div>{content.paymentTabName}</div>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className={rightPanelContainer}>
          <div className={content.accountTabName === activeTab ? activeTabContentContainer : inActiveTabContentContainer}>
            <AccountDetails />
          </div>

          <div className={content.membershipTabName === activeTab ? activeTabContentContainer : inActiveTabContentContainer}>
            <Membership />
          </div>

          <div className={content.paymentTabName === activeTab ? activeTabContentContainer : inActiveTabContentContainer} />
        </div>
      </div>
    </IndexLayout>
  );
};

export default MyAccount;
