import { useStaticQuery, graphql } from 'gatsby';
import { ClientLogger } from '@lib/ClientLogger';
import {
  Collection,
  getParentCategories,
  getParentSeasons,
  getParentSeries,
  DatabaseCategory,
  DatabaseSeason,
  DatabaseSeries,
  DatabaseVideo,
  ascendingOrder,
} from '@sharedLib/index';

const DEBUG = false;

export function useVideoData(): {
  videos: DatabaseVideo[];
  categories: DatabaseCategory[];
  seasons: DatabaseSeason[];
  series: DatabaseSeries[];
  getSeries: (seriesId: string) => DatabaseSeries | undefined;
  getVideo: (id: string) => DatabaseVideo | undefined;
} {
  const data = useStaticQuery(graphql`
    query videosAndCollectionJson {
      allVideosJson {
        nodes {
          id
          title
          label
          description
          shortDescription
          url
          isAvailable
          isFree
          type
          thumbnailUrl
          adStrategies {
            experimentId
            experimentTitle
            strategyId
            strategyTitle
            rangePercentage
            ranges {
              start
              end
            }
            ads {
              id
              name
              productId
              rank
              startTimeIndex
              type
              reasonData {
                assignmentType
                assignmentId
              }
              isRecommendation
            }
          }
        }
      }
      allProcessedJson {
        nodes {
          categories {
            id
            isAvailable
            isFeatured
            isFree
            title
            items {
              categoryId
              id
              rank
              seriesId
              videoId
            }
          }
          seasons {
            id
            title
            items {
              id
              rank
              seasonId
              videoId
            }
          }
          series {
            id
            description
            isAvailable
            isFree
            shortDescription
            thumbnailUrl
            title
            items {
              id
              rank
              seasonId
              seriesId
            }
          }
        }
      }
    }
  `);
  const videos: DatabaseVideo[] = data.allVideosJson.nodes;
  const categories: DatabaseCategory[] = data.allProcessedJson.nodes.length ? data.allProcessedJson.nodes[0].categories : [];
  const series: DatabaseSeries[] = data.allProcessedJson.nodes.length ? data.allProcessedJson.nodes[0].series : [];
  const seasons: DatabaseSeason[] = data.allProcessedJson.nodes.length ? data.allProcessedJson.nodes[0].seasons : [];

  function getSeriesImp(seriesId: string): DatabaseSeries | undefined {
    return series.find(s => s.id === seriesId);
  }
  function getVideoImp(id: string): DatabaseVideo | undefined {
    return videos.find(v => v.id === id);
  }

  return { videos, categories, series, seasons, getSeries: getSeriesImp, getVideo: getVideoImp };
}

function getVideo(videos: DatabaseVideo[], id: string): DatabaseVideo | undefined {
  // Deprecated? use useVideoData.getVideo
  return videos.find(v => v.id === id);
}

function getSeries(allSeries: DatabaseSeries[], seriesId: string): DatabaseSeries | undefined {
  return allSeries.find(s => s.id === seriesId);
}

function getCategory(allCategories: DatabaseCategory[], categoryId: string): DatabaseCategory | undefined {
  return allCategories.find(c => c.id === categoryId);
}

function getSeason(allSeasons: DatabaseSeason[], seasonId: string): DatabaseSeason | undefined {
  return allSeasons.find(s => s.id === seasonId);
}

export function useVideo(id: string): DatabaseVideo | undefined {
  const videoData = useVideoData();
  return getVideo(videoData.videos, id);
}

export function useSeries(id: string): DatabaseSeries | undefined {
  // Deprecated? use useVideoData.getSeries
  const videoData = useVideoData();
  return getSeries(videoData.series, id);
}

export function usePlayNext(
  videoId: string,
  seasonId: string,
  seriesId: string,
  categoryId: string
): { video: DatabaseVideo | undefined; upNextEpisode: number } | undefined {
  const allVideoData = useVideoData();
  DEBUG &&
    ClientLogger.debug(
      'useVideoData:usePlayNext',
      `received: videoId=${videoId}, seasonId=${seasonId}, seriesId=${seriesId}, categoryId=${categoryId}`,
      DEBUG
    );

  if (seasonId) {
    // get season object
    const season = allVideoData.seasons.find(sea => sea.id === seasonId);
    if (season) {
      // find current episode
      const currentEpisode = getEpisodeNumberInSeason(season, videoId);
      if (!currentEpisode) {
        return undefined;
      }

      // determine next epoisode
      if (season.items.length > currentEpisode) {
        const nextSeasonItem = season.items[currentEpisode];
        return { video: allVideoData.videos.find(vid => vid.id === nextSeasonItem.videoId), upNextEpisode: currentEpisode + 1 };
      }
    }
  } else if (categoryId) {
    // if not in a season, check if directly in category
    const category = allVideoData.categories.find(cat => cat.id === categoryId);
    if (category) {
      // get current episode
      const currentEpisode = getEpisodeNumberInCategory(category, videoId);
      if (!currentEpisode) {
        return undefined;
      }

      // determine next episode if it is a video
      if (category.items.length > currentEpisode) {
        const nextCategoryItem = category.items[currentEpisode];
        if (!nextCategoryItem.videoId) {
          return undefined;
        }
        return { video: allVideoData.videos.find(vid => vid.id === nextCategoryItem.videoId), upNextEpisode: currentEpisode + 1 };
      }
    }
  }

  return undefined;
}

// if specific series is unknown, get the number from the first one that contains the season
export function getDefaultSeasonNumber(series: DatabaseSeries[], seasonId: string): { rank: number; seriesId: string } | undefined {
  for (const ser of series) {
    for (const item of ser.items) {
      if (item.seasonId === seasonId) {
        return { rank: item.rank + 1, seriesId: ser.id };
      }
    }
  }

  return undefined;
}

export function getSeasonNumberInSeries(series: DatabaseSeries, seasonId: string): number | undefined {
  for (const item of series.items) {
    if (item.seasonId === seasonId) {
      return item.rank + 1;
    }
  }

  return undefined;
}

// if specific season is unknown, get the number from the first one that contains the video
export function getDefaultEpisodeNumber(
  seasons: DatabaseSeason[],
  categories: DatabaseCategory[],
  videoId: string
): { rank: number; seasonId: string; categoryId: string } | undefined {
  for (const season of seasons) {
    for (const item of season.items) {
      if (item.videoId === videoId) {
        return { rank: item.rank + 1, seasonId: season.id, categoryId: '' };
      }
    }
  }

  for (const cat of categories) {
    for (const item of cat.items) {
      if (item.videoId === videoId) {
        return { rank: item.rank + 1, seasonId: '', categoryId: cat.id };
      }
    }
  }

  return undefined;
}

export function getEpisodeNumberInSeason(season: DatabaseSeason, videoId: string): number | undefined {
  for (const item of season.items) {
    if (item.videoId === videoId) {
      return item.rank + 1;
    }
  }

  return undefined;
}

export function getEpisodeNumberInCategory(category: DatabaseCategory, videoId: string): number | undefined {
  for (const item of category.items) {
    if (item.videoId === videoId) {
      return item.rank + 1;
    }
  }

  return undefined;
}

export function useVideoParentCollections(
  id: string | undefined
): { categories: DatabaseCategory[]; seasons: DatabaseSeason[]; series: DatabaseSeries[] } {
  const videoData = useVideoData();

  if (!id) {
    return {
      categories: [],
      series: [],
      seasons: [],
    };
  }

  const categories = getParentCategories(videoData.categories, videoData.series, videoData.seasons, id);
  const series = getParentSeries(videoData.series, videoData.seasons, id);
  const seasons = getParentSeasons(videoData.seasons, id);

  return {
    categories,
    series,
    seasons,
  };
}

export function useChildSeasons(seriesId: string): DatabaseSeason[] {
  const videoData = useVideoData();
  const allSeasons = videoData.seasons;
  const allSeries = videoData.series;

  const series = allSeries.find(ser => ser.id === seriesId);
  if (!series) {
    return [];
  }

  const seasonIds = series.items.map(item => item.seasonId);
  const seasons = allSeasons.filter(s => seasonIds.includes(s.id));

  return seasons;
}

export function useVideosOfSeason(seasonId: string): DatabaseVideo[] {
  const videoData = useVideoData();
  const season = videoData.seasons.find(s => s.id === seasonId);
  if (!season) {
    return [];
  }
  const videoIds = season.items.map(s => s.videoId);
  const videos = videoData.videos.filter(vid => vid.isAvailable && videoIds.includes(vid.id));
  return videos;
}

export function useVideosOfSeries(seriesId: string): DatabaseVideo[] {
  const videoData = useVideoData();
  const seasons = useChildSeasons(seriesId);
  const videoIds: string[] = [];
  seasons.map(season => {
    season.items.map(item => {
      videoIds.push(item.videoId);
    });
  });
  const videos = videoData.videos.filter(vid => vid.isAvailable && videoIds.includes(vid.id));
  return videos;
}

export function getCategoryItems(category: DatabaseCategory, allSeries: DatabaseSeries[], allVideos: DatabaseVideo[]) {
  const items: {
    item: DatabaseVideo | DatabaseSeries;
    type: 'series' | 'video';
  }[] = [];

  // get video and series items contained
  category.items
    .sort((a, b) => ascendingOrder(a.rank, b.rank))
    .map(catItem => {
      const isVideo = Boolean(catItem.videoId);

      if (isVideo) {
        const video = allVideos.find(vid => vid.id === catItem.videoId);
        if (video) {
          items.push({
            type: 'video',
            item: video,
          });
        }
      } else {
        const series = allSeries.find(series => series.id === catItem.seriesId);
        if (series) {
          items.push({
            type: 'series',
            item: series,
          });
        }
      }
    });
  return items;
}

function useFeaturedCollections(): DatabaseCategory[] {
  const videoData = useVideoData();
  const categories = videoData.categories.filter(cat => cat.isAvailable && cat.isFeatured);
  return categories;
}

export function useHomePageCollections(): DatabaseCategory[] {
  const videoData = useVideoData();
  const categories = videoData.categories.filter(cat => cat.isAvailable && !cat.isFeatured);
  return categories;
}

export function useVideoPageData(videoId: string) {
  const video = useVideo(videoId) || null;
  return { video };
}

export function useHomePageData() {
  const featuredCollections = useFeaturedCollections();
  const videoCollections = useHomePageCollections();
  return { featuredCollections, videoCollections };
}
