import { ClientLogger } from '@lib/ClientLogger';
import React, { useEffect, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from '@components/Buttons';
import {
  defaultSelectControl,
  adminCheckbox,
  adminRadio,
  metadataFormField,
  adminText,
  adminPadBottom,
  adminTitle,
  borderSeparator,
  copyToLocalForm,
  spacer,
  flexContainer,
  assignmentCollectionSelect,
} from './styles.module.scss';
import Select from 'react-select';
import { BrandUtil } from '@sharedLib/index';
import classNames from 'classnames';

const copyToLocalValidation = yup.object().shape({
  picked: yup.string().required('This is required'),
  skipImages: yup.boolean().required('This is required'),
});

const DEBUG = false;

interface ProductCollectionOption {
  value: string;
  label: string;
}

export const MetadataUpdateForm = () => {
  const api = usePvepApi();
  const brandInfo = BrandUtil.getSiteInfo();
  const [copyToLocalError, setCopyToLocalError] = useState('');
  const [copyToLocalSuccess, setCopyToLocalSuccess] = useState('');

  const [collectionsLoading, setCollectionsLoading] = useState(true);
  const [productCollections, setProductCollections] = useState<ProductCollectionOption[]>([]);
  const [includedCollections, setIncludedCollections] = useState<ProductCollectionOption[]>([]);
  const [productError, setProductError] = useState('');
  const [productCommitFeedback, setProductCommitFeedback] = useState('');

  const [rebuildBranchError, setRebuildBranchError] = useState('');
  const [rebuildBranchSuccess, setRebuildBranchSuccess] = useState('');
  const [rebuildLoading, setRebuildLoading] = useState(false);

  const handleBranchRebuild = async () => {
    setRebuildLoading(true);
    const resp = await api.rebuildBranch();
    if (resp.data?.rebuildBranch.success) {
      setRebuildBranchError('');
      setRebuildBranchSuccess('The request was successful, a new pipeline has started in Gitlab');
    } else {
      setRebuildBranchSuccess('');
      ClientLogger.error('AdminPage:handleBranchRebuild', JSON.stringify(resp.data?.rebuildBranch.errorMessages));
      setRebuildBranchError(
        `The request could not be fulfilled, please try again. The following error was returned: ${JSON.stringify(
          resp.data?.rebuildBranch.errorMessages
        )}`
      );
    }
    setRebuildLoading(false);
  };

  // get all product collections from shopify endpoint
  // get all included product collections from server
  useEffect(() => {
    async function getProductCollections() {
      const collectionResp = await api.getProductCollectionData();
      if (collectionResp.data?.getProductCollectionsFromShopify.success && collectionResp.data.getIncludedProductCollections.success) {
        setProductCollections(
          collectionResp.data.getProductCollectionsFromShopify.productCollections.map(collection => {
            return {
              value: collection.id,
              label: collection.title,
            };
          })
        );

        setIncludedCollections(
          collectionResp.data.getIncludedProductCollections.productCollections.map(collection => {
            return {
              value: collection.id,
              label: collection.title,
            };
          })
        );
        setProductError('');
      } else {
        setProductError('Unable to fetch product collections from Shopify.');
      }
      setCollectionsLoading(false);
    }

    getProductCollections();
  }, []);

  return (
    <>
      <div className={flexContainer}>
        <div className={adminTitle}>Product collections for this brand</div>
        <div className={adminPadBottom}>Assign product collections to your brand so they will be available in the Product Assignments</div>
        <Formik
          initialValues={{
            includedProductCollections: includedCollections.map(collection => collection.value),
          }}
          enableReinitialize
          onSubmit={async values => {
            const includeData = values.includedProductCollections.map(col => {
              return {
                id: col,
                title: productCollections.find(pc => pc.value === col)?.label || '',
              };
            });
            const commitResp = await api.writeIncludedProductCollections(JSON.stringify(includeData, null, 2));
            if (commitResp.data?.writeIncludedProductCollections.success) {
              setProductCommitFeedback('Commit was successful.');
            } else {
              setProductCommitFeedback(
                `Commit was not successful, please try again. Error returned: ${JSON.stringify(
                  commitResp.data?.writeIncludedProductCollections.errorMessages
                )}`
              );
            }
          }}
        >
          {props => (
            <>
              <Select
                value={productCollections.filter(collection => props.values.includedProductCollections.includes(collection.value))}
                name={`includedProductCollections`}
                onChange={opts => {
                  props.setFieldValue(
                    `includedProductCollections`,
                    opts.map(opt => opt.value)
                  );
                }}
                isMulti
                isClearable={false}
                options={productCollections}
                className={assignmentCollectionSelect}
                classNamePrefix="productSelect"
                isDisabled={Boolean(productError) || collectionsLoading}
              />
              <Button type="submit" onClick={props.handleSubmit} className={spacer}>
                UPDATE
              </Button>
              {productError && <div>{productError}</div>}
              {productCommitFeedback && <div>{productCommitFeedback}</div>}
            </>
          )}
        </Formik>
      </div>

      <div className={borderSeparator} />

      <div className={flexContainer}>
        <div className={adminTitle}>Metadata</div>
        <div className={adminPadBottom}>Select the metadata categories you would like to update for your brand.</div>
        <Formik
          initialValues={{
            picked: 'all',
            skipImages: false,
          }}
          validationSchema={copyToLocalValidation}
          onSubmit={async values => {
            const metadataResp = await api.requestMetadataUpdate(
              brandInfo.brandId,
              values.picked === 'products',
              values.picked === 'videos',
              values.picked === 'labels',
              values.skipImages
            );
            if (metadataResp.data?.requestMetadataUpdate.success) {
              setCopyToLocalError('');
              setCopyToLocalSuccess('The request was successful, a new build should begin now.');
            } else {
              setCopyToLocalSuccess('');
              ClientLogger.error('AdminPage:metadataUpdate', JSON.stringify(metadataResp.data?.requestMetadataUpdate.errorMessages));
              setCopyToLocalError(
                `The request could not be fulfilled, please try again. The following error was returned: ${JSON.stringify(
                  metadataResp.data?.requestMetadataUpdate.errorMessages
                )}`
              );
            }
          }}
        >
          {props => (
            <div className={copyToLocalForm}>
              <label className={classNames(spacer, metadataFormField)}>
                <Field type="radio" name="picked" value="videos" className={adminRadio} />
                <div className={adminText}>Update Videos</div>
              </label>
              <label className={metadataFormField}>
                <Field type="radio" name="picked" value="products" className={adminRadio} />
                <div className={adminText}>Update Products</div>
              </label>
              <label className={metadataFormField}>
                <Field type="radio" name="picked" value="labels" className={adminRadio} />
                <div className={adminText}>Update Object Recognition Labels</div>
              </label>
              <label className={metadataFormField}>
                <Field type="radio" name="picked" value="all" className={adminRadio} />
                <div className={adminText}>Update All Metadata (Videos, Products, and Labels)</div>
              </label>
              <label className={classNames(spacer, metadataFormField)}>
                <Field type="checkbox" name="skipImages" checked={props.values.skipImages} className={adminCheckbox} />
                <div className={adminText}>Skip Images</div>
              </label>
              <div>
                <Button type="submit" onClick={props.handleSubmit} className={spacer}>
                  UPDATE
                </Button>
              </div>

              {copyToLocalError && <div>{copyToLocalError}</div>}
              {copyToLocalSuccess && <div>{copyToLocalSuccess}</div>}
            </div>
          )}
        </Formik>
      </div>

      <div className={borderSeparator} />

      <div className={flexContainer}>
        <div className={adminTitle}>Cloud Builds</div>
        <div className={adminPadBottom}>Use this to rebuild your demo branch before pushing to your live site.</div>

        <Button onClick={() => handleBranchRebuild()} className={spacer} disabled={rebuildLoading}>
          REBUILD THIS BRANCH
        </Button>
        {rebuildBranchError && <div>{rebuildBranchError}</div>}
        {rebuildBranchSuccess && <div>{rebuildBranchSuccess}</div>}
      </div>
    </>
  );
};
