// extracted by mini-css-extract-plugin
export var embeddedVideo = "styles-module--embeddedVideo--2UKYJ";
export var nonEmbeddedVideo = "styles-module--nonEmbeddedVideo--2-8Ug";
export var shiftForMobileCard = "styles-module--shiftForMobileCard--3LEl5";
export var removeProductsForPrompt = "styles-module--removeProductsForPrompt--2y3fC";
export var largeVideoContainer = "styles-module--largeVideoContainer--1xGWd";
export var productsAndFooter = "styles-module--productsAndFooter--29aHn";
export var videoContainer = "styles-module--videoContainer--3bSyI";
export var gap = "styles-module--gap--2HLm1";
export var roomForVideo = "styles-module--roomForVideo--1Eo3k";
export var videoCTA = "styles-module--videoCTA--TmYPv";
export var productsCTA = "styles-module--productsCTA--3WqUS";
export var iconCTA = "styles-module--iconCTA--1ZmCW";
export var tableDebug = "styles-module--tableDebug--oImZj";