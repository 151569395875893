import React, { useRef, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { Button } from '@components/Buttons';
import {
  fileUploaderText,
  fileUploaderCustom,
  fileUploaderStyle,
  recognitionField,
  recognitionFieldRow,
  textField,
  defaultSelectControl,
  spacer,
  flexContainer,
  adminTitle,
  adminText,
  flexRow,
} from './styles.module.scss';
import Select from 'react-select';
import { BrandUtil } from '@sharedLib/index';
import classNames from 'classnames';

const uploadFileValidation = yup.object().shape({
  file: yup.mixed().required('A file is required'),
  videoId: yup.string().required('Please enter the videoId'),
  bucket: yup.string().required('Please select a bucket'),
  brand: yup.string().required('Please select a brand'),
});

const DEBUG = false;

export const VideoUploader = () => {
  const api = usePvepApi();
  const fileInput: any = useRef<HTMLInputElement>();
  const [uploadProgress, setUploadProgress] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState('');
  const [uploadError, setUploadError] = useState('');

  const brandsInfo = BrandUtil.getAllBrands();

  const bucketOptions = [
    {
      value: 'rekognition-video-alibitech',
      label: 'rekognition-video-alibitech',
    },
  ];
  const brandOptions = brandsInfo.map(brand => {
    return {
      label: brand.brandId,
      value: brand.brandId,
    };
  });

  const handleUpload = async (values: any) => {
    const ext = values.file.name.split('.').pop();

    const urlResp = await api.getS3UploadUrl(values.bucket, `${values.brand}/${values.videoId}.${ext}`, values.file.type);
    if (urlResp.data?.getS3UploadUrl.success) {
      //using XHR instead of fetch as fetch has no progress update functionality
      const xhr = new XMLHttpRequest();
      xhr.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
        if (e.lengthComputable) {
          const percentComplete = Math.round((e.loaded / e.total) * 100);
          const totalMB = e.total / Math.pow(10, 6);
          const loadedMB = e.loaded / Math.pow(10, 6);
          const loadedMessage = loadedMB > 1000 ? `${(loadedMB / 1000).toFixed(2)}GB` : `${loadedMB.toFixed(1)}MB`;
          const totalMessage = totalMB > 1000 ? `${(totalMB / 1000).toFixed(2)}GB` : `${totalMB.toFixed(1)}MB`;

          const message = `${percentComplete}%, ${loadedMessage} of ${totalMessage}`;
          setUploadProgress(message);
        }
      };
      xhr.onreadystatechange = function onReady() {
        // eslint-disable-next-line react/no-this-in-sfc
        if (this.readyState === XMLHttpRequest.DONE) {
          setUploadProgress('');
          // eslint-disable-next-line react/no-this-in-sfc
          if (this.status === 200) {
            setUploadSuccess('Upload succeeded');
            setUploadError('');
          } else {
            setUploadSuccess('');
            // eslint-disable-next-line react/no-this-in-sfc
            setUploadError(`Upload failed, AWS returned ${this.status}: ${this.statusText}`);
          }
        }
      };

      xhr.open('PUT', urlResp.data?.getS3UploadUrl.url, true);
      xhr.setRequestHeader('Content-Type', values.file.type);
      xhr.send(values.file);
    } else {
      setUploadError(`Unable to get presigned url from AWS, returned ${JSON.stringify(urlResp.data?.getS3UploadUrl.errorMessages)}`);
    }
  };

  return (
    <div className={flexContainer}>
      <Formik
        initialValues={{
          file: '',
          videoId: '',
          bucket: '',
          brand: '',
        }}
        validationSchema={uploadFileValidation}
        onSubmit={(values, { resetForm }) => {
          setUploadError('');
          setUploadSuccess('');
          handleUpload(values);
          resetForm();
          fileInput.current.value = '';
        }}
      >
        {props => (
          <>
            <div className={adminTitle}>Video Uploader</div>
            <div className={classNames(flexRow, recognitionFieldRow)}>
              <label className={classNames(spacer, flexRow, recognitionField)}>
                <div className={adminText}>Bucket:</div>
                <Select
                  value={bucketOptions.filter(opt => opt.value === props.values.bucket)}
                  name={`bucket`}
                  onChange={opt => {
                    props.setFieldValue(`bucket`, opt?.value);
                  }}
                  isClearable
                  options={bucketOptions}
                  className={defaultSelectControl}
                  classNamePrefix="productSelect"
                />
              </label>
              <label className={classNames(spacer, flexRow, recognitionField)}>
                <div className={adminText}>Brand:</div>
                <Select
                  value={brandOptions.filter(opt => opt.value === props.values.brand)}
                  name={`brand`}
                  onChange={opt => {
                    props.setFieldValue(`brand`, opt?.value);
                  }}
                  isClearable
                  options={brandOptions}
                  className={defaultSelectControl}
                  classNamePrefix="productSelect"
                />
              </label>
            </div>

            <div className={classNames(flexRow, recognitionFieldRow)}>
              <label className={classNames(spacer, flexRow, recognitionField)}>
                <div className={adminText}>Video ID:</div>
                <Field type="text" name="videoId" value={props.values.videoId} className={textField} />
              </label>
              <div className={classNames(spacer, flexRow, recognitionField)}>
                <label className={fileUploaderCustom} htmlFor="file">
                  Choose File
                </label>
                <div className={fileUploaderText}>
                  {/* @ts-ignore */}
                  {props.values.file?.name || 'No file chosen'}
                </div>

                <input
                  ref={fileInput}
                  id="file"
                  name="file"
                  type="file"
                  accept="video/*"
                  onChange={event => {
                    if (event.currentTarget.files) {
                      props.setFieldValue('file', event.currentTarget.files[0]);
                    }
                  }}
                  onBlur={props.handleBlur}
                  className={fileUploaderStyle}
                />
              </div>
            </div>
            <Button className={spacer} type="submit" onClick={props.handleSubmit}>
              SUBMIT
            </Button>
            {props.errors.bucket && props.touched.bucket && <div className={spacer}>{props.errors.bucket}</div>}
            {props.errors.videoId && props.touched.videoId && <div className={spacer}>{props.errors.videoId}</div>}
            {props.errors.file && props.touched.file && <div className={spacer}>{props.errors.file}</div>}
          </>
        )}
      </Formik>
      {uploadError && <div className={spacer}>{uploadError}</div>}
      {uploadSuccess && <div className={spacer}>{uploadSuccess}</div>}
      {uploadProgress && <div className={spacer}>{`Uploading.. ${uploadProgress}`}</div>}
    </div>
  );
};
