import * as yup from 'yup';
import { Link } from 'gatsby';
import { Formik } from 'formik';
import classNames from 'classnames';
import React, { useContext, useState, useRef } from 'react';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { usePvepApi } from '@apiClient/usePvepApi';
import IndexLayout from '@src/layouts';
import { Button } from '@components/Buttons';
import { FacebookLoginButton, GoogleLoginButton } from '@components/Buttons/LoginButton';
import { TextField, FormContainer } from '@components/Forms';
import Background from '@src/components/Background';
import { AlreadyMemberLogIn } from '@src/pageComponents/SignUp/AlreadyMemberLogIn';
import { useCookies } from 'react-cookie';
import { BrandUtil } from '@sharedLib/util';
import { textError, formHeader, loginForm, link, loginButtonWrapper, formHeaderATExtra } from './styles.module.scss';
import { useLoginResource } from './useLoginResources';

interface LoginFormErrors {
  emptyEmailField: string;
  invalidEmailError: string;
  emptyPasswordField: string;
  minLimitPasswordField: string;
}

const validationSchema = (error: LoginFormErrors) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(error.invalidEmailError)
      .required(error.emptyEmailField),
    password: yup
      .string()
      .required(error.emptyPasswordField)
      .min(8, error.minLimitPasswordField),
  });
};

interface FormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

const initialValues: FormData = {
  email: '',
  password: '',
  rememberMe: false,
};

const DEBUG = false;

export const Login: React.FC<any> = () => {
  const api = usePvepApi();
  const [cookies, , removeCookie] = useCookies(['hideVerifyEmailPopup']);
  const { brandId, enableFacebookLogin } = BrandUtil.getBrandInfoFromBranch();
  const content = useLoginResource();

  const nav = useContext(NavStateContext);
  const { pageData } = nav.navState;
  const destinationUrl: string = pageData.length > 0 && pageData[pageData.length - 1].state?.destination;
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageRedirection, setErrorMessageRedirection] = useState('');
  const loginRef = useRef<HTMLButtonElement>(null);
  DEBUG && ClientLogger.debug('Login', 'render', { destinationUrl, pageData });

  const handleEnterKey = (event: any) => {
    if (loginRef && event.key === 'Enter') {
      // @ts-ignore
      loginRef.current.click();
    }
  };

  const onSuccessProviderLogin = (response: any, provider: 'FACEBOOK' | 'GOOGLE') => {
    DEBUG && ClientLogger.debug('FacebookGoogleSignUp', 'onSuccessProviderLogin', { response, provider });
    api
      .loginWithProvider(response, provider)
      .then(result => {
        if (result.data) {
          if (result.data.exchangeProviderCodeForAuth.success) {
            const isSignup = result.data.exchangeProviderCodeForAuth.isSignup || false;

            // remove existing hide-popup cookie here, will show popup on login
            if (cookies.hideVerifyEmailPopup) {
              removeCookie('hideVerifyEmailPopup', { path: '/' });
            }

            if (isSignup) {
              nav.setRoot({ path: '/subscribe', title: 'Subscribe' });
            } else if (destinationUrl) {
              nav.setRoot({ path: destinationUrl });
            } else {
              nav.setRoot({ path: '/app', title: 'Home' });
            }
          } else {
            setErrorMessage(
              result.data.exchangeProviderCodeForAuth.errorMessages
                ? result.data.exchangeProviderCodeForAuth.errorMessages.map(e => e.errorMessage).join(', ')
                : 'unknown error'
            );
          }
        } else {
          setErrorMessage(result.errors ? result.errors.map(e => e.message).join(', ') : 'unknown error');
        }
      })
      .catch(e => {
        ClientLogger.error('FacebookGoogleSignUp', `error caught   ${ClientLogger.errorToText(e)}`);
        setErrorMessage(JSON.stringify(e));
      });
  };

  const onFailLoginProvider = (error: any) => {
    ClientLogger.error('FacebookGoogleSignUp', `onFailLoginProvider ${ClientLogger.errorToText(error)}`);
    setErrorMessage('Something went wrong. Please try again or ');
    setErrorMessageRedirection('Login');
  };

  return (
    <IndexLayout landing hideFreeTrial hideLogin noVideo>
      <Background />
      <Formik
        onSubmit={async (values: FormData, { setSubmitting }) => {
          setSubmitting(true);
          DEBUG && ClientLogger.debug('Login.onSubmit', '', values);
          const resp = await api.login(values.email, values.password);
          DEBUG && ClientLogger.debug('Login.Resp', JSON.stringify(resp));
          if (resp.data?.login.commonResponse.success) {
            // remove existing hide-popup cookie here, will show popup on login
            if (cookies.hideVerifyEmailPopup) {
              removeCookie('hideVerifyEmailPopup', { path: '/' });
            }

            if (destinationUrl) {
              nav.setRoot({ path: destinationUrl });
            } else {
              nav.setRoot({ path: '/app', title: 'Home' });
            }
          } else {
            setErrorMessage(`Wrong email or password. Please try again!`);
          }
        }}
        initialValues={initialValues}
        validationSchema={validationSchema(content.errors)}
      >
        {props => {
          ClientLogger.debug('Login', 'render', DEBUG, props);
          return (
            <FormContainer
              dataCY="loginForm"
              className={loginForm}
              errorMessage={
                errorMessage && (
                  <div className={textError}>
                    {errorMessage}
                    {errorMessageRedirection !== '' && <span>{errorMessageRedirection}</span>}
                  </div>
                )
              }
            >
              <h1 className={classNames(formHeader, brandId === 'at' ? formHeaderATExtra : '')}>{content.header}</h1>
              <TextField dataCY="email" handleenter={handleEnterKey} headerText="Email" fieldType="email" fieldName="email" />
              <TextField dataCY="password" handleenter={handleEnterKey} headerText="Password" fieldType="password" fieldName="password" />
              <Link data-cy="forgotPassword" className={link} to="/password-recovery-email">
                <b>{content.forgotPassword}</b>
              </Link>
              <FormContainer onSubmit={props.handleSubmit} className={loginButtonWrapper}>
                <Button dataCY="submit" onClick={props.handleSubmit} disabled={!(props.isValid && props.dirty)} refs={loginRef}>
                  {content.loginPageCTA.emailLogin}
                </Button>
                <GoogleLoginButton
                  onSuccess={resp => {
                    onSuccessProviderLogin(resp, 'GOOGLE');
                  }}
                  onFailure={onFailLoginProvider}
                  text={content.loginPageCTA.googleLogin}
                />
                {enableFacebookLogin && (
                  <FacebookLoginButton
                    callback={resp => {
                      onSuccessProviderLogin(resp, 'FACEBOOK');
                    }}
                    onFailure={onFailLoginProvider}
                    text={content.loginPageCTA.facebookLogin}
                  />
                )}
                <AlreadyMemberLogIn content={content.member.message} link="/sign-up" linkText={content.member.signUpLink} />
              </FormContainer>
            </FormContainer>
          );
        }}
      </Formik>
    </IndexLayout>
  );
};
