// extracted by mini-css-extract-plugin
export var logo = "styles-module--logo--2jE-v";
export var landingGreeting = "styles-module--landingGreeting--3z7R7";
export var option = "styles-module--option--omi95";
export var actionItems = "styles-module--actionItems--3uzco";
export var productTextContainer = "styles-module--productTextContainer--1W6Hi";
export var formFix = "styles-module--formFix--18l-i";
export var formFixCompact = "styles-module--formFixCompact--XzoOX";
export var embeddedVideo = "styles-module--embeddedVideo--28T5q";
export var product = "styles-module--product--3lbvF";
export var productContainer = "styles-module--productContainer--1ydZh";
export var imgShare = "styles-module--imgShare--PYuLy";
export var imgThumbnail = "styles-module--imgThumbnail--1C0V4";
export var imgFull = "styles-module--imgFull--3QiWo";
export var productCardContainer = "styles-module--productCardContainer--UIJnB";
export var productTitle = "styles-module--productTitle--2yunC";
export var productCard = "styles-module--productCard--3We1W";
export var productCardCompact = "styles-module--productCardCompact--1B5gP";
export var productImageWrapper = "styles-module--productImageWrapper--3cqLQ";
export var productImage = "styles-module--productImage--1rnPu";
export var productContent = "styles-module--productContent--3Uw5O";
export var productMessage = "styles-module--productMessage--1Q_rk";
export var productPrice = "styles-module--productPrice--tIyTM";
export var productDescription = "styles-module--productDescription--1h0FQ";
export var productDescriptionFull = "styles-module--productDescriptionFull--goayB";
export var buyNow = "styles-module--buyNow--3btbw";
export var addButton = "styles-module--addButton--2MELt";
export var selectorContainer = "styles-module--selectorContainer--3QJQd";
export var label = "styles-module--label--3OqdY";
export var variantSelector = "styles-module--variantSelector--3Htwu";
export var iconAffliate = "styles-module--iconAffliate--Gg5qx";
export var floatRight = "styles-module--floatRight--3EJ4B";
export var mediaObject = "styles-module--mediaObject--3xXOg";
export var mediaObjectCompact = "styles-module--mediaObjectCompact--VtWKC";
export var applyFlexMobile = "styles-module--applyFlexMobile--1u8xL";
export var backButton = "styles-module--backButton--2F41G";
export var cartProductDetails = "styles-module--cartProductDetails--2cz30";
export var deleteIconHeight = "styles-module--deleteIconHeight--100rG";
export var link = "styles-module--link--nKC2V";
export var iconDelete = "styles-module--iconDelete--3cnTI";
export var removeButton = "styles-module--removeButton--Rvcne";
export var shareFill = "styles-module--shareFill--vAsEy";
export var productFill = "styles-module--productFill--1VL5c";
export var productFormContainer = "styles-module--productFormContainer--1Vaxq";
export var productFormContainerCompact = "styles-module--productFormContainerCompact--3fNWv";
export var maxWidth = "styles-module--maxWidth--14y7R";
export var minHeightAuto = "styles-module--minHeightAuto--1BQHa";
export var descriptionRevealButton = "styles-module--descriptionRevealButton--3-Wep";
export var revealButton = "styles-module--revealButton--22XpM";