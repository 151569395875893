/* eslint-disable camelcase */
import { Coupon } from '@apiClient/gql-types';
import React, { useEffect, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import * as yup from 'yup';
import { TextField } from '@components/Forms';
import { Formik, FormikHelpers } from 'formik';
import { Button } from '@components/Buttons';
import {
  gridRightDecorator,
  gridBottomDecorator,
  tableCell,
  gridTable,
  couponText,
  deleteCouponButton,
  adminTitle,
  flexContainer,
  flexRow,
  spacer,
  adminPadBottom,
  borderInput,
  adminText,
  couponSelect,
} from './styles.module.scss';
import classNames from 'classnames';
import Select from 'react-select';

const codeValidation = yup.object().shape({
  code: yup.string().required('Please enter a code'),
});

const DEBUG = false;

export const CouponCodes = () => {
  const api = usePvepApi();
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const couponOptions = coupons.map(coupon => {
    return {
      value: coupon.code,
      label: coupon.code,
    };
  });

  const updateCodeList = () =>
    api.getCouponCodes().then(resp => {
      if (resp && resp.data) {
        setCoupons(resp.data.coupons);
      }
    });

  useEffect(() => {
    updateCodeList();
  }, []);

  const deleteCode = async (code: string) => {
    await api.deleteOneCoupon(code);
    await updateCodeList();
  };

  const handleCouponSubmit = (code: string, actions: FormikHelpers<{ code: string }>) => {
    setTimeout(() => {
      api
        .createCouponCode(code)
        .then(() => {
          actions.resetForm();
          updateCodeList();
        })
        .catch(error => {
          alert('Sorry, something went wrong with your submission');
          console.log(error);
        });
    });
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 1000);
  };

  return (
    <div>
      <div className={flexContainer}>
        <div className={adminTitle}>Coupon Code</div>
        <div className={adminPadBottom}>
          Coupon codes are used to gate your site. New users will be required to enter these correctly before proceeding.
        </div>

        <Formik
          initialValues={{
            code: '',
          }}
          validationSchema={codeValidation}
          onSubmit={(values, actions) => handleCouponSubmit(values.code, actions)}
        >
          {props => (
            <>
              <div className={flexRow}>
                <TextField fieldType="code" fieldName="code" className={classNames(borderInput, spacer)} placeholder="Type to add a code" />
                <Button
                  className={spacer}
                  disabled={props.isSubmitting || Object.entries(props.errors).length !== 0}
                  type="submit"
                  onClick={props.handleSubmit}
                >
                  SUBMIT
                </Button>
              </div>

              {props.status && props.status.msg && <div>{props.status.msg}</div>}
            </>
          )}
        </Formik>

        <div className={classNames(adminText, spacer)}>Active Codes</div>
        <Select
          value={couponOptions.filter(coupon => coupon.value)}
          onChange={opts => {
            // if a code was just removed then delete it
            if (opts.length < coupons.length) {
              const toDelete = coupons.filter(coupon => opts.findIndex(opt => opt.value === coupon.code) === -1);

              toDelete.map(item => {
                deleteCode(item.code);
              });
            }
          }}
          isMulti
          isClearable={false}
          options={couponOptions}
          className={couponSelect}
          classNamePrefix="productSelect"
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Menu: () => null }}
          isSearchable={false}
        />
      </div>
    </div>
  );
};
