// extracted by mini-css-extract-plugin
export var cardContents = "styles-module--cardContents--1gwWc";
export var cardContentsLarge = "styles-module--cardContentsLarge--28M3y";
export var title = "styles-module--title--y_oCB";
export var productDetailsWrapper = "styles-module--productDetailsWrapper--3rFTj";
export var productDetails = "styles-module--productDetails--rnDgl";
export var productPrice = "styles-module--productPrice--2flCX";
export var image = "styles-module--image--RAtN0";
export var imageLarge = "styles-module--imageLarge--123_1";
export var buttonFreeTrial = "styles-module--buttonFreeTrial--mJ4KV";
export var buttonWatchStream = "styles-module--buttonWatchStream--19kiI";
export var detailsAndButton = "styles-module--detailsAndButton--2PRA2";
export var detailsAndButtonLarge = "styles-module--detailsAndButtonLarge--1nuK0";
export var details = "styles-module--details--3CNsi";