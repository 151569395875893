/* eslint-disable camelcase */
import { User, ServerInfo } from '@apiClient/gql-types';
import { Button } from '@components/Buttons';
import { ClientLogger } from '@lib/ClientLogger';
import { BrandUtil } from '@sharedLib/util';
import React, { useEffect, useState } from 'react';
import IndexLayout from '@layouts/index';
import { serverURL, usePvepApi, tokenExpiryReadable } from '@apiClient/usePvepApi';

const siteMetadata = BrandUtil.getSiteInfo();

const DEBUG = true; // Leave on please.  Auth system auto navigates to login on me call obscuring output.

const InfoPage: React.FC = () => {
  const [serverInfoData, setServerInfoData] = useState<ServerInfo>({} as ServerInfo);
  const [networkStatus, setNetworkStatus] = useState('');
  const [me, setMe] = useState<User | null>(null);
  const api = usePvepApi();
  ClientLogger.debug('InfoPage', 'render', DEBUG, {
    GATSBY_BUILD_DATE: process.env.GATSBY_BUILD_DATE,
    GATSBY_GIT_BRANCH: process.env.GATSBY_GIT_BRANCH,
    GATSBY_BRAND_ID: process.env.GATSBY_BRAND_ID,
    GATSBY_FB_ID: process.env.GATSBY_FB_ID,
    GATSBY_GOOGLE_ID: process.env.GATSBY_GOOGLE_ID,
    GATSBY_NODE_ENV: process.env.GATSBY_NODE_ENV,
    GATSBY_ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID,
    siteMetadata,
  });
  useEffect(() => {
    ClientLogger.debug('InfoPage.useEffect', 'calling getServerInfo', DEBUG);
    api
      .getServerInfo()
      .then(result => {
        ClientLogger.debug('InfoPage.useEffect', 'resolved', DEBUG, { result });
        if (result && result.data) {
          setServerInfoData(result.data.serverInfo || {});
          if (result.data.serverInfo) {
            setNetworkStatus('OK');
          } else {
            setNetworkStatus(JSON.stringify(result.errors));
          }
        }
      })
      .catch(e => {
        ClientLogger.error('InfoPage.useEffect', 'Error calling Server', e);
      });
    if (api.isLoggedIn()) {
      api
        .getMe()
        .then(me => {
          ClientLogger.debug('InfoPage.useEffect', 'resolved me', DEBUG, { me });
          setMe(me.data?.me || null);
        })
        .catch(e => {
          ClientLogger.error('InfoPage.useEffect', 'Error calling getMe', e);
        });
    }
  }, []);

  const userState = api.state;

  return (
    <IndexLayout noVideo>
      <div className="is-size-2">Environment</div>
      <table className="table is-bordered is-striped is-narrow">
        <thead>
          <tr>
            <td>Setting</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Network Status</td>
            <td>{networkStatus}</td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>{serverInfoData.branch}</td>
          </tr>
          <tr>
            <td>Client Build Date</td>
            <td>{process.env.GATSBY_BUILD_DATE}</td>
          </tr>
          <tr>
            <td>Build Id</td>
            <td>{serverInfoData.buildId}</td>
          </tr>
          <tr>
            <td>Commit</td>
            <td>{serverInfoData.commit}</td>
          </tr>
          <tr>
            <td>Server Build Date</td>
            <td>{serverInfoData.serverBuildDate}</td>
          </tr>
          <tr>
            <td>Environment</td>
            <td>{serverInfoData.environment}</td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>{serverInfoData.branch}</td>
          </tr>
          <tr>
            <td>Branch Type</td>
            <td>{serverInfoData.branchType}</td>
          </tr>
          <tr>
            <td>DB Connect Test</td>
            <td>{serverInfoData.dbConnectTest}</td>
          </tr>
          <tr>
            <td>Last Digits of AWS Key Id</td>
            <td>{serverInfoData.awsAccessKeyId}</td>
          </tr>
          <tr>
            <td>Is Logged In (Server)</td>
            <td>{serverInfoData.isLoggedIn ? 'true' : 'false'}</td>
          </tr>
          <tr>
            <td>Is Admin (Server)</td>
            <td>{serverInfoData.isAdmin ? 'true' : 'false'}</td>
          </tr>
          <tr>
            <td>Email (User State)</td>
            <td>{userState.email}</td>
          </tr>
          <tr>
            <td>Is Logged In (User State)</td>
            <td>{userState.isLoggedIn ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Is Admin (User State)</td>
            <td>{userState.isAdmin ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Me Object</td>
            <td>{JSON.stringify(me, null, 4)}</td>
          </tr>
          <tr>
            <td>JWT Expiry</td>
            <td>{tokenExpiryReadable(userState)}</td>
          </tr>
          <tr>
            <td>JWT (User State)</td>
            <td>{JSON.stringify(userState.jwt, null, 4)}</td>
          </tr>
          <tr>
            <td>token</td>
            <td style={{ wordWrap: 'break-word', maxWidth: '250px' }}>"authorization": "Bearer {userState.token}"</td>
          </tr>
          <tr>
            <td>Site Metadata</td>
            <td>{JSON.stringify(siteMetadata, null, 4)}</td>
          </tr>
          <tr>
            <td>GATSBY_GIT_BRANCH</td>
            <td>{process.env.GATSBY_GIT_BRANCH}</td>
          </tr>
          <tr>
            <td>GATSBY_BRAND_ID</td>
            <td>{process.env.GATSBY_BRAND_ID}</td>
          </tr>
          <tr>
            <td>GATSBY_FB_ID</td>
            <td>{process.env.GATSBY_FB_ID}</td>
          </tr>
          <tr>
            <td>GATSBY_GOOGLE_ID</td>
            <td>{process.env.GATSBY_GOOGLE_ID}</td>
          </tr>
          <tr>
            <td>GATSBY_NODE_ENV</td>
            <td>{process.env.GATSBY_NODE_ENV}</td>
          </tr>
          <tr>
            <td>GATSBY_ALGOLIA_APP_ID</td>
            <td>{process.env.GATSBY_ALGOLIA_APP_ID}</td>
          </tr>
          <tr>
            <td>serverURL</td>
            <td>{serverURL}</td>
          </tr>
          <tr>
            <td>Test Console Messages</td>
            <td>
              <Button
                onClick={() => {
                  api
                    .consoleTest()
                    .then(r => {
                      ClientLogger.debug('Test Console Messages', 'Success', r);
                    })
                    .catch(e => {
                      ClientLogger.error('Test Console Messages', 'Success', e);
                    });
                }}
              >
                Generate Test Console Messages
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </IndexLayout>
  );
};

export default InfoPage;
