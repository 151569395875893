/* eslint-disable camelcase */
import Page from '@components/Page';
import { ServerInfo } from '@apiClient/gql-types';
import IndexLayout from '@src/layouts';
import React, { useEffect, useState } from 'react';
import { Table } from '@src/pageComponents/Table/Table';
import { brands, BrandUtil } from '@sharedLib/util';
import { ClientLogger } from '@lib/ClientLogger';

const GitTable = () => {
  const [serverInfo, setServerInfo] = useState<ServerInfo[]>();
  const [rows, setRows] = useState<any>([]);
  const branchTypes = ['develop', 'staging', 'master'];

  const fetchBranch = async (url = '') => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: '{"query": "{ serverInfo { serverBuildDate, buildId, commit } }"}',
      });
      return response.json() || {};
    } catch {
      return Promise.resolve({ data: {} });
    }
  };

  const getRowsSetColumns = async (): Promise<void> => {
    try {
      const rowsGet: Array<string | any> = [];
      const fetchArray = [];
      for (const branch of branchTypes) {
        for (const brand in brands) {
          const siteInfo = BrandUtil.getSiteInfo(brand, brand === 'at' ? branch : `${brand}-${branch}`);
          const { targetDomain, serverPath, siteUrl } = siteInfo;
          fetchArray.push(fetchBranch('https://' + targetDomain + '/' + serverPath));
          rowsGet.push({ title: `${brand}-${branch}`, url: siteUrl + '/info' });
        }
      }
      const columns = await Promise.all(fetchArray);
      const servInfo: any = columns.map(({ data }) => {
        if (data.serverInfo) {
          const serverBuildDate = new Date(data.serverInfo?.serverBuildDate).toString() || 'N/A';
          return { ...data.serverInfo, serverBuildDate };
        } else {
          return {};
        }
      });

      setServerInfo(servInfo);
      setRows(rowsGet);
    } catch (err) {
      ClientLogger.error('Git Table Error', 'Error caught', err);
    }
  };

  useEffect(() => {
    getRowsSetColumns();
  }, []);

  return (
    <IndexLayout noVideo>
      <Page>
        <h1>Git Lab Commit Information</h1>
        {serverInfo && <Table rows={rows} columns={serverInfo || []} />}
      </Page>
    </IndexLayout>
  );
};

export default GitTable;
