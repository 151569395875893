import { AuthClientUtil } from '@lib/AuthClientUtil';
import { useVideoState, VideoPlayerSize } from '@lib/useVideoState';
import React, { useEffect } from 'react';
import IndexLayout from '@layouts/index';

const LogOutPage = () => {
  const videoState = useVideoState();
  useEffect(() => {
    videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
    videoState.pause();
    AuthClientUtil.logout('/');
  }, []);
  return <IndexLayout hideHeader hideFooter noVideo />;
};

export default LogOutPage;
