import React from 'react';
import { Link } from 'gatsby';
import * as yup from 'yup';
import classNames from 'classnames';
import { Formik } from 'formik';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import IndexLayout from '@src/layouts';
import { FormikContainer, TextField } from '@src/components/Forms';
import { Button } from '@src/components/Buttons';
import Background from '@src/components/Background';
import {
  subHeaderTextWrapper,
  forgotPasswordForm,
  formHeader,
  formText,
  formTextSmall,
  link,
  narrowButton,
  info,
} from './styles.module.scss';

interface PasswordRecoveryEmailErrors {
  emptyEmailField: string;
  invalidEmailError: string;
}

const errors = {
  emptyEmailField: 'Email address is required',
  invalidEmailError: 'Valid email address is required (ie. example@email.com)',
};

const validationSchema = (error: PasswordRecoveryEmailErrors) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(error.invalidEmailError)
      .required(error.emptyEmailField),
  });
};

interface FormData {
  email: string;
}

const initialValues: FormData = {
  email: '',
};

const DEBUG = false;
const EmailForm = ({ setSuccessState }: any) => {
  const api = usePvepApi();

  return (
    <IndexLayout hideFreeTrial hideLogin>
      <Background />
      <Formik
        onSubmit={async (values: FormData, { setSubmitting }) => {
          setSubmitting(true);
          await api.forgotPassword(values.email);
          setSuccessState(true);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema(errors)}
        render={props => {
          ClientLogger.debug('Login', 'render', DEBUG, props);
          return (
            <FormikContainer className={forgotPasswordForm}>
              <h1 className={classNames('standard-form__header', formHeader)}>Forgot Your Password?</h1>
              <h1 className={classNames('standard-form__header', formText)}>No Problem!</h1>
              <h1 className={classNames('standard-form__header', formText)}>Just enter the email address used for the account</h1>
              <h1 className={classNames('standard-form__header', formText)}>and we'll send you a reset link.</h1>
              <div className={classNames(subHeaderTextWrapper)}>
                <TextField dataCY="resetPasswordEmail" headerText="Email" fieldType="email" fieldName="email" className={info} />
              </div>
              <Button dataCY="resetPasswordLink" type="submit" disabled={!props.isValid} className={narrowButton}>
                Request Reset Link
              </Button>

              <div className={classNames('standard-form__header', formTextSmall)}>
                Back to
                <Link className={link} to="/login">
                  <b>Log in</b>
                </Link>
              </div>
            </FormikContainer>
          );
        }}
      />
    </IndexLayout>
  );
};

export default EmailForm;
