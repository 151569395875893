import { usePageImage } from '@lib/usePageImage';
import { IPageProps } from '@src/cms/cms-types';
import { IFrontMatterNotFound } from '@templates/not-found/model';
import React from 'react';
import { isDesktop, isTablet, isMobile } from '@src/lib/responsive';
import IndexLayout from '@src/layouts';
import { Img } from '@components/Img';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Link } from 'gatsby';
import { pageContainer, link, image as imageStyle, info, header, description } from './styles.module.scss';

const NotFound = (props: IPageProps<IFrontMatterNotFound>) => {
  const useDesktop = isDesktop();
  const useTablet = isTablet();
  const useMobile = isMobile();
  const api = usePvepApi();
  const isLoggedIn = api.isLoggedIn();
  const image = usePageImage(props.pageContext.frontMatter.image);
  const content = props.pageContext.frontMatter;

  const renderDesktop = () => {
    return (
      <div className={pageContainer}>
        <div className={info}>
          <div className={header}>{content.header}</div>
          <div className={description}>
            {content.description}
            <Link to={isLoggedIn ? '/app' : '/'} className={link}>
              &nbsp;
              {content.cta}
            </Link>
          </div>
        </div>
        <div>
          <Img childImageSharp={image.childImageSharp} className={imageStyle} />
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className={pageContainer}>
        <div>
          <Img childImageSharp={image.childImageSharp} className={imageStyle} />
        </div>
        <div className={info}>
          <div className={header}>{content.header}</div>
          <div className={description}>
            {content.description}
            <Link to={isLoggedIn ? '/app' : '/'} className={link}>
              &nbsp;
              {content.cta}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <IndexLayout noVideo>
      {(useDesktop || useTablet) && renderDesktop()}
      {useMobile && renderMobile()}
    </IndexLayout>
  );
};

export default NotFound;
