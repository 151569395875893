import React, { useContext, useEffect, useState } from 'react';
import IndexLayout from '@src/layouts';
import { BrandUtil } from '@sharedLib/util/Brand';
import Page from '@components/Page';
import queryString from 'query-string';
import algoliasearch from 'algoliasearch';
import {
  searchPageContainer,
  searchBar,
  searchIconContainer,
  searchIconStyle,
  searchTitle,
  resultGrid,
  episodeContainer,
  videoImageContainer,
  episodeTitle,
  productResult,
  productImageContainer,
  productImage,
  productTitle,
  videoImage,
  searchInput,
} from './styles.module.scss';
import { ProductImg, VideoImg } from '@components/Img';
import { formatCurrency } from '@lib/adPlacements';
import { NavStateContext } from '@src/lib/navContext';
import searchIcon from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { ClientLogger } from '@src/lib/ClientLogger';

const DEBUG = false;

export const SearchPage: React.FC = () => {
  const nav = useContext(NavStateContext);
  const brandInfo = BrandUtil.getSiteInfo();
  const searchOutsideLogin = !brandInfo.hasSignup || !brandInfo.videoNeedsRegistration || !brandInfo.productNeedsRegistration;
  const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID || '', process.env.GATSBY_ALGOLIA_SEARCH_KEY || '');
  const [episodeHits, setEpisodeHits] = useState([]);
  const [productHits, setProductHits] = useState([]);
  const [numberOfEpisodeHits, setNumberOfEpisodeHits] = useState(0);
  const [numberOfProductHits, setNumberOfProductHits] = useState(0);
  const [query, setQuery] = useState('');

  const queries = [
    {
      indexName: brandInfo.episodesSearchIndexName,
      query,
      params: { typoTolerance: false },
    },
    {
      indexName: brandInfo.productsSearchIndexName,
      query,
      params: { typoTolerance: false },
    },
  ];

  useEffect(() => {
    const queryUrl = queryString.parse(location.search).q as string;
    DEBUG && ClientLogger.debug('SearchPage', `initial query=${queryUrl}`, DEBUG);
    setQuery(queryUrl);
  }, []);

  useEffect(() => {
    if (query) {
      DEBUG && ClientLogger.debug('SearchPage', `new search with query=${query}`, DEBUG);
      searchClient.multipleQueries(queries).then(({ results }: any) => {
        setEpisodeHits(results[0].hits);
        setNumberOfEpisodeHits(results[0].nbHits);
        setProductHits(results[1].hits);
        setNumberOfProductHits(results[1].nbHits);
      });
    }
  }, [query]);

  return (
    <IndexLayout needsLogin={!searchOutsideLogin} noVideo>
      <Page>
        <div className={searchPageContainer}>
          <div className={searchBar}>
            <div className={searchIconContainer}>
              <Icon icon={searchIcon} className={searchIconStyle} />
            </div>
            <input value={query || ''} onChange={e => setQuery(e.target.value)} className={searchInput} />
          </div>

          <div>
            <div className={searchTitle}>Shows - {numberOfEpisodeHits} found</div>
            <div className={resultGrid}>
              {numberOfEpisodeHits > 0 &&
                episodeHits.map((episode: any, i) => {
                  const baseImageUrl = brandInfo.imageBaseUrl;
                  const thumbnailUrl = episode.node.thumbnailUrl
                    ? `${baseImageUrl}/videos/${episode.node.thumbnailUrl}`
                    : `${brandInfo.siteUrl}/fallback.png`;

                  return (
                    <div
                      className={episodeContainer}
                      key={i}
                      onClick={() => nav.push({ path: `/video/${episode.node.id}`, title: episode.node.title })}
                    >
                      <div className={videoImageContainer}>
                        <img className={videoImage} src={thumbnailUrl} alt="" />
                      </div>

                      <div className={episodeTitle}>{episode.node.title}</div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div>
            <div className={searchTitle}>Products - {numberOfProductHits} found</div>
            <div className={resultGrid}>
              {numberOfProductHits > 0 &&
                productHits.map((product: any, i) => {
                  const price = product.node.variants.length && product.node.variants[0].price;
                  return (
                    <div
                      className={productResult}
                      key={i}
                      onClick={() => nav.push({ path: `/product/${product.node.id}`, title: product.node.title })}
                    >
                      <div className={productImageContainer}>
                        <ProductImg product={product.node} className={productImage} imgStyle={{ objectFit: 'cover' }} />
                      </div>

                      <div className={productTitle}>{product.node.title}</div>

                      <div>{formatCurrency(price) || ''}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Page>
    </IndexLayout>
  );
};
