// extracted by mini-css-extract-plugin
export var logo = "styles-module--logo--Acl2l";
export var landingGreeting = "styles-module--landingGreeting--3GdP-";
export var box = "styles-module--box--7yPb_";
export var embedBox = "styles-module--embedBox--M9oV6";
export var videoPlaceholder = "styles-module--video-placeholder--2l7K6";
export var videoList = "styles-module--videoList--HY6K5";
export var videoListContainer = "styles-module--videoListContainer--18KZ8";
export var gap = "styles-module--gap--RlP0E";
export var featuredProductsCTA = "styles-module--featuredProductsCTA--3Mcev";
export var iconCTA = "styles-module--iconCTA--1oX86";
export var productsAndFooter = "styles-module--productsAndFooter--bo3Wp";
export var show = "styles-module--show--3g5CK";
export var hide = "styles-module--hide--CPcRp";
export var scrollButton = "styles-module--scrollButton--ElAfH";
export var left = "styles-module--left--2tMSE";
export var right = "styles-module--right--2vJsq";
export var shiftUpLeft = "styles-module--shiftUpLeft--Rd0xh";
export var shiftUpRight = "styles-module--shiftUpRight--2RZiP";
export var buttonIcon = "styles-module--buttonIcon--IvB4c";
export var seriesEmbeddedTitle = "styles-module--seriesEmbeddedTitle--1K0Db";
export var embeddedCartContainer = "styles-module--embeddedCartContainer--2ja2_";
export var closeCartButton = "styles-module--closeCartButton--n0LzU";