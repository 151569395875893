import React, { useContext, useEffect, useState } from 'react';
import { SubmitButton } from '@components/Buttons';
import { FormContainer } from '@components/Forms';
import { NavStateContext } from '@src/lib/navContext';
import IndexLayout from '@src/layouts';
import Background from '@src/components/Background';
import { usePvepApi } from '@apiClient/usePvepApi';
import queryString from 'query-string';
import { ClientLogger } from '@src/lib/ClientLogger';

import { verifyForm, verifyTitle, verifyText } from './styles.module.scss';

const DEBUG = false;

export const VerifyEmail = () => {
  const nav = useContext(NavStateContext);
  const api = usePvepApi();
  const userState = api.state;

  const [loading, setLoading] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [emailResent, setEmailResent] = useState(false);

  useEffect(() => {
    async function verifyOnMount() {
      const verifyId = queryString.parse(location.search).verifyId as string;

      if (verifyId) {
        DEBUG && ClientLogger.debug('VerifyEmail', `calling api with verifyId=${verifyId}`);

        const verifyResp = await api.verifyEmail(verifyId);

        if (verifyResp.data?.verifyEmail?.success) {
          setVerifySuccess(true);
          setLoading(false);

          // if logged in update JWT
          if (userState.isLoggedIn) {
            const updateTokenResp = await api.getJwtFromRefresh();
            if (!updateTokenResp) {
              ClientLogger.error('VerifyEmail', 'returned an error when renewing jwt', updateTokenResp);
              nav.push({ path: '/logout' });
            }
          }
        } else {
          ClientLogger.error('VerifyEmail', 'api call returned an error', verifyResp.data?.verifyEmail?.errorMessages);
          setVerifySuccess(false);
          setLoading(false);
        }
      } else {
        DEBUG && ClientLogger.debug('VerifyEmail', 'expected verifyId in querystring');
        setVerifySuccess(false);
        setLoading(false);
      }
    }

    verifyOnMount();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // verification successful, navigate away
    if (verifySuccess) {
      if (userState.isLoggedIn) {
        nav.setRoot({ path: '/app', title: 'Home' });
      } else {
        nav.setRoot({ path: '/login', title: 'Log In' });
      }

      // verification unsuccessful, resend email
    } else if (userState.isLoggedIn) {
      DEBUG && ClientLogger.debug('VerifyEmail', 'resending verification email', DEBUG);

      const resendEmailResp = await api.resendEmailVerification();
      if (resendEmailResp.data?.resendEmailVerification.success) {
        setEmailResent(true);
      } else {
        ClientLogger.error(
          'VerifyEmail',
          'resend email api call returned an error',
          resendEmailResp.data?.resendEmailVerification?.errorMessages
        );
      }
    } else {
      // log in first to send verification email -- requires email address
      nav.push({ path: '/login', title: 'Log In', state: { destination: '/verify' } });
    }
  };

  if (loading) {
    return (
      <IndexLayout noVideo>
        <Background />
        <FormContainer className={verifyForm}>
          <div>Verifying your email address...</div>
        </FormContainer>
      </IndexLayout>
    );
  }

  if (emailResent) {
    return (
      <IndexLayout noVideo>
        <Background />
        <FormContainer className={verifyForm}>
          <div className={verifyText}>Verification email sent successfully.</div>
          <div>Please check your inbox.</div>
        </FormContainer>
      </IndexLayout>
    );
  }

  return (
    <IndexLayout noVideo>
      <Background />
      <FormContainer onSubmit={e => handleSubmit(e)} className={verifyForm}>
        {verifySuccess ? (
          <>
            <div className={verifyTitle}>SUCCESS!</div>
            <div className={verifyText}>You're good at this computer stuff.</div>
            <div>Thank you for verifying your email address.</div>
            <SubmitButton>{userState.isLoggedIn ? 'HOME' : 'LOG IN'}</SubmitButton>
          </>
        ) : (
          <>
            <div className={verifyText}>Looks like something went wrong.</div>
            <div>
              {userState.isLoggedIn
                ? 'Click the button to re-send the verification email.'
                : 'Please log in to re-send the verification email.'}
            </div>
            <SubmitButton>{userState.isLoggedIn ? 'RE-SEND EMAIL' : 'LOG IN'}</SubmitButton>
          </>
        )}
      </FormContainer>
    </IndexLayout>
  );
};
