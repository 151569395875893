// extracted by mini-css-extract-plugin
export var featuredProductContainer = "styles-module--featuredProductContainer--2NrFU";
export var headerText = "styles-module--headerText--wuqBY";
export var productImage = "styles-module--productImage--6Qhn5";
export var productList = "styles-module--productList--1NVyo";
export var spaceForVideo = "styles-module--spaceForVideo--3HNc_";
export var productBox = "styles-module--productBox--2FnY0";
export var containerImage = "styles-module--containerImage--1SSY4";
export var containerInfo = "styles-module--containerInfo--2MKix";
export var productPrice = "styles-module--productPrice--14Vwo";
export var productName = "styles-module--productName--1rC67";
export var productBuyNow = "styles-module--productBuyNow--3k5EA";