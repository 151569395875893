import { usePvepApi } from '@apiClient/usePvepApi';
import React, { useContext, useState } from 'react';
import { NavStateContext } from '@src/lib/navContext';
import EmailForm from './EmailForm';
import SuccessMessage from './SuccessMessage';
import { hidden } from './styles.module.scss';

export const PasswordRecoveryEmail: React.FC<any> = () => {
  const api = usePvepApi();
  const [successState, setsuccessState] = useState<boolean>(false);
  const nav = useContext(NavStateContext);

  if (api.state.isLoggedIn) {
    nav.setRoot({ path: '/app', title: 'Home' });
  }

  return (
    <div>
      <div className={successState ? hidden : ''}>
        <EmailForm setSuccessState={setsuccessState} />
      </div>

      <div className={successState ? '' : hidden}>
        <SuccessMessage />
      </div>
    </div>
  );
};
