/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { isBrowser } from '@lib/build';
import VideoLayout from './src/layouts/VideoLayout';

import './src/styles/styles.scss';
import './src/styles/universal.scss';

import { useAnalyticsCapture } from './src/lib/AnalyticsCapture';
import { ClientLogger } from './src/lib/ClientLogger';
import { MeProvider } from './src/lib/meContext';
import { NavStateProvider } from './src/lib/navContext';
import { CartProvider } from './src/lib/clientStorage/cartContext';

const DEBUG = false;

export const onServiceWorkerUpdateReady = () => {
  if (isBrowser) {
    // const answer = window.confirm(`This application has been updated. ` + `Reload to display the latest version?`);

    // if (answer === true) {
    window.location.reload();
    // }
  }
};

const AnalyticsComponent = ({ location, pageContext, data }) => {
  const [prevLocation, setPrevLocation] = useState('');
  const analyticsCapture = useAnalyticsCapture();

  DEBUG && ClientLogger.debug('AnalyticsComponent', '', { location, pageContext, data });
  useEffect(() => {
    const shouldRecord = prevLocation !== location.href;
    DEBUG && ClientLogger.debug('AnalyticsComponent', 'useEffect', { shouldRecord, last: prevLocation, current: location.href });
    if (shouldRecord) {
      analyticsCapture.recordPage(location);
      setPrevLocation(location.href);
    }
  }, [location.href])
  return null;
};

export const wrapPageElement = ({ element, location, props }) => {
  // eslint-disable-next-line react/jsx-filename-extension
  return (
    <>
      <AnalyticsComponent location={location} {...props} />
      <VideoLayout {...props}>{element}</VideoLayout>
    </>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <CartProvider>
      <NavStateProvider>
        <MeProvider>{element}</MeProvider>
      </NavStateProvider>
    </CartProvider>
  );
};
