import React from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { FormContainer, TextField } from '@src/components/Forms';
import { Formik } from 'formik';
import classNames from 'classnames';
import { accountInfoForm } from './styles.module.scss';
import { infoWrapper, subHeaderText, info, tabContainer } from '../styles.module.scss';

const initialValues = {
  email: '',
};

interface FormData {
  email: string;
}

const Email = () => {
  const api = usePvepApi();
  const user = api.state;

  initialValues.email = user.email;
  return (
    <Formik
      onSubmit={async (_values: FormData, { setSubmitting }) => {
        setSubmitting(false);
      }}
      initialValues={initialValues}
    >
      {props => {
        return (
          <div className={classNames('column is-half', tabContainer)}>
            <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
              <div className={classNames(infoWrapper)}>
                <h1 className={subHeaderText}>Email</h1>
              </div>
              <TextField placeholder="Email" fieldType="email" fieldName="email" className={info} disabled />
            </FormContainer>
          </div>
        );
      }}
    </Formik>
  );
};

export default Email;
