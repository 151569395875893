// extracted by mini-css-extract-plugin
export var largeVideoCard = "styles-module--largeVideoCard--1gVTb";
export var smallVideoCard = "styles-module--smallVideoCard--1_rVk";
export var videoCard = "styles-module--videoCard--1BVKL";
export var videoThumbnail = "styles-module--videoThumbnail--ifydt";
export var videoOverlay = "styles-module--videoOverlay--309Mg";
export var videoActions = "styles-module--videoActions--2X3AG";
export var largeVideoTitle = "styles-module--largeVideoTitle--12-uD";
export var videoTitle = "styles-module--videoTitle--60XeP";
export var smallVideoTitle = "styles-module--smallVideoTitle--NwwM8";
export var largeVideoDescription = "styles-module--largeVideoDescription--3CMEA";
export var videoDescription = "styles-module--videoDescription--2hza6";
export var smallVideoDescription = "styles-module--smallVideoDescription--3Aoj0";
export var largeVideoButton = "styles-module--largeVideoButton--1JU62";
export var videoButton = "styles-module--videoButton--2lgdX";
export var smallVideoButton = "styles-module--smallVideoButton--dWMdg";
export var overlayIcon = "styles-module--overlayIcon--1lWEi";