import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterLogin } from '@src/pageComponents/Login/model';
import { graphql, useStaticQuery } from 'gatsby';

const DEBUG = false;

export function useLoginResource(): IFrontMatterLogin {
  try {
    // alternatively, we could import the md file
    const data = useStaticQuery(graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "login" } }) {
          id
          frontmatter {
            header
            loginPageCTA {
              emailLogin
              googleLogin
              facebookLogin
            }
            member {
              message
              signUpLink
            }
            forgotPassword
            errors {
              emptyEmailField
              invalidEmailError
              emptyPasswordField
              minLimitPasswordField
            }
          }
        }
      }
    `);
    const resource = data.markdownRemark.frontmatter;
    DEBUG && ClientLogger.debug('useLoginResource', `render ${JSON.stringify({ resource }, null, 2)}`);

    if (!resource) {
      throw new Error(`useLoginResource - cannot find 'resource' debug: ${JSON.stringify({ resource }, null, 2)}`);
    }

    return { ...resource };
  } catch (e) {
    // Running in CMS Preview
    ClientLogger.error('useLoginResource', 'error caught', e);
    return {} as any;
  }
}
