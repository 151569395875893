import React, { useState } from 'react';
import { ClientLogger } from '../ClientLogger';
import { CartItem, CartState } from '@sharedLib/product';
import { IFrontMatterCart } from '@templates/cart/model';

const DEBUG = false;

const initialCartContext: CartItem[] = [];

const initialCartSyncContext: CartState = {
  success: false,
  state: 'idle',
  message: {
    header: '',
    info: '',
  },
};

const initialCartContent: IFrontMatterCart = {
  header: 'Shopping Cart',
  emptyCart: {
    emptyCartMessage: 'There are currently no items in your shopping cart.',
    emptyCartCTA: 'Go to Homepage',
  },
  orderStatus: {
    orderCompletedMessage: 'Please continue with your purchase in the pop-up window.',
    thankYouMessage: 'Thank you for shopping with us.',
    orderStatusCTA: 'Go to Homepage',
  },
};

export const CartContext = React.createContext({
  cartData: initialCartContext,
  cartState: initialCartSyncContext,
  content: initialCartContent,
  updateCartData: (_: any) => {
    ClientLogger.error('CartContext', 'updateCartData ignored');
  },
  updateCartState: (_: any) => {
    ClientLogger.error('CartContext', 'updateCartStatus ignored');
  },
  updateCartContent: (_: any) => {
    ClientLogger.error('CartContext', 'updateCartContent ignored');
  },
});

export const CartProvider = (props: { cartData: CartItem[]; cartState: CartState; content: IFrontMatterCart; children: any }) => {
  const { cartData: initialData, cartState, children } = props;
  const [cartData, setCartData] = useState(initialData);
  const [cartSyncState, setCartSyncState] = useState<CartState>(cartState);
  const [cartContent, setCartContent] = useState<IFrontMatterCart>(initialCartContent);

  const updateCartData = (newData: any) => {
    DEBUG && ClientLogger.debug('CartContext', 'updateCartData', newData);
    setCartData([...newData]);
  };

  const updateCartState = (state: CartState) => {
    DEBUG && ClientLogger.debug('CartContext', 'updateCartState', state);
    setCartSyncState(state);
  };

  const updateCartContent = (newContent: IFrontMatterCart) => {
    DEBUG && ClientLogger.debug('CartContext', 'updateCartContent', newContent);
    setCartContent(newContent);
  };

  const cartContext = {
    cartData,
    cartState: cartSyncState,
    content: cartContent,
    updateCartData,
    updateCartState,
    updateCartContent,
  };

  return <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>;
};

CartProvider.defaultProps = {
  cartData: [],
};
