import { MeStateContext } from '@lib/meContext';
import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';
import { usePvepApi, ErrorText, ApolloErrorsToErrorStrings } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import { PricingCard } from '@components/PricingCard';
import { FormContainer, TextField } from '@components/Forms';
import { SubmitButton } from '@components/Buttons';
import { useSignUpResource } from '@src/pageComponents/SignUp/useSignUpResources';
import { formHeader, emailSignUpForm } from './styles.module.scss';
import { BrandUtil } from '@sharedLib/index';

interface PromoCodeFormErrors {
  emptyPromoCode: string;
}

const codeValidation = (error: PromoCodeFormErrors) => {
  return yup.object().shape({
    code: yup.string().required(error.emptyPromoCode),
  });
};

const initialValues = {
  code: '',
};

const DEBUG = false;

interface Props {
  successCallback: () => void;
}

export const PromoCode = ({ successCallback }: Props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const api = usePvepApi();
  const meContext = useContext(MeStateContext);
  const content = useSignUpResource();
  const brandInfo = BrandUtil.getSiteInfo();

  const checkSubscribed = async (onSubmit: boolean) => {
    api
      .checkSubscribed()
      .then(async resp => {
        ClientLogger.debug('PromoCode.isVhxSubscribed', 'calling isVhxSubscribed', DEBUG, resp);
        if (resp) {
          ClientLogger.debug('PromoCode.isVhxSubscribed', 'confirmed. is OK. Going home #1', DEBUG);
          await meContext.readMe();
          if (onSubmit) {
            successCallback();
          }
        } else {
          ClientLogger.error('PromoCode.checkSubscribed', 'Cannot refresh token with roles');
          setErrors(['Not subscribed.  Unknown error.']);
        }
      })
      .catch(error => ClientLogger.error('PromoCode.error', error));
  };

  const getMe = async () => {
    await meContext.readMe();
    successCallback();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={codeValidation(content.promoCodePage.errors)}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          ClientLogger.debug('PromoCode.onSubmit', 'values', DEBUG, values);
          // This needs to be done when the shopify success comes back from the webhook
          api
            .createVhxSubscriber(values.code)
            .then(response => {
              ClientLogger.debug('PromoCode.onSubmit', 'response', DEBUG, response);
              if (response.data) {
                if (response.data.subscribe.success) {
                  if (brandInfo.usesVimeoOtt) {
                    checkSubscribed(true);
                  } else {
                    getMe();
                  }
                } else {
                  setErrors(ErrorText(response.data.subscribe.errorMessages));
                }
              }
              if (response.errors) {
                ClientLogger.error('PromoCode.onSubmit', 'error from submit', response.errors);
                setErrors(ApolloErrorsToErrorStrings(response.errors));
              }
            })
            .catch(error => {
              ClientLogger.error('PromoCode.submit', 'error caught', error);
              setErrors([ClientLogger.errorToText(error)]);
            });
        });
        setTimeout(() => {
          actions.setSubmitting(false);
        }, 250);
      }}
      render={props => (
        <FormContainer className={emailSignUpForm} onSubmit={props.handleSubmit} errorMessage={errors && errors.length ? errors : ''}>
          <h1 className={classNames('standard-form__header', formHeader)}>{content.promoCodePage.promoCodeHeader}</h1>
          <PricingCard
            title={content.promoCodePage.pricingCard.subscriptionPlanTitle}
            plan={content.promoCodePage.pricingCard.subscriptionPlanName}
            details={content.promoCodePage.pricingCard.subscriptionPlanDetails}
            price={content.promoCodePage.pricingCard.subscriptionPlanPrice}
          />
          <TextField dataCY="signupPromo" fieldType="code" fieldName="code" placeholder="Promo Code" />
          <SubmitButton disabled={props.isSubmitting || Object.entries(props.errors).length !== 0 || !props.isValid}>
            {content.promoCodePage.promoCodeCTA}
          </SubmitButton>
          {props.status && props.status.msg && <div>{props.status.msg}</div>}
        </FormContainer>
      )}
    />
  );
};
