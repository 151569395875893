import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterProduct } from '@templates/product/model';
import { graphql, useStaticQuery } from 'gatsby';

const DEBUG = false;

export function useProductResource(): IFrontMatterProduct {
  try {
    // alternatively, we could import the md file
    const data = useStaticQuery(graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "product" } }) {
          id
          frontmatter {
            productCardCTA {
              affilateProduct
              nonAffilateProduct
            }
            addProductToastMessage
            signUpPrompt {
              promptCTA
              promptText {
                primary
                secondary
              }
            }
          }
        }
      }
    `);
    const resource = data.markdownRemark.frontmatter;
    DEBUG && ClientLogger.debug('useProductResource', `render ${JSON.stringify({ resource }, null, 2)}`);

    if (!resource) {
      throw new Error(`useProductResource - cannot find 'layout' debug: ${JSON.stringify({ resource }, null, 2)}`);
    }

    return { ...resource };
  } catch (e) {
    // Running in CMS Preview
    ClientLogger.error('useProductResource', 'error caught', e);
    return {} as any;
  }
}
