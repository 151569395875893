import React, { useContext } from 'react';
import classNames from 'classnames';
import IndexLayout from '@src/layouts';
import { FormContainer } from '@src/components/Forms';
import Background from '@src/components/Background';
import { forgotPasswordForm, formHeader, formText, returnButton } from './styles.module.scss';
import { Button } from '@components/Buttons';
import { NavStateContext } from '@lib/navContext';

const SuccessMessage = () => {
  const nav = useContext(NavStateContext);

  return (
    <IndexLayout hideFreeTrial hideLogin>
      <Background />
      <FormContainer className={forgotPasswordForm}>
        <h1 className={classNames('standard-form__header', formHeader)}>Confirmation Email Sent!</h1>
        <h1 className={classNames('standard-form__header', formText)}>Please check your inbox to find the link</h1>
        <h1 className={classNames('standard-form__header', formText)}>to reset your password</h1>
        <Button onClick={() => nav.push({ path: '/login' })} className={returnButton}>
          Return to Login
        </Button>
      </FormContainer>
    </IndexLayout>
  );
};

export default SuccessMessage;
