module.exports = [{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"77fuE2V6QFGrbY2mXVGGCQxH81I6oRnJ","devKey":"77fuE2V6QFGrbY2mXVGGCQxH81I6oRnJ","trackPage":false,"delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.fieldtriplife.com"},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Field Trip","short_name":"Field Trip","description":"Field Trip Music & Arts Festival - a multi-disciplinary, cross-generational event unlike any other","start_url":"/","background_color":"black","theme_color":"black","display":"standalone","icon":"./src/data/brands/ftl/styles/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"475fc8b2b257df4ccafde93e2e411526"},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"cleanupOutdatedCaches":true,"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":5}}]}},
    },{
      plugin: require('../../../../../../apps/packages/ui/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/builds/watchhabitat/habitat/packages/ui/src/cms/cms.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
