import { useFluidImage } from '@components/Img/useFluidImage';
import React from 'react';
import { BrandUtil, Util } from '@sharedLib/index';
import { isDesktop, isTablet } from '@lib/responsive';
import { SocialMediaIcons } from '@components/SocialMediaIcons/SocialMediaIcons';
import classNames from 'classnames';
import { Img } from '@components/Img';
import {
  seriesHeader,
  seriesHeaderImage,
  seriesHeaderImageThumbnail,
  seriesHeaderContent,
  seriesHeaderTitle,
  seriesHeaderDescription,
} from './styles.module.scss';

const DEBUG = false;

interface Props {
  title: string;
  description: string;
  imageUrl?: string;
  gatsbyImageUrl?: string;
}

export const SeriesHeader = (props: Props) => {
  const useDesktop = isDesktop();
  const useTablet = isTablet();
  const brandInfo = BrandUtil.getSiteInfo();
  const baseImageUrl = brandInfo.imageBaseUrl;
  const thumbnailUrl = props.imageUrl ? `${baseImageUrl}/series/${props.imageUrl}` : `${brandInfo.siteUrl}/fallback.png`;

  const images = useFluidImage();
  const icons = useDesktop || useTablet ? <SocialMediaIcons isCentered={false} /> : <SocialMediaIcons isCentered />;
  return (
    <div className={seriesHeader}>
      <div className={seriesHeaderImage}>
        {props.gatsbyImageUrl && (
          <Img
            childImageSharp={images.find(f => f.relativePath === `images/${props.gatsbyImageUrl}`)?.childImageSharp}
            title={props.title}
            alt={props.title}
            className={seriesHeaderImageThumbnail}
          />
        )}
        <img className={seriesHeaderImageThumbnail} src={thumbnailUrl} alt="" />
      </div>
      <div className={seriesHeaderContent}>
        <h1 className={seriesHeaderTitle}>{props.title}</h1>
        <p className={seriesHeaderDescription}>{props.description}</p>
        {icons}
      </div>
    </div>
  );
};
