import CartWidget from '@components/Cart/CartWidget';
import { isBrowser } from '@lib/build';
import { BrandUtil } from '@sharedLib/util';
import queryString from 'query-string';
import React, { useEffect, useState, useContext } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import IndexLayout from '@src/layouts';
import { Stepper } from '@components/Stepper';
import Background from '@src/components/Background';
import { PromoCode } from '@src/pageComponents/SignUp/PromoCode';
import { Thanks } from '@src/pageComponents/SignUp/Thanks';
import { NavStateContext } from '@lib/navContext';
import { useCookies } from 'react-cookie';
import { FacebookGoogleSignUp } from './FacebookGoogleSignUp';
import { EmailSignUp } from './EmailSignUp';

const DEBUG = false;
type StepId = 'start' | 'email' | 'subscribe' | 'cart' | 'thanks';
interface Props {
  step?: StepId;
}
interface Step {
  step: StepId;
  label: string;
  next: StepId | undefined;
}

const SignUp = ({ step }: Props) => {
  const nav = useContext(NavStateContext);
  const { pageData } = nav.navState;
  const destinationUrl: string = pageData.length > 0 && pageData[pageData.length - 1].state?.destination;
  const brandInfo = BrandUtil.getSiteInfo();
  const defaultStart: StepId = 'start';
  const isProductSignUp = isBrowser && window.location.href.indexOf('productId') > -1 && !!queryString.parse(location.search).productId;
  function calcSteps(): Step[] {
    if (!brandInfo.freeMembershipOnSignup) {
      if (isProductSignUp) {
        return [
          { step: 'email', label: 'Create Account', next: 'cart' },
          { step: 'cart', label: 'Purchase', next: 'thanks' },
          { step: 'thanks', label: 'Completed', next: undefined },
        ];
      }
      // if there's no free membership on signup for the brand, add promo code step
      return [
        { step: 'email', label: 'Create Account', next: 'subscribe' },
        { step: 'subscribe', label: 'Promo Code', next: 'thanks' },
        { step: 'thanks', label: 'Completed', next: undefined },
      ];
    }
    if (isProductSignUp) {
      return [
        { step: 'email', label: 'Create Account', next: 'cart' },
        { step: 'cart', label: 'Purchase', next: 'thanks' },
        { step: 'thanks', label: 'Completed', next: undefined },
      ];
    }
    return [
      { step: 'email', label: 'Create Account', next: 'thanks' },
      { step: 'thanks', label: 'Completed', next: undefined },
    ];
  }
  const steps: Step[] = calcSteps();
  const nextStep = (step: StepId): StepId => {
    const newStep = steps.find(s => s.step === step)?.next || 'start';
    DEBUG && ClientLogger.debug('SignUp', 'nextStep', { step, newStep });
    return newStep;
  };
  const api = usePvepApi();
  const userState = api.state;

  const [currentStep, setCurrentStep] = useState<StepId>(step || defaultStart);
  const [cookies, setCookie, removeCookie] = useCookies(['hideVerifyEmailPopup']);

  const handleSignupSuccess = async () => {
    if (brandInfo.freeMembershipOnSignup) {
      const membershipResp = await api.createVhxSubscriber('');
      DEBUG && ClientLogger.debug('SignUpPage:handleSignupSuccess', 'Free membership response=', DEBUG, membershipResp.data);

      if (membershipResp.data?.subscribe.success) {
        DEBUG && ClientLogger.debug('SignUpPage:handleSignupSuccess', 'Free membership signup successful', DEBUG);
      } else {
        if (membershipResp.errors) {
          ClientLogger.error('SignUpPage:handleSignupSuccess', 'error caught from free membership response', membershipResp.errors);
        }
        const error = membershipResp.data?.subscribe.errorMessages;
        ClientLogger.error('SignUpPage:handleSignupSuccess', 'error caught from free membership attempt', error);
      }
    }
    // set hidepopup cookie here to not show popup on first signup
    setCookie('hideVerifyEmailPopup', true, { path: '/' });
    setCurrentStep(nextStep('email'));
  };

  useEffect(() => {
    const location = 'SignUpPage.useEffect';
    if (!userState.isLoggedIn && currentStep !== 'start' && currentStep !== 'email') {
      DEBUG && ClientLogger.debug(location, 'Changing step due to not being logged in', DEBUG);
      setCurrentStep('start');
    }
    DEBUG &&
      ClientLogger.debug(location, 'Checking changing step due to being logged in', DEBUG, {
        userState,
        currentStep,
      });
    if (userState.isLoggedIn && currentStep === 'start') {
      DEBUG && ClientLogger.debug(location, 'Changing step due to being logged in', DEBUG, { userState });
      api
        .checkSubscribed()
        .then(async resp => {
          DEBUG && ClientLogger.debug(location, 'calling isVhxSubscribed', DEBUG, resp);
          if (resp) {
            DEBUG && ClientLogger.debug(location, 'confirmed. is OK. Going home #1', DEBUG);
            nav.setRoot({ path: '/app', title: 'Home' });
          } else {
            DEBUG && ClientLogger.error(location, 'Cannot refresh token with roles');
            setCurrentStep('subscribe');
          }
        })
        .catch(error => ClientLogger.error(location, error));
    }
  }, [currentStep]);

  DEBUG && ClientLogger.debug('SignUp', 'render', { currentStep, isProductSignUp, brandInfo, steps });

  return (
    <IndexLayout landing hideFreeTrial noVideo>
      <Background />
      {currentStep !== 'start' && steps.length >= 3 && !isProductSignUp && <Stepper currentStep={currentStep} steps={steps} />}
      {currentStep === 'start' && (
        <FacebookGoogleSignUp onClickSignupWithEmail={() => setCurrentStep('email')} onSignUpWithProviderSuccess={handleSignupSuccess} />
      )}
      {currentStep === 'email' && <EmailSignUp successCallback={handleSignupSuccess} />}
      {currentStep === 'cart' && <CartWidget successCallback={() => setCurrentStep(nextStep(currentStep))} />}
      {currentStep === 'subscribe' && <PromoCode successCallback={() => setCurrentStep(nextStep(currentStep))} />}
      {currentStep === 'thanks' && <Thanks destinationUrl={destinationUrl} />}
    </IndexLayout>
  );
};

export default SignUp;
