import React, { useContext } from 'react';
import { isBrowser } from '@lib/build';
import IndexLayout from '@src/layouts';
import CartWidget from '@components/Cart/CartWidget';
import { ClientLogger } from '@src/lib/ClientLogger';
import { BrandUtil } from '@sharedLib/util/Brand';
import { IPageProps } from '@src/cms/cms-types';
import { IFrontMatterCart } from '@templates/cart/model';
import { CartContext } from '@src/lib/clientStorage/cartContext';
import { usePvepApi } from '@apiClient/usePvepApi';
const DEBUG = false;

const CartComponent = (props: IPageProps<IFrontMatterCart>) => {
  const cartContext = useContext(CartContext);
  const content = props.pageContext.frontMatter;
  const api = usePvepApi();
  cartContext.updateCartContent(content);

  const productNeedsRegistration = BrandUtil.getSiteInfo().productNeedsRegistration;
  DEBUG && ClientLogger.debug('Cart', `productNeedsRegistration = ${productNeedsRegistration}`);

  if (!isBrowser) {
    return <></>;
  }
  return (
    <IndexLayout
      needsLogin={productNeedsRegistration}
      noMargins
      hideFeedback
      showStrategyPreview={api.state.isAdmin || api.state.isManager}
    >
      <CartWidget />
    </IndexLayout>
  );
};

export default CartComponent;
