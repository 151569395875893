import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterVideo } from '@templates/video/model';
import { graphql, useStaticQuery } from 'gatsby';

const DEBUG = false;

export function useVideoResource(): IFrontMatterVideo {
  try {
    // alternatively, we could import the md file
    const data = useStaticQuery(graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "video" } }) {
          id
          frontmatter {
            miniProductCardCTA
            signUpPrompt {
              promptCTA
              promptText {
                primary
                secondary
              }
            }
          }
        }
      }
    `);
    const resource = data.markdownRemark.frontmatter;
    DEBUG && ClientLogger.debug('useVideoResource', `render ${JSON.stringify({ resource }, null, 2)}`);

    if (!resource) {
      throw new Error(`useVideoResource - cannot find 'layout' debug: ${JSON.stringify({ resource }, null, 2)}`);
    }

    return { ...resource };
  } catch (e) {
    // Running in CMS Preview
    ClientLogger.error('useVideoResource', 'error caught', e);
    return {} as any;
  }
}
