import { useVideo } from '@lib/video-query/useVideoData';
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import { useVideoState, VideoPlayerSize, VideoStateContext } from '@lib/useVideoState';
import { BrandUtil, VideoSource, ascendingOrder, AdWithProduct } from '@sharedLib/index';
import Footer from '@layouts/Footer';
import IndexLayout from '@layouts/index';
import { Button, IconButton } from '@components/Buttons';
import Container from '@components/Container';
import Page from '@components/Page';
import Products from '@components/products';
import { Icon } from '@iconify/react';
import arrowThickThinDown from '@iconify/icons-si-glyph/arrow-thick-thin-down';
import { SignUpPrompt } from '@components/SignUpPrompt';
import { BreadCrumbs } from '@components/BreadCrumbs';
import { NavStateContext } from '@lib/navContext';
import { useLocation } from '@reach/router';

import { VideoPlaceHolder } from '@src/components/VideoPlayer/VideoPlaceHolder';
import { isMobile, isTablet } from '@lib/responsive';
import { useVideoResource } from '@templates/video/useVideoResource';
import {
  productsAndFooter,
  videoContainer,
  largeVideoContainer,
  removeProductsForPrompt,
  shiftForMobileCard,
  gap,
  embeddedVideo,
  nonEmbeddedVideo,
  roomForVideo,
  productsCTA,
  iconCTA,
  tableDebug,
} from './styles.module.scss';

interface VideoPageTemplateProps {
  pageContext: {
    videoId?: string;
    matchPath?: string;
  };
}

const DEBUG = false;

const VideoPageTemplate = ({ pageContext }: VideoPageTemplateProps) => {
  const nav = useContext(NavStateContext);
  const api = usePvepApi();
  const userState = api.state;

  // determine videoId based on static page context or dynamically from the path
  let videoId = pageContext.videoId || '';
  const location = useLocation();
  if (pageContext.matchPath) {
    const pathParts = location.pathname.split('/').reverse();
    for (const part of pathParts) {
      if (part) {
        videoId = part;
        break;
      }
    }
  }

  useEffect(() => {
    if (pageContext.matchPath && !userState.useRuntimeData) {
      nav.setRoot({ path: '/not-found' });
    }
  }, []);

  const videoState = useVideoState();
  const { state } = useContext(VideoStateContext);
  const brandInfo = BrandUtil.getSiteInfo();
  const videoJson = useVideo(videoId);
  const [showDebug, setShowDebug] = useState(false);
  const isMobileSize = isMobile();
  const isTabletSize = isTablet();
  const content = useVideoResource();

  const [products, setProducts] = useState<AdWithProduct[]>([]);

  // whenever video id or the loaded ads change, upate the product list
  useEffect(() => {
    const featured = videoState.state.adPlacements
      .filter(ad => ad.type === 'featured')
      .sort((prev, cur) => ascendingOrder(prev.rank, cur.rank));
    setProducts(featured);
  }, [videoId, videoState.state.adPlacements]);

  DEBUG && ClientLogger.debug('VideoPageTemplate', 'render', DEBUG, { videoState, videoJson });
  useEffect(() => {
    if (state.videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED && state.payPerViewNotPurchased) {
      DEBUG && ClientLogger.debug('VideoPageTemplate.useEffect', 'PPV not purchased, disallow small embedding', DEBUG);
      videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
    }
    if (state.videoPlayerSize === VideoPlayerSize.NOT_SHOWN) {
      DEBUG && ClientLogger.debug('VideoPageTemplate.useEffect', 'Video not shown so setting it', DEBUG);
      videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
    }
    if (state.videoPlayerSize === VideoPlayerSize.SMALL_FLOAT) {
      DEBUG && ClientLogger.debug('VideoPageTemplate.useEffect', 'defaulting to MEDIUM_EMBEDDED', DEBUG, { videoState });
      videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED); // Default to embedded if not manually selected
    }
    if (state.videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED && (isTabletSize || isMobileSize)) {
      DEBUG && ClientLogger.debug('VideoPageTemplate.useEffect', 'screen smaller, setting to MEDIUM_EMBEDDED', DEBUG, { videoState });
      videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
    }
  }, [videoState.state, videoState.state.videoPlayerSize, videoState.state.lastManualSizeRequest, isMobileSize, isTabletSize]);
  useEffect(() => {
    // Enforce autoplay unless otherwise specified
    const navData = [...nav.navState.pageData];
    const preventAutoplay = navData.length ? navData.pop()?.state?.preventAutoplay : false;
    if (!preventAutoplay) {
      videoState.play();
    }
  }, []);
  const { isLoggedIn } = userState;

  const debugText = JSON.stringify({ products }, null, 2);

  const copyDebugToClipBoard = () => {
    console.log({ products });
    navigator.clipboard.writeText(debugText);
  };

  const showPrompt = !isLoggedIn && !videoState.state.video?.isFree && videoState.state.video?.type === VideoSource.VIMEO_OTT;
  // if user is non member, show products only if the video is free or from youtube. If user is member, show products
  const showProductsToNonMembers = videoState.state.userNotMember
    ? videoState.state.video?.isFree || videoState.state.video?.type !== VideoSource.VIMEO_OTT
    : true;

  return (
    <IndexLayout
      needsLogin={brandInfo.videoNeedsRegistration}
      needsSubscription={brandInfo.videoNeedsRegistration && !videoState.state.video?.isFree}
      hideFooter
      hideFeedback
      showStrategyPreview={userState.isAdmin || userState.isManager}
    >
      <Helmet>
        <title>{videoJson?.label || videoJson?.title}</title>
      </Helmet>
      <Page>
        <Container
          className={state.videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? videoContainer : `${largeVideoContainer} largeVid`}
        >
          <div className={classNames(productsAndFooter, showPrompt ? removeProductsForPrompt : '')}>
            {!videoState.state.payPerViewNotPurchased && showProductsToNonMembers && (
              <>
                <BreadCrumbs isVideoPage />
                <Products pageVideo={videoJson} featuredProducts={products} />
              </>
            )}
            {userState.isAdmin && (
              <Button
                onClick={() => {
                  setShowDebug(!showDebug);
                  copyDebugToClipBoard();
                }}
              >
                ?
              </Button>
            )}
          </div>
          {showPrompt && brandInfo.hasSignup && (
            <div className={shiftForMobileCard}>
              <SignUpPrompt
                ctaText={content.signUpPrompt.promptCTA}
                primaryText={content.signUpPrompt.promptText.primary}
                secondaryText={content.signUpPrompt.promptText.secondary}
                destinationUrl={`/video/${videoId}/`}
              />
            </div>
          )}
          <div className={`${state.videoPlayerSize === VideoPlayerSize.SMALL_EMBEDDED ? embeddedVideo : nonEmbeddedVideo}`}>
            {!VideoPlayerSize.NOT_SHOWN && <VideoPlaceHolder videoId={videoId} />}
          </div>
        </Container>
        {showDebug && (
          <div className={classNames(tableDebug)}>
            <Button
              onClick={() => {
                setShowDebug(!showDebug);
              }}
            >
              Debug Off
            </Button>
            <table className={classNames('table tableDebug is-bordered is-striped is-narrow')}>
              <thead>
                <tr>
                  <td>#</td>
                  <td>Product Title</td>
                  <td>Name</td>
                  <td>EComm Type</td>
                  <td>Start time</td>
                  <td>Rank</td>
                  <td>Type</td>
                  <td>Product Id</td>
                  <td>Product Handle</td>
                </tr>
              </thead>
              <tbody>
                {products
                  .sort((a, b) => a.rank - b.rank)
                  .map((ad, index) => (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{ad.product.title}</td>
                      <td>{ad.name}</td>
                      <td>{ad.product.eCommerceType}</td>
                      <td>{ad.startTimeIndex}</td>
                      <td>{ad.rank}</td>
                      <td>{ad.type}</td>
                      <td>{ad.product.id}</td>
                      <td>{ad.product.handle}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <pre>
              <code id="debug">{debugText}</code>
            </pre>
          </div>
        )}
        <Footer />
      </Page>
    </IndexLayout>
  );
};

export default VideoPageTemplate;
