// extracted by mini-css-extract-plugin
export var playerControlContainer = "styles-module--playerControlContainer--3xnYS";
export var leftButtonGroup = "styles-module--leftButtonGroup--c4ml7";
export var centerButtonGroup = "styles-module--centerButtonGroup--3QUol";
export var rightButtonGroup = "styles-module--rightButtonGroup--TrGWy";
export var flexRow = "styles-module--flexRow--3Ba_W";
export var flexColumn = "styles-module--flexColumn--2Lw7I";
export var roundTheCorners = "styles-module--roundTheCorners--3YKfR";
export var playerControlButton = "styles-module--playerControlButton--20iLv";
export var fullScreenContainer = "styles-module--fullScreenContainer--2ZdAO";
export var closeFullScreenContainer = "styles-module--closeFullScreenContainer--3NrOL";
export var iconText = "styles-module--iconText--31S2v";
export var browseText = "styles-module--browseText--1mPqr";
export var centerText = "styles-module--centerText--2Ll4e";
export var smallCenterText = "styles-module--smallCenterText--iYk7e";
export var titleText = "styles-module--titleText--1umY2";
export var shareButton = "styles-module--shareButton--3euYW";
export var theatreIcon = "styles-module--theatreIcon--3HiVE";
export var theatreContainer = "styles-module--theatreContainer--26DDt";
export var cartButton = "styles-module--cartButton--3re1y";
export var cartCounter = "styles-module--cartCounter--2S9yO";