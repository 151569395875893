import { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import debounce from 'lodash.debounce';

export const isDesktop = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMediaQuery({
    query: '(min-width: 1025px)',
  });
};

export const isMobile = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMediaQuery({
    query: '(max-width: 768px)',
  });
};

export const isMobilePortrait = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMediaQuery({
    query: '(max-width: 450px)',
  });
};

export const isTablet = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMediaQuery({
    query: '(min-width: 769px) and (max-width: 1024px)',
  });
};

// get window size, for resizing
// https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = debounce(() => {
      setSize([window.innerWidth, window.innerHeight]);
    }, 400);

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
