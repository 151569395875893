import { NavStateContext } from '@lib/navContext';
import React, { useContext, useEffect } from 'react';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';

// I would have preferred to create this with gatsby-node / netlify CMS, but it seems gatsby does 404 special handling
const NotFoundPage = () => {
  const nav = useContext(NavStateContext);
  ClientLogger.log(
    `NotFoundPage`,
    `user was shown 404 ${JSON.stringify({
      message: '404 rendered',
      referrer: isBrowser ? document.referrer : 'N/A',
      href: isBrowser ? window.location.href : 'N/A',
    })}`
  );
  ClientLogger.error('NotFoundPage', 'user was shown 404', {
    message: '404 rendered',
    referrer: isBrowser ? document.referrer : 'N/A',
    href: isBrowser ? window.location.href : 'N/A',
  });
  useEffect(() => {
    nav.setRoot({ path: 'not-found' });
  }, []);
  return <></>;
};

export default NotFoundPage;
