import { NavigateOptions } from '@reach/router';
import { navigate } from 'gatsby';

import { ClientLogger } from '@lib/ClientLogger';
import { BrandUtil } from '@sharedLib/util';
import React, { useState } from 'react';

export interface PageData {
  path: string;
  title?: string;
  state?: any;
}

interface NavState {
  pageData: PageData[];
}

export interface NavStateType {
  navState: NavState;
  push: (page: PageData, options?: { state: any }) => void;
  replace: (path: PageData, options?: { state: any }) => void;
  setRoot: (path: PageData) => void;
  back: (levels?: number) => void;
}

const DEBUG = false;

const initialNavContext: NavState = { pageData: [] };

/* Define a context and a reducer for updating the context */

/* Export a component to provide the context to its children */
export const NavStateContext = React.createContext({
  navState: initialNavContext,
  push: (_: PageData, _options?: any) => {
    ClientLogger.error('NavStateContext', 'push ignored');
  },
  replace: (_: PageData, _at?: number) => {
    ClientLogger.error('NavStateContext', 'replace ignored');
  },
  replaceInStack: (_: PageData) => {
    ClientLogger.error('NavStateContext', 'replaceInStack ignored');
  },
  setRoot: (_: PageData) => {
    ClientLogger.error('NavStateContext', 'setRoot ignored');
  },
  back: (_: number = 1) => {
    ClientLogger.error('NavStateContext', 'back ignored');
  },
});

export const NavStateProvider = ({ children }: any) => {
  const [paths, setPaths] = useState<PageData[]>([]);

  const nav = (pageData: PageData, options?: NavigateOptions<any>) => {
    const pathPrefix = ''; // Gatsby automatically does this. (?)
    // const pathPrefix = BrandUtil.getSiteInfo().pathPrefix ? `${BrandUtil.getSiteInfo().pathPrefix}/` : '';
    const path = pageData.path[0] === '/' ? pageData.path.substr(1) : pageData.path;
    ClientLogger.debug('NavStateProvider', `Navigate started typeof navigate ${typeof navigate}`, DEBUG, { path, pageData, pathPrefix });
    navigate(`/${pathPrefix}${path}`, options);
  };

  const push = (pageData: PageData, options?: { state: any }) => {
    ClientLogger.debug('NavStateProvider', 'push', DEBUG, { pageData, paths });
    setPaths([...paths, pageData]);
    ClientLogger.debug('NavStateProvider', 'push. New State', DEBUG, { paths });
    nav(pageData, options);
  };

  const back = (levels: number = 1) => {
    ClientLogger.debug('NavStateProvider', 'back. called', DEBUG, { paths, levels });
    const howFar = levels + 1;
    if (paths.length >= howFar) {
      const target = paths[paths.length - howFar];
      paths.pop();
      setPaths([...paths]);
      ClientLogger.debug('NavStateProvider', 'back. New State', DEBUG, { target, paths });
      nav(target);
    } else if (paths.length === 1) {
      setPaths([{ path: '/app', title: 'Home' }]);
      nav({ path: '/app', title: 'Home' });
    } else {
      ClientLogger.warning('NavStateProvider', 'back called on empty stack');
      window.history.back();
    }
  };

  const replaceAt = (pageData: PageData, at: number, options?: { state: any }) => {
    ClientLogger.debug('NavStateProvider', 'replaceAt', DEBUG, { pageData, paths, at });
    paths[at] = pageData;
    while (paths.length - 1 > at) {
      paths.pop();
    }
    setPaths([...paths]);
    ClientLogger.debug('NavStateProvider', 'replaceAt. New State', DEBUG, { paths });
    nav(pageData, { ...options });
  };

  const replace = (pageData: PageData, at?: number) => {
    if (paths.length > 0) {
      ClientLogger.debug('NavStateProvider', 'replace', DEBUG, { pageData, paths });
      if (at === undefined) {
        at = paths.length - 1;
      }
      replaceAt(pageData, at);
    } else {
      ClientLogger.warning('NavStateProvider', 'replace called on empty stack. calling set root');
      setRoot(pageData);
    }
  };

  const replaceInStack = (pageData: PageData) => {
    if (paths.length > 0) {
      ClientLogger.debug('NavStateProvider', 'replaceInStack', DEBUG, { pageData, paths });
      let at = paths.findIndex(p => p.path === pageData.path);
      if (at === -1) {
        at = paths.length - 1;
      }
      replaceAt(pageData, at);
    } else {
      ClientLogger.warning('NavStateProvider', 'replace called on empty stack. calling set root');
      setRoot(pageData);
    }
  };

  const setRoot = (pageData: PageData) => {
    ClientLogger.debug('NavStateProvider', 'setRoot', DEBUG, { pageData, paths });
    setPaths([pageData]);
    ClientLogger.debug('NavStateProvider', 'setRoot. New State', DEBUG, { paths });
    nav(pageData);
  };

  ClientLogger.debug('NavStateProvider', 'called', DEBUG, { paths });
  return (
    <NavStateContext.Provider value={{ navState: { pageData: paths }, push, replace, replaceInStack, setRoot, back }}>
      {children}
    </NavStateContext.Provider>
  );
};
