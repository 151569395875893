import { usePvepApi, UserState } from '@apiClient/usePvepApi';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { navigate } from 'gatsby';

const DEBUG = false;

export class AuthClientUtil {
  public static async redirectToLogin() {
    ClientLogger.debug('AuthClientUtil', 'redirectToLogin', DEBUG);
    if (!isBrowser) {
      return;
    }

    navigate('/login');
  }

  public static logout(redirect: string) {
    ClientLogger.debug('AuthClientUtil', 'logout', DEBUG);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = usePvepApi();
    if (isBrowser) {
      // redirect to fusionauth logout url to end session. append redirect url to... redirect
      DEBUG && ClientLogger.debug('AuthClientUtils.logout', `Logging out... redirect: ${redirect}`);
      api.logout().finally(() => {
        if (redirect && redirect !== window.location.pathname) {
          DEBUG && ClientLogger.debug('AuthClientUtils.logout', `Navigating...`);
          navigate(redirect);
        }
      });
    }
  }

  public static navigateToRegister() {
    ClientLogger.debug('AuthClientUtil', 'navigateToRegister', DEBUG);
    navigate('/subscribe');
  }
}
