import React, { useEffect } from 'react';
import { isBrowser } from '@lib/build';
import { useContactUsResource } from '@src/pageComponents/ContactUs/useContactUsResources';
import { Img } from '@src/components/Img';
import { Icon } from '@iconify/react';
import closeCircleTwotone from '@iconify/icons-ant-design/close-circle-twotone';
import { thankYouBackground, thankYouBackgroundWrapper, info, icon, header, subHeader, closeModal } from './styles.module.scss';

interface Props {
  setThankYouState: (on: boolean) => void;
}

const ThankYou = ({ setThankYouState }: Props) => {
  const content = useContactUsResource();

  useEffect(() => {
    if (isBrowser) {
      setTimeout(() => {
        setThankYouState(false);
      }, 3000);
    }
  }, []);

  return (
    <div className={thankYouBackgroundWrapper}>
      <div className={thankYouBackground}>
        <div
          className={closeModal}
          onClick={() => {
            setThankYouState(false);
          }}
        >
          <Icon icon={closeCircleTwotone} height="20px" />
        </div>
        <div className={info}>
          <Img childImageSharp={content.thankYouIconImage.childImageSharp} className={icon} />
          <div className={header}>{content.thankYouHeader}</div>
          <div className={subHeader}>{content.thankYouSubHeader}</div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
