// extracted by mini-css-extract-plugin
export var videoWrapper = "styles-module--videoWrapper--3aisJ";
export var loading = "styles-module--loading--3z9wc";
export var adBannerContainer = "styles-module--adBannerContainer--3rrFg";
export var adBoxWrapper = "styles-module--adBoxWrapper--2RJeg";
export var adBox = "styles-module--adBox--7Ypp5";
export var adContentWrapper = "styles-module--adContentWrapper--y6TbM";
export var adImage = "styles-module--adImage--3I9Dy";
export var adInfo = "styles-module--adInfo--2offT";
export var productTitle = "styles-module--productTitle--1RdfZ";
export var productPrice = "styles-module--productPrice--3lWJx";
export var videoContent = "styles-module--videoContent--3uz4s";
export var playerControlContainer = "styles-module--playerControlContainer--3Fl2d";
export var roundTheCorners = "styles-module--roundTheCorners--_yuPR";
export var playerControlButton = "styles-module--playerControlButton--DHFnY";
export var fullScreenIcon = "styles-module--fullScreenIcon--3Jeqn";
export var iconText = "styles-module--iconText--13rDE";
export var centerText = "styles-module--centerText--3dP2s";
export var smallCenterText = "styles-module--smallCenterText--1jqXV";
export var shortDescriptionText = "styles-module--shortDescriptionText--1U3Nm";
export var popUpHeader = "styles-module--popUpHeader--NxHHi";
export var popUpBorder = "styles-module--popUpBorder--2S-GR";
export var onScreenDebug = "styles-module--onScreenDebug--BUqbE";
export var videoAndAdWrapper = "styles-module--videoAndAdWrapper--3MvYk";
export var hide = "styles-module--hide--1yTEr";
export var videoPlaceHolderMedium = "styles-module--videoPlaceHolderMedium--2EcjI";
export var videoPlaceHolderEmbed = "styles-module--videoPlaceHolderEmbed--3TD8b";
export var videoPlaceHolderMediumBanner = "styles-module--videoPlaceHolderMediumBanner--3JJO9";
export var videoPlaceHolderSmallEmbedded = "styles-module--videoPlaceHolderSmallEmbedded--3H8pt";
export var videoPlayerMedium = "styles-module--videoPlayerMedium--3u4TG";
export var videoPlayerSmall = "styles-module--videoPlayerSmall--iMo_q";
export var videoPlaceHolderSmall = "styles-module--videoPlaceHolderSmall--2oOHX";
export var videoPlayerLarge = "styles-module--videoPlayerLarge--2livm";
export var videoPlaceHolderLarge = "styles-module--videoPlaceHolderLarge--3Qu7S";
export var videoPlayerNotShown = "styles-module--videoPlayerNotShown--3y94N";
export var iframe = "styles-module--iframe--2oFqF";
export var iframeMiniPlayer = "styles-module--iframeMiniPlayer--JJDem";
export var jsPlayer = "styles-module--jsPlayer--20T6a";
export var jsMiniPlayer = "styles-module--jsMiniPlayer--1vHDq";
export var RndPopUp = "styles-module--RndPopUp--1io97";
export var popUpClose = "styles-module--popUpClose--2nHPJ";
export var hidden = "styles-module--hidden--2cXhy";
export var visible = "styles-module--visible--2H2a2";
export var theatreButtonLeft = "styles-module--theatreButtonLeft--29D40";
export var theatreContainer = "styles-module--theatreContainer--22uCe";
export var buttonIcon = "styles-module--buttonIcon--10_on";
export var cartButtonContainer = "styles-module--cartButtonContainer--2pnoO";
export var cartButton = "styles-module--cartButton--wqT__";
export var cartCounter = "styles-module--cartCounter--1cRQu";