import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { useProductData } from '@lib/shopify/useProductData';
import React, { useContext } from 'react';
import IndexLayout from '@layouts/index';
import Page from '@components/Page';
import { BrandUtil } from '@sharedLib/index';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '@components/BreadCrumbs';
import Products from '@components/products';

const DEBUG = false;

export interface ProductCollectionTemplateProps {
  pageContext: {
    productCollectionId: string;
  };
}

const ProductCollectionTemplatePage = (props: ProductCollectionTemplateProps) => {
  const collectionId = props.pageContext.productCollectionId;

  const products = useProductData().getProductByCollection(collectionId);
  const nav = useContext(NavStateContext);
  const brandInfo = BrandUtil.getSiteInfo();
  if (!collectionId) {
    ClientLogger.error(`ProductCollectionTemplatePage`, 'no data', props);
    return (
      <ErrorDisplay
        errorData={{ errors: [`Missing productCollectionId or invalid.  data=${JSON.stringify({ pageContext: props.pageContext })}`] }}
      />
    );
  }
  return (
    <IndexLayout needsLogin={brandInfo.videoNeedsRegistration} needsSubscription={brandInfo.videoNeedsRegistration} noVideo>
      <Helmet>{/* <title>{c.name}</title> */}</Helmet>
      <Page>
        <BreadCrumbs />
        <div>
          <Products productCollection={products} />
        </div>
      </Page>
    </IndexLayout>
  );
};

export default ProductCollectionTemplatePage;
