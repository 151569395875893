import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import React, { useState } from 'react';
import { FormContainer, TextField } from '@src/components/Forms';
import { Formik } from 'formik';
import classNames from 'classnames';
import { SubmitButton } from '@src/components/Buttons';
import IndexLayout from '@src/layouts';

import {
  accountDetailsWrapper,
  accountInfoForm,
  infoWrapper,
  headerText,
  subHeaderText,
  subHeaderTextWrapper,
  link,
  hideLinks,
  info,
} from './styles.module.scss';

const DEBUG = false;

const initialValues = {
  firstname: '',
  lastname: '',
  creditcardnumber: '',
  expirymonth: '',
  expiryyear: '',
  cvc: '',
  address: '',
  city: '',
  postal: '',
  province: '',
};

interface FormData {
  firstname: string;
  lastname: string;
  creditcardnumber: string;
  expirymonth: string;
  expiryyear: string;
  cvc: string;
  address: string;
  city: string;
  postal: string;
  province: string;
}

const PaymentInfo = () => {
  const api = usePvepApi();
  const user = api.state;
  ClientLogger.debug('PaymentInfo', 'render', DEBUG, user);

  const [updatePaymentDetails, setUpdatePaymentDetails] = useState<boolean>();
  return (
    <IndexLayout noVideo>
      <Formik
        onSubmit={async (_values: FormData, { setSubmitting }) => {
          setSubmitting(false);
        }}
        initialValues={initialValues}
        render={props => {
          return (
            <div className={accountDetailsWrapper}>
              <div>
                <h1 className={headerText}>Payment</h1>
              </div>
              <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
                <div className={classNames(infoWrapper, subHeaderTextWrapper)}>
                  <h1 className={subHeaderText}>Payment Details</h1>
                  <div className={updatePaymentDetails ? hideLinks : ''}>
                    <div
                      onClick={() => {
                        setUpdatePaymentDetails(true);
                      }}
                      className={link}
                    >
                      Edit
                    </div>
                  </div>
                  <div className={updatePaymentDetails ? '' : hideLinks}>
                    <SubmitButton>Save</SubmitButton>
                  </div>
                </div>
                <div className={infoWrapper}>
                  <TextField
                    placeholder="First Name"
                    fieldType="name"
                    fieldName="firstname"
                    className={info}
                    disabled={!updatePaymentDetails}
                  />
                  <TextField
                    placeholder="Last Name"
                    fieldType="name"
                    fieldName="lastname"
                    className={info}
                    disabled={!updatePaymentDetails}
                  />
                </div>
                <TextField
                  placeholder="Credit Card Number"
                  fieldType="text"
                  fieldName="creditcardnumber"
                  className={info}
                  disabled={!updatePaymentDetails}
                />
                <div className={classNames(infoWrapper)}>
                  <TextField
                    placeholder="Month"
                    fieldType="text"
                    fieldName="expirymonth"
                    className={info}
                    disabled={!updatePaymentDetails}
                  />
                  <TextField placeholder="Year" fieldType="text" fieldName="expiryyear" className={info} disabled={!updatePaymentDetails} />
                </div>
                <TextField placeholder="CVC" fieldType="text" fieldName="cvc" className={info} disabled={!updatePaymentDetails} />
                <TextField placeholder="Address" fieldType="text" fieldName="address" className={info} disabled={!updatePaymentDetails} />
                <TextField placeholder="City" fieldType="text" fieldName="city" className={info} disabled={!updatePaymentDetails} />
                <TextField
                  placeholder="Postal Code"
                  fieldType="text"
                  fieldName="postal"
                  className={info}
                  disabled={!updatePaymentDetails}
                />
                <TextField placeholder="Province" fieldType="text" fieldName="province" className={info} disabled={!updatePaymentDetails} />
              </FormContainer>
            </div>
          );
        }}
      />
    </IndexLayout>
  );
};

export default PaymentInfo;
