import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterMyAccount } from '@src/pageComponents/MyAccount/model';
import { graphql, useStaticQuery } from 'gatsby';

const DEBUG = false;

export function useMyAccountResource(): IFrontMatterMyAccount {
  try {
    // alternatively, we could import the md file
    const data = useStaticQuery(graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "my-account" } }) {
          id
          frontmatter {
            accountTabName
            accountTabErrors {
              passwordIsTooShort
              passwordsDoNotMatch
              emptyNewPasswordField
              emptyConfirmPasswordField
              emptyCurrentPasswordField
            }
            paymentTabName
            profileTabName
            profileTabErrors {
              emptyLastNameField
              emptyFirstNameField
            }
            membershipTabName
            membershipTabContent {
              mainPage {
                membershipCTA {
                  subscribe
                  unsubscribe
                }
                currentPlanDescription
              }
              thankYouPage {
                backToMainPageCTA
                thankYouPageHeader
                thankYouPageSubHeader
                thankYouPageMessage
              }
              cancelMembershipPage {
                cancelMembershipCTA {
                  cancelUnsubscribe
                  confirmUnsubscribe
                }
                cancelMembershipPageHeader
                cancelMembershipPageSubHeader
                cancelMembershipPageMessage
              }
              deleteAccountPage {
                deleteAccountErrors {
                  deleteAccountUnchecked
                }
                reasonsForDeletion {
                  name
                  value
                }
              }
            }
          }
        }
      }
    `);
    const resource = data.markdownRemark.frontmatter;
    DEBUG && ClientLogger.debug('useMyAccountResource', `render ${JSON.stringify({ resource }, null, 2)}`);

    if (!resource) {
      throw new Error(`useMyAccountResource - cannot find 'resource' debug: ${JSON.stringify({ resource }, null, 2)}`);
    }

    return { ...resource };
  } catch (e) {
    // Running in CMS Preview
    ClientLogger.error('useMyAccountResource', 'error caught', e);
    return {} as any;
  }
}
