import { ShopifyProduct, ShopifyVariant } from '@sharedLib/product';
export interface ShopifyVariantDisplayOption {
  text: string;
  value: string;
}

export class ShopifyUtil {
  public static extractMetafield(product: ShopifyProduct, metafieldKey: string) {
    const metafields = product.metafields;
    const metafield = metafields.filter(field => field.key === metafieldKey)[0];
    return metafield.value;
  }

  public static formatVariantsForDisplay(variants: ShopifyVariant[]) {
    const displayVariants: Map<string, ShopifyVariantDisplayOption[]> = new Map();

    variants.forEach(variant => {
      if (variant.selectedOptions) {
        variant.selectedOptions.forEach(option => {
          if (!displayVariants.get(option.name)) {
            displayVariants.set(option.name, []);
          }
          const displayOption = {
            text: option.value,
            value: option.value,
          };
          const currentOptions = displayVariants.get(option.name);
          if (currentOptions) {
            const currentOptionValues = currentOptions.map(displayOption => {
              return displayOption.text;
            });
            if (!currentOptionValues.includes(displayOption.text)) {
              currentOptions.push(displayOption);
              displayVariants.set(option.name, currentOptions);
            }
          }
        });
      }
    });
    if (displayVariants.size > 0) {
      return displayVariants;
    }

    return new Map();
  }

  public static setDefaultDisplayVariants(displayVariants: Map<string, ShopifyVariantDisplayOption[]>) {
    const defaultVariants: any = {};
    for (const [optionName, options] of displayVariants) {
      if (!defaultVariants[optionName]) {
        defaultVariants[optionName] = options[0];
      }
    }

    return defaultVariants;
  }

  public static getFirstVariant(product: ShopifyProduct): ShopifyVariant | undefined {
    return product && product.variants && product.variants.length > 0 ? product.variants[0] : undefined;
  }

  public static getPrice(product: ShopifyProduct): string {
    const variant = ShopifyUtil.getFirstVariant(product);
    if (variant) {
      return String(variant.price);
    }
    return '';
  }
}
