import React from 'react';
import IndexLayout from '@layouts/index';
import Background from '@src/components/Background';
import { container, headerText, progressBarContainer, progress } from './styles.module.scss';

interface Props {
  message?: string;
}

export const LoadingPage = ({ message }: Props) => {
  return (
    <IndexLayout hideFooter hideHeader>
      <Background />
      <div className={container}>
        <h2 className={headerText}>{message || 'Loading...'}</h2>
        <div className={progressBarContainer}>
          <div className={progress}>
            <div style={{ animationDuration: '5s', animationTimingFunction: 'ease-out' }} />
          </div>
        </div>
      </div>
    </IndexLayout>
  );
};
