import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import * as yup from 'yup';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useCookies } from 'react-cookie';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import IndexLayout from '@src/layouts';
import { FormikContainer, TextField } from '@src/components/Forms';
import { Button } from '@src/components/Buttons';
import Background from '@src/components/Background';
import { NavStateContext } from '@src/lib/navContext';

import { info, subHeaderTextWrapper, forgotPasswordForm, formHeader, textError } from './styles.module.scss';

interface ForgotPasswordErrors {
  emptyNewPasswordField: string;
  emptyConfirmPasswordField: string;
  passwordsDoNotMatch: string;
  passwordIsTooShort: string;
}

const validationSchema = (error: ForgotPasswordErrors) => {
  return yup.object().shape({
    newPassword: yup
      .string()
      .required(error.emptyNewPasswordField)
      .min(8, error.passwordIsTooShort),
    confirmNewPassword: yup
      .string()
      .required(error.emptyConfirmPasswordField)
      .min(8, error.passwordIsTooShort)
      .test('passwords-match', error.passwordsDoNotMatch, function testPass(value) {
        return this.parent.newPassword === value;
      }),
  });
};

const initialValues = {
  newPassword: '',
  confirmNewPassword: '',
};

interface FormData {
  newPassword: string;
  confirmNewPassword: string;
}

const errors = {
  emptyCurrentPasswordField: 'Current password is required',
  emptyNewPasswordField: 'New password is required',
  emptyConfirmPasswordField: 'Password confirmation is required',
  passwordsDoNotMatch: 'The passwords do not match - try again',
  passwordIsTooShort: 'Password must be at least 8 characters',
};

const DEBUG = false;
export const ForgotPassword: React.FC<any> = () => {
  const api = usePvepApi();
  const [errorMessage, setErrorMessage] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['passwordlessLogin']);
  const nav = useContext(NavStateContext);

  useEffect(() => {
    if (api.state.isLoggedIn) {
      nav.setRoot({ path: '/app', title: 'Home' });
    }

    if (queryString.parse(location.search).passwordlessLogin) {
      if (cookies.passwordlessLogin) {
        removeCookie('passwordlessLogin');
      }
      setCookie('passwordlessLogin', queryString.parse(location.search).passwordlessLogin, { path: '/' });
      nav.setRoot({ path: '/forgot-password' });
    }
  }, []);

  return (
    <IndexLayout hideFreeTrial hideLogin landing noVideo>
      <Background />
      <Formik
        onSubmit={async (values: FormData, { setSubmitting }) => {
          setSubmitting(false);
          if (values.newPassword === values.confirmNewPassword) {
            ClientLogger.debug('UserProfile.updateUserPassword', `current password = new password = ${values.newPassword}`, DEBUG);
            if (cookies.passwordlessLogin) {
              const resp = await api.updateUserPasswordFromChangePasswordId(values.newPassword, cookies.passwordlessLogin);
              ClientLogger.debug('UserProfile.updateUserPasswordFromChangePasswordIdResp', `resp = ${JSON.stringify(resp)}`, DEBUG);

              if (resp.data && resp.data.updateUserPasswordFromChangePasswordId.success) {
                nav.setRoot({ path: '/login' });
              } else {
                setErrorMessage('Invalid Password Change Key');
              }
            } else {
              setErrorMessage('Invalid Password Change Key');
            }
          }
        }}
        initialValues={initialValues}
        validationSchema={validationSchema(errors)}
      >
        {props => {
          ClientLogger.debug('Login', 'render', DEBUG, props);
          return (
            <FormikContainer className={forgotPasswordForm} errorMessage={errorMessage && <div className={textError}>{errorMessage}</div>}>
              <h1 className={classNames('standard-form__header', formHeader)}>Update Password</h1>
              <div className={classNames(subHeaderTextWrapper)}>
                <TextField headerText="New Password" fieldType="password" fieldName="newPassword" className={info} />
              </div>
              <div className={classNames(subHeaderTextWrapper)}>
                <TextField headerText="Confirm New Password" fieldType="password" fieldName="confirmNewPassword" className={info} />
              </div>
              <Button dataCY="submitNewPassword" type="submit" disabled={!props.isValid}>
                Submit
              </Button>
            </FormikContainer>
          );
        }}
      </Formik>
    </IndexLayout>
  );
};
