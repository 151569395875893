/* eslint-disable no-restricted-globals */
import { NavStateContext } from '@lib/navContext';
import { useHomePageData, useVideoData, getCategoryItems } from '@lib/video-query/useVideoData';
import React, { useContext, useState, useEffect } from 'react';
import IndexLayout from '@src/layouts';
import { FeaturedCarousel } from '@components/Carousel/FeaturedCarousel';
import Page from '@components/Page';
import Container from '@components/Container';
import { VideoList } from '@components/VideoList';
import { ClientLogger } from '@lib/ClientLogger';
import { BrandUtil, alphabeticalOrder, DatabaseVideo, DatabaseSeries, DatabaseCategory, ascendingOrder } from '@sharedLib/index';
import { isBrowser } from '@lib/build';
import { usePvepApi } from '@apiClient/usePvepApi';
import { HomeBannerLayouts } from '@sharedLib/util/Brand';
import PPVBanner from '@src/components/PPVBanner';
import { FeaturedVideo } from '@src/components/FeaturedVideo';
import { homePageCollection } from './styles.module.scss';

const DEBUG = false;
const SHOW_CONTINUE_WATCHING = false;

const HomeComponent = () => {
  const nav = useContext(NavStateContext);
  const api = usePvepApi();
  const userState = api.state;
  const navData = nav.navState.pageData;
  const brandInfo = BrandUtil.getSiteInfo();
  const [loading, setLoading] = useState(false);
  const [allVideos, setAllVideos] = useState<DatabaseVideo[]>([]);
  const [allSeries, setAllSeries] = useState<DatabaseSeries[]>([]);
  const [allCategories, setAllCategories] = useState<DatabaseCategory[]>([]);

  const { featuredCollections, videoCollections } = useHomePageData();
  const allVideoData = useVideoData();

  useEffect(() => {
    if (userState.useRuntimeData) {
      loadCategories();
    }
  }, []);

  const loadCategories = async () => {
    setLoading(true);
    const catResp = await api.getDatabaseCategories();
    if (catResp.data?.getDatabaseCategories.success && catResp.data.getDatabaseCategories.categories) {
      const databaseCategory = catResp.data.getDatabaseCategories.categories.map(category => {
        const newCat: any = category;
        delete newCat.__typename;
        return newCat;
      });
      setAllCategories(databaseCategory);
    } else {
      setAllCategories([]);
    }

    const videoResp = await api.getDatabaseVideos();
    if (videoResp.data?.getDatabaseVideos.success) {
      const databaseVideos = videoResp.data.getDatabaseVideos.videos
        .map(video => {
          const newVid: any = video;
          delete newVid.__typename;
          return newVid;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setAllVideos(databaseVideos);
    } else {
      setAllVideos([]);
    }

    const seriesResp = await api.getDatabaseSeries();
    if (seriesResp.data?.getDatabaseSeries.success && seriesResp.data.getDatabaseSeries.series) {
      const databaseSeries = seriesResp.data.getDatabaseSeries.series
        .map(series => {
          const newSeries: any = series;
          delete newSeries.__typename;
          return newSeries;
        })
        .sort((a, b) => alphabeticalOrder(a.title, b.title));
      setAllSeries(databaseSeries);
    } else {
      setAllSeries([]);
    }

    setLoading(false);
  };

  DEBUG && ClientLogger.debug('HomeComponent', `render`, { userState, featuredCollections, videoCollections });

  const navigateToVideo = (videoId: string, title: string) => {
    if (isBrowser) {
      if (navData.length && navData[navData.length - 1].path.split('/').includes('video')) {
        nav.replace({ path: `/video/${videoId}/`, title });
      } else {
        nav.push({ path: `/video/${videoId}/`, title });
      }
    }
  };

  const onClickCollection = (collectionId: number | string, title: string) => {
    ClientLogger.debug('HomeComponent', 'onClickCollection', DEBUG, { collectionId, title });
    if (isBrowser) {
      if (navData[navData.length - 1]?.path.split('/').includes('series')) {
        nav.replace({ path: `/series/${collectionId}/`, title });
      } else {
        nav.push({ path: `/series/${collectionId}/`, title });
      }
    }
  };

  ///
  /// Get featured video for featured video home banner
  ///
  const getFeaturedVideo = (featuredCategories: DatabaseCategory[], allVideos: DatabaseVideo[]) => {
    if (featuredCategories.length > 0) {
      const firstFeaturedVid = featuredCategories[0].items.find(item => Boolean(item.videoId));
      const video = allVideos.find(vid => vid.id === firstFeaturedVid?.videoId);
      return video;
    }
    return undefined;
  };

  const getBannerLayout = () => {
    switch (brandInfo.homePageConfig.homeBannerLayout) {
      case HomeBannerLayouts.PPV_PRODUCT:
        return <PPVBanner />;

      case HomeBannerLayouts.FEATURED_VIDEO:
        if (userState.useRuntimeData) {
          const featuredCategories = allCategories.filter(cat => cat.isFeatured);
          const video = getFeaturedVideo(featuredCategories, allVideos);
          if (video) {
            return <FeaturedVideo video={video} />;
          }
          return <></>;
        } else {
          const video = getFeaturedVideo(featuredCollections, allVideoData.videos);
          if (video) {
            return <FeaturedVideo video={video} />;
          }
          return <></>;
        }

      case HomeBannerLayouts.CAROUSEL:
        if (userState.useRuntimeData) {
          // get featured category
          const featuredCategories = allCategories.filter(cat => cat.isFeatured);

          // get video and series items contained
          const items: {
            item: DatabaseVideo | DatabaseSeries;
            type: 'series' | 'video';
          }[] = [];
          featuredCategories.map(cat => {
            items.push(...getCategoryItems(cat, allSeries, allVideos));
          });
          return <FeaturedCarousel carouselItems={items} />;
        } else {
          // get video and series items contained
          const items: {
            item: DatabaseVideo | DatabaseSeries;
            type: 'series' | 'video';
          }[] = [];
          featuredCollections.map(cat => {
            items.push(...getCategoryItems(cat, allVideoData.series, allVideoData.videos));
          });
          return <FeaturedCarousel carouselItems={items} />;
        }
      default:
        return <FeaturedCarousel carouselItems={[]} />;
    }
  };

  const heroBanner = getBannerLayout();

  return (
    <>
      <IndexLayout needsLogin={brandInfo.videoNeedsRegistration} needsSubscription={brandInfo.videoNeedsRegistration} noVideo>
        <Page>
          {heroBanner}
          <Container>
            {!userState.useRuntimeData &&
              videoCollections.map((cat, i) => {
                const items = getCategoryItems(cat, allVideoData.series, allVideoData.videos);
                return (
                  <div key={i} className={homePageCollection}>
                    <VideoList
                      items={items}
                      onClick={async (videoId, title) => {
                        await api.updateUserWatching({
                          categoryId: cat.id,
                          seriesId: '',
                          seasonId: '',
                          videoId,
                        });
                        navigateToVideo(videoId, title);
                      }}
                      onClickCollection={async (collectionId, title) => {
                        await api.updateUserWatching({
                          categoryId: cat.id,
                          seriesId: collectionId.toString(),
                          seasonId: '',
                          videoId: '',
                        });
                        onClickCollection(collectionId, title);
                      }}
                      name={cat.title}
                      horizontal
                      header
                    />
                  </div>
                );
              })}
            {loading && userState.useRuntimeData && <div>Loading...</div>}
            {!loading &&
              userState.useRuntimeData &&
              allCategories
                .filter(category => !category.isFeatured)
                .sort((a, b) => ascendingOrder(a.rank, b.rank))
                .map((cat, i) => {
                  const items = getCategoryItems(cat, allSeries, allVideos);
                  return (
                    <div key={i} className={homePageCollection}>
                      <VideoList
                        items={items}
                        onClick={async (videoId, title) => {
                          await api.updateUserWatching({
                            categoryId: cat.id,
                            seriesId: '',
                            seasonId: '',
                            videoId,
                          });
                          navigateToVideo(videoId, title);
                        }}
                        onClickCollection={async (collectionId, title) => {
                          await api.updateUserWatching({
                            categoryId: cat.id,
                            seriesId: collectionId.toString(),
                            seasonId: '',
                            videoId: '',
                          });
                          onClickCollection(collectionId, title);
                        }}
                        name={cat.title}
                        horizontal
                        header
                      />
                    </div>
                  );
                })}
          </Container>
        </Page>
      </IndexLayout>
    </>
  );
};

export default HomeComponent;
