import React from 'react';

export const scrollToLocation = (locationId: string) => {
  const element = document.getElementById(locationId);

  if (element) {
    element.scrollIntoView();
  }
};

export const scrollToRefLocation = (ref: React.RefObject<HTMLElement>) => {
  window.scrollTo({
    left: 0,
    top: ref.current?.offsetTop,
    behavior: 'smooth',
  });
};
