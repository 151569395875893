import React, { useEffect, useState } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { BrandUtil } from '@sharedLib/util';
import { adminSubText, adminText, analyticsMessageContainer } from './styles.module.scss';

const DEBUG = false;

export const DataAnalytics = () => {
  const api = usePvepApi();
  const { analyticsConfig } = BrandUtil.getSiteInfo();
  const [accessToken, setAccessToken] = useState('');
  const [accessTokenExpiry, setAccessTokenExpiry] = useState<number | undefined>(undefined);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // get an access token on mount
  useEffect(() => {
    getAccessToken();
  }, []);

  // new expiry time received, set a timeout to refresh the token and record the timeout id
  useEffect(() => {
    if (accessTokenExpiry) {
      const timeout = setTimeout(() => {
        getAccessToken();
      }, (accessTokenExpiry - Math.floor(Date.now() / 1000)) * 1000);

      setCurrentTimeout(timeout);
    }
  }, [accessTokenExpiry]);

  // timeout cleanup when a new one is received or the component is unmounted
  useEffect(() => {
    return () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
    };
  }, [currentTimeout]);

  // use the refresh cookie to get an access token
  const getAccessToken = async () => {
    if (!analyticsConfig.brandUsesAnalytics) {
      return;
    }

    const refreshResp = await api.refreshFourIDataToken();
    if (refreshResp.data?.refreshFourIDataToken.success) {
      if (!refreshResp.data.refreshFourIDataToken.accessToken) {
        // still no token, show error instead of iframe
        setErrorMessage('You do not have the necessary authentication to view the analytics data.');
        setLoading(false);
        return;
      }

      const decodedToken = jwtDecode<JwtPayload>(refreshResp.data.refreshFourIDataToken.accessToken);

      setAccessToken(refreshResp.data.refreshFourIDataToken.accessToken);
      setAccessTokenExpiry(decodedToken.exp);
      setLoading(false);
    } else {
      // refresh unsuccessful, show error instead of iframe
      const errorMessages = refreshResp.data?.refreshFourIDataToken?.errorMessages
        ? refreshResp.data?.refreshFourIDataToken.errorMessages[0]
        : undefined;
      const message = errorMessages?.errorMessage;
      setErrorMessage(message || 'You do not have the necessary authentication to view the analytics data.');
      setLoading(false);
    }
  };

  return (
    <>
      {!analyticsConfig.brandUsesAnalytics && (
        <div className={analyticsMessageContainer}>
          <div className={adminSubText}>This brand is not configured to use analytics.</div>
        </div>
      )}
      {analyticsConfig.brandUsesAnalytics && loading && (
        <div className={analyticsMessageContainer}>
          <div className={adminSubText}>LOADING....</div>
        </div>
      )}
      {analyticsConfig.brandUsesAnalytics && !loading && errorMessage && (
        <div className={analyticsMessageContainer}>
          <div className={adminText}>An error has occurred:</div>
          <div className={adminSubText}>{errorMessage}</div>
        </div>
      )}
      {analyticsConfig.brandUsesAnalytics && !loading && !errorMessage && (
        <iframe width="100%" height="100%" src={`https://qi.trillabit.com/?t=${accessToken}`} />
      )}
    </>
  );
};
