import { ErrorDisplay } from '@components/ErrorDisplay/ErrorDisplay';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { useSeries, useChildSeasons, useVideosOfSeries } from '@lib/video-query/useVideoData';
import React, { useContext, useState, useEffect } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import IndexLayout from '@layouts/index';
import Page from '@components/Page';
import { BrandUtil, DatabaseSeries, DatabaseSeason, DatabaseVideo } from '@sharedLib/index';
import { isBrowser } from '@lib/build';
import { Helmet } from 'react-helmet';
import { BreadCrumbs } from '@components/BreadCrumbs';
import { useLocation } from '@reach/router';
import { box } from './styles.module.scss';
import { SeriesHeader } from './SeriesHeader';
import { SeasonList } from './SeasonList';

const DEBUG = false;

export interface SeriesPageTemplateProps {
  pageContext: {
    collectionId?: string;
    matchPath?: string;
  };
}

const SeriesPageTemplatePage = (props: SeriesPageTemplateProps) => {
  const nav = useContext(NavStateContext);
  const brandInfo = BrandUtil.getSiteInfo();
  const api = usePvepApi();
  const userState = api.state;
  const [loading, setLoading] = useState(false);
  const [seriesData, setSeriesData] = useState<DatabaseSeries | undefined>(undefined);
  const [seasonData, setSeasonData] = useState<DatabaseSeason[]>([]);
  const [videoData, setVideoData] = useState<DatabaseVideo[]>([]);

  // determine collection id based on static page context or dynamically from the path
  let collectionId = props.pageContext.collectionId || '';
  const location = useLocation();
  if (props.pageContext.matchPath) {
    const pathParts = location.pathname.split('/').reverse();
    for (const part of pathParts) {
      if (part) {
        collectionId = part;
        break;
      }
    }
  }

  useEffect(() => {
    if (props.pageContext.matchPath && !userState.useRuntimeData) {
      nav.setRoot({ path: '/not-found' });
    }
  }, []);

  useEffect(() => {
    checkUserWatchingSeries(collectionId);
    if (userState.useRuntimeData) {
      loadSeries(collectionId);
    }
  }, [collectionId]);

  /// check/update watching location stored
  const checkUserWatchingSeries = async (seriesId: string) => {
    if (userState.watching.seriesId !== seriesId) {
      await api.updateUserWatching({
        categoryId: '',
        seriesId,
        seasonId: '',
        videoId: '',
      });
    }
  };

  const loadSeries = async (seriesId: string) => {
    setLoading(true);
    const seriesResp = await api.getAllDatabaseSeriesInfo(seriesId);
    if (seriesResp.data?.getAllDatabaseSeriesInfo.success && seriesResp.data.getAllDatabaseSeriesInfo.series) {
      const seriesDataDb = seriesResp.data.getAllDatabaseSeriesInfo.series;
      const seasonsDataDb = seriesResp.data.getAllDatabaseSeriesInfo.seasons || [];
      const videosDataDb = seriesResp.data.getAllDatabaseSeriesInfo.videos || [];
      setSeriesData(seriesDataDb);
      setSeasonData(seasonsDataDb);
      setVideoData(videosDataDb);
    }
    setLoading(false);
  };

  const seasons = useChildSeasons(collectionId);
  const videoCollection = useSeries(collectionId);
  const videosInSeries = useVideosOfSeries(collectionId);

  if ((!collectionId || !videoCollection) && !userState.useRuntimeData) {
    ClientLogger.error(`SeriesPageTemplatePage`, 'no data', props);
    return (
      <ErrorDisplay
        errorData={{ errors: [`Missing collectionId or invalid.  data=${JSON.stringify({ pageContext: props.pageContext })}`] }}
      />
    );
  }

  const navigateToVideo = (videoId: string, title: string) => {
    ClientLogger.debug('SeriesPageTemplatePage', 'Navigate To', DEBUG, videoId);
    if (isBrowser) {
      const { pageData } = nav.navState;
      let highestVideo: number | undefined;
      for (let i = 0; i < pageData.length; i++) {
        // This logic should be moved to nav (maybe there's a function that works)
        const page = pageData[i];
        if (page.path.substr(0, 7) === '/video/') {
          highestVideo = i;
          break;
        }
      }
      if (highestVideo === undefined) {
        ClientLogger.debug('SeriesPageTemplatePage', 'Pushing', DEBUG, { highestVideo, pageData });
        nav.push({ path: `/video/${videoId}/`, title });
      } else {
        ClientLogger.debug('SeriesPageTemplatePage', 'Replacing', DEBUG, { highestVideo, pageData });
        nav.replace({ path: `/video/${videoId}/`, title }, highestVideo);
      }
    }
  };

  ClientLogger.debug('SeriesPageTemplatePage', 'render', DEBUG, { videoCollection, seasons });

  return (
    <IndexLayout needsLogin={brandInfo.videoNeedsRegistration} needsSubscription={brandInfo.videoNeedsRegistration} noVideo>
      {!userState.useRuntimeData && videoCollection && (
        <>
          <Helmet>
            <title>{videoCollection.title}</title>
          </Helmet>
          <Page>
            <BreadCrumbs seriesCrumb={{ label: videoCollection.title }} />
            <div className={box}>
              <SeriesHeader
                title={videoCollection.title}
                description={videoCollection.description}
                imageUrl={videoCollection.thumbnailUrl || ''}
              />
              <SeasonList
                videos={videosInSeries}
                seasons={seasons}
                navigateToVideo={async (videoId, title, seasonId) => {
                  await api.updateUserWatching({ ...userState.watching, videoId, seasonId });
                  navigateToVideo(videoId, title);
                }}
              />
            </div>
          </Page>
        </>
      )}

      {loading && userState.useRuntimeData && <div>Loading...</div>}
      {!loading && userState.useRuntimeData && (
        <>
          {seriesData && (
            <Helmet>
              <title>{seriesData.title}</title>
            </Helmet>
          )}

          <Page>
            {seriesData && (
              <>
                <BreadCrumbs seriesCrumb={{ label: seriesData.title }} />
                <div className={box}>
                  <SeriesHeader title={seriesData.title} description={seriesData.description} imageUrl={seriesData.thumbnailUrl || ''} />
                  <SeasonList
                    videos={videoData}
                    seasons={seasonData}
                    navigateToVideo={async (videoId, title, seasonId) => {
                      await api.updateUserWatching({ ...userState.watching, videoId, seasonId });
                      navigateToVideo(videoId, title);
                    }}
                  />
                </div>
              </>
            )}
          </Page>
        </>
      )}
    </IndexLayout>
  );
};

export default SeriesPageTemplatePage;
