import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import { isBrowser } from '@src/lib/build';
import { ClientLogger } from '@src/lib/ClientLogger';
import { NavStateContext } from '@src/lib/navContext';
import { Video } from '@sharedLib/index';
import { usePvepApi } from '@apiClient/usePvepApi';
import { FormContainer } from '@src/components/Forms';
import { Button, ButtonStyles, SubmitButton } from '@src/components/Buttons';
import { VideoList } from '@src/components/VideoList';
import { useVideoData, getCategoryItems, useHomePageCollections } from '@lib/video-query/useVideoData';

import {
  featuredSlider,
  accountInfoForm,
  infoWrapper,
  headerText,
  currentPlan,
  currentPlanName,
  currentPlanDescription,
  cancelSubscriptionInfo,
  cancelSubscriptionTitle,
  cancelSubscriptionDescription,
  thankYouInfo,
  thankYouTitle,
  thankYouDescription,
  tabContainer,
  activeTabContentContainer,
  inActiveTabContentContainer,
  submitButtonWrapper,
  submitButton,
} from '../styles.module.scss';
import { useMyAccountResource } from '@src/pageComponents/MyAccount/useMyAccountResources';
import { useStaticQuery, graphql } from 'gatsby';

const initialValues = {};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FormData {}

interface Data {
  allVideoSubscriptionsJson: { nodes: { id: string; name: string; price: { monthly: { formatted: string } } }[] };
}
const Membership = () => {
  const nav = useContext(NavStateContext);
  const allVideoData = useVideoData();

  const [subscriptionStatus, setSubscriptionStatus] = useState<boolean>();
  const [subscriptionTab, setSubscriptionTab] = useState<string>('subscriptionPlan');

  const data: Data = useStaticQuery(
    graphql`
      query subscriptionQuery {
        allVideoSubscriptionsJson {
          nodes {
            id
            name
            price {
              monthly {
                formatted
              }
            }
          }
        }
      }
    `
  );

  const api = usePvepApi();
  const content = useMyAccountResource();

  useEffect(() => {
    api.checkSubscribed().then(resp => {
      resp ? setSubscriptionStatus(true) : setSubscriptionStatus(false);
    });
  }, [subscriptionStatus]);

  const subscriptionTier = data.allVideoSubscriptionsJson.nodes.length ? data.allVideoSubscriptionsJson.nodes[0] : null; // To Do - how need to figure out products/brand relations
  const collections = useHomePageCollections();
  const navData = nav.navState.pageData;

  const navigateToVideo = (video: Video) => {
    if (isBrowser) {
      if (navData[navData.length - 1].path.split('/').includes('video')) {
        nav.replace({ path: `/video/${video.id}/`, title: video.title });
      } else {
        nav.push({ path: `/video/${video.id}/`, title: video.title });
      }
    }
  };

  const onClickCollection = (collectionId: string, title: string) => {
    ClientLogger.debug('HomeComponent', 'onClickCollection', DEBUG, { collectionId, title });
    if (isBrowser) {
      if (navData[navData.length - 1].path.split('/').includes('series')) {
        nav.replace({ path: `/series/${collectionId}/`, title });
      } else {
        nav.push({ path: `/series/${collectionId}/`, title });
      }
    }
  };

  return (
    <Formik
      onSubmit={async (_values: FormData) => {
        const resp: any = await api.unsubscribeUser(api.state.email);
        if (resp.data.unsubscribe.success) {
          setSubscriptionStatus(false);
          setSubscriptionTab('thankYou');
        }
      }}
      initialValues={initialValues}
    >
      {props => {
        return (
          <>
            <div
              className={classNames(
                subscriptionTab === 'subscriptionPlan' ? activeTabContentContainer : inActiveTabContentContainer,
                'column is-half',
                tabContainer
              )}
            >
              <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
                <div className={classNames(infoWrapper)}>
                  <h1 className={headerText}>{content.membershipTabName}</h1>
                </div>
                <div className={currentPlan}>
                  <h2 className={currentPlanName}>{subscriptionTier?.name}</h2>
                  <p className={currentPlanDescription}>{content.membershipTabContent.mainPage.currentPlanDescription}</p>
                </div>
                {subscriptionStatus && (
                  <div
                    className={classNames(submitButton)}
                    onClick={() => {
                      setSubscriptionTab('subscriptionCancel');
                    }}
                  >
                    <Button style={ButtonStyles.Transparent}>{content.membershipTabContent.mainPage.membershipCTA.unsubscribe}</Button>
                  </div>
                )}

                {!subscriptionStatus && (
                  <div
                    className={classNames(submitButton)}
                    onClick={() => {
                      nav.setRoot({ path: '/subscribe' });
                    }}
                  >
                    <Button>{content.membershipTabContent.mainPage.membershipCTA.subscribe}</Button>
                  </div>
                )}
              </FormContainer>
            </div>
            <div
              className={classNames(
                subscriptionTab === 'subscriptionCancel' ? activeTabContentContainer : inActiveTabContentContainer,
                'column is-full',
                tabContainer
              )}
            >
              <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
                <div className={classNames(infoWrapper)}>
                  <h1 className={headerText}>{content.membershipTabContent.cancelMembershipPage.cancelMembershipPageHeader}</h1>
                </div>
                <div className={cancelSubscriptionInfo}>
                  <h2 className={cancelSubscriptionTitle}>
                    {content.membershipTabContent.cancelMembershipPage.cancelMembershipPageSubHeader}
                  </h2>
                  <p className={cancelSubscriptionDescription}>
                    {content.membershipTabContent.cancelMembershipPage.cancelMembershipPageMessage}
                  </p>
                </div>
                <div className={featuredSlider}>
                  {collections.slice(0, 1).map(node => (
                    <div key={node.id}>
                      <VideoList
                        items={getCategoryItems(node, allVideoData.series, allVideoData.videos)}
                        onClick={() => navigateToVideo(node as any)}
                        onClickCollection={() => {
                          onClickCollection(node.id, node.title);
                        }}
                        name={node.title}
                        horizontal
                        fullwidth
                        small
                      />
                    </div>
                  ))}
                </div>
                <div className={submitButtonWrapper}>
                  <div
                    className={classNames(submitButton)}
                    onClick={() => {
                      setSubscriptionTab('subscriptionPlan');
                    }}
                  >
                    <Button>{content.membershipTabContent.cancelMembershipPage.cancelMembershipCTA.cancelUnsubscribe}</Button>
                  </div>
                  <div className={classNames(submitButton)}>
                    <SubmitButton style={ButtonStyles.Transparent}>
                      {content.membershipTabContent.cancelMembershipPage.cancelMembershipCTA.confirmUnsubscribe}
                    </SubmitButton>
                  </div>
                </div>
              </FormContainer>
            </div>
            <div
              className={classNames(
                subscriptionTab === 'thankYou' ? activeTabContentContainer : inActiveTabContentContainer,
                'column is-three-fifths',
                tabContainer
              )}
            >
              <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
                <div className={classNames(infoWrapper)}>
                  <h1 className={headerText}>{content.membershipTabContent.thankYouPage.thankYouPageHeader}</h1>
                </div>
                <div className={thankYouInfo}>
                  <h2 className={thankYouTitle}>{content.membershipTabContent.thankYouPage.thankYouPageSubHeader}</h2>
                  <p className={thankYouDescription}>{content.membershipTabContent.thankYouPage.thankYouPageMessage}</p>
                </div>
                <div className={submitButtonWrapper}>
                  <div
                    className={classNames(submitButton)}
                    onClick={() => {
                      setSubscriptionTab('subscriptionPlan');
                    }}
                  >
                    <Button>{content.membershipTabContent.thankYouPage.backToMainPageCTA}</Button>
                  </div>
                </div>
              </FormContainer>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default Membership;
