import React, { useState } from 'react';
import { DatabaseSeason, DatabaseVideo, ascendingOrder } from '@sharedLib/index';
import Dropdown from '@components/Forms/Dropdown';
import { isMobilePortrait } from '@lib/responsive';
import classNames from 'classnames';
import { seriesSeasonsList, tabDropdown, tabsHeader, tabLink, isActive, videoListContainer, videoList } from './styles.module.scss';
import { SeriesVideoCard } from './SeriesVideoCard';

const DEBUG = false;

interface Props {
  seasons: DatabaseSeason[];
  videos: DatabaseVideo[];
  navigateToVideo: (videoId: string, title: string, seasonId: string) => void;
}

export const SeasonList = ({ seasons, videos, navigateToVideo }: Props) => {
  const [selectedSeason, setSelectedSeason] = useState<DatabaseSeason | undefined>(seasons.length > 0 ? seasons[0] : undefined);
  const useMobilePortrait = isMobilePortrait();

  return (
    <div className={seriesSeasonsList}>
      {useMobilePortrait && (
        <Dropdown
          className={tabDropdown}
          values={seasons.map(season => {
            return { text: season.title, value: season.id };
          })}
          selected={selectedSeason?.id || ''}
          onChange={value => {
            const season = seasons.find(sea => sea.id === value);
            setSelectedSeason(season);
          }}
        />
      )}
      {!useMobilePortrait && (
        <ul className={tabsHeader}>
          {seasons.map(season => (
            <div
              key={season.id}
              onClick={() => setSelectedSeason(season)}
              className={classNames(tabLink, season.id === selectedSeason?.id ? isActive : '')}
            >
              {season.title}
            </div>
          ))}
        </ul>
      )}
      {selectedSeason && (
        <div className={classNames(videoListContainer, videoList)}>
          {selectedSeason.items
            .sort((a, b) => ascendingOrder(a.rank, b.rank))
            .map((item, i) => {
              const video = videos.find(vid => vid.id === item.videoId);

              if (!video) {
                return <></>;
              }
              return (
                <SeriesVideoCard
                  video={video}
                  key={i}
                  onClick={vid => navigateToVideo(vid.id, vid.label || vid.title, selectedSeason.id)}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};
