import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterSignUp } from '@src/pageComponents/SignUp/model';
import { graphql, useStaticQuery } from 'gatsby';

const DEBUG = false;

export function useSignUpResource(): IFrontMatterSignUp {
  try {
    // alternatively, we could import the md file
    const data = useStaticQuery(graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "sign-up" } }) {
          id
          frontmatter {
            mainPage {
              header
              signUpCTA {
                emailSignUp
                googleSignUp
                facebookSignUp
              }
              member {
                message
                loginLink
              }
            }
            signUpEmailPage {
              signUpEmailHeader
              checkListOne
              checkListTwo {
                start
                linkOne
                middle
                linkTwo
              }
              signUpEmailCTA
              errors {
                emptyEmailField
                emptyTermsField
                invalidEmailError
                emptyLastNameField
                emptyFirstNameField
                emptyPasswordField
                minLimitPasswordField
                emailAlreadyExistError
              }
            }
            promoCodePage {
              promoCodeHeader
              pricingCard {
                subscriptionPlanName
                subscriptionPlanPrice
                subscriptionPlanTitle
                subscriptionPlanDetails
              }
              promoCodeCTA
              errors {
                emptyPromoCode
              }
            }
            thankYouPage {
              thankYouPageTitle
              thankYouPageDescription
              thankYouCTA
            }
          }
        }
      }
    `);
    const resource = data.markdownRemark.frontmatter;
    DEBUG && ClientLogger.debug('useSignUpResource', `render ${JSON.stringify({ resource }, null, 2)}`);

    if (!resource) {
      throw new Error(`useSignUpResource - cannot find 'resource' debug: ${JSON.stringify({ resource }, null, 2)}`);
    }

    return { ...resource };
  } catch (e) {
    // Running in CMS Preview
    ClientLogger.error('useSignUpResource', 'error caught', e);
    return {} as any;
  }
}
