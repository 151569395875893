import { ClientLogger } from '@lib/ClientLogger';
import { ascendingOrder, AdWithProduct } from '@sharedLib/index';

const DEBUG = false;

// Todo: Move individual functions to a class as per coding style guidelines
export function collectAdsByRank(ads: AdWithProduct[]) {
  return ads.reduce((collectedAds: Map<string, AdWithProduct[]>, ad) => {
    const rank = ad.rank.toString();

    if (!collectedAds.has(rank)) {
      collectedAds.set(rank, []);
    }

    const entry = collectedAds.get(rank);

    if (entry) {
      entry.push(ad);
    }

    return collectedAds;
  }, new Map());
}

export function sortRankedAds(rankedAds: Map<string, AdWithProduct[]>) {
  const newRankedAds = new Map<string, AdWithProduct[]>();
  rankedAds.forEach((value, entry) => {
    const ads = value;
    const rank = entry;
    const sortedAds = ads.sort((prev, cur) => ascendingOrder(prev.startTimeIndex, cur.startTimeIndex));
    newRankedAds.set(rank, sortedAds);
  });

  return newRankedAds;
}

export function findLatestAds(sortedAds: Map<string, AdWithProduct[]>, currentTime: number) {
  const latestAds: Map<string, string> = new Map();

  sortedAds.forEach((entry, key) => {
    const ads: AdWithProduct[] = entry;
    const rank: string = key;

    ads.forEach(ad => {
      if (ad.startTimeIndex <= currentTime) {
        latestAds.set(rank, ad.id);
      }
    });
  });

  return latestAds;
}

export function findAdsToDisplay(ads: AdWithProduct[], currentTime: number) {
  const collectedAds = collectAdsByRank(ads);
  const sortedAds = sortRankedAds(collectedAds);

  return findLatestAds(sortedAds, currentTime);
}

export function setAdDisplayTypes(ads: AdWithProduct[], currentTime: number, adType = 'banner'): AdWithProduct[] {
  const filteredAds = ads.filter(a => {
    return !adType || a.type === adType;
  });
  const currentAds = findAdsToDisplay(filteredAds, currentTime);
  const displayAds = filteredAds.map(ad => {
    const rank = ad.rank.toString();
    const newAd = { ...ad };
    if (currentAds.get(rank) === ad.id) {
      newAd.display = 'block';
      return newAd;
    }
    newAd.display = 'none';
    return newAd;
  });
  DEBUG && ClientLogger.debug('setAdDisplayTypes', '', { filteredAds, displayAds, ads });

  return displayAds;
}

export function refreshAds(adPlacements: AdWithProduct[] | undefined, currentTime: number): AdWithProduct[] | null {
  if (adPlacements) {
    const displayAds = setAdDisplayTypes(adPlacements, currentTime);
    return displayAds.sort((prev, cur) => ascendingOrder(prev.rank, cur.rank));
  }
  return null;
}

export function getFeaturedProducts(adPlacements: AdWithProduct[] | undefined, adType = 'featured'): AdWithProduct[] | null {
  if (adPlacements) {
    const displayAds = adPlacements.filter(ad => ad.type === adType);
    return displayAds.sort((prev, cur) => ascendingOrder(prev.rank, cur.rank));
  }
  return null;
}

export function getStaticBannerProducts(adPlacements: AdWithProduct[] | undefined, adType = 'banner_static'): AdWithProduct[] | null {
  if (adPlacements) {
    const displayAds = adPlacements.filter(ad => ad.type === adType);
    return displayAds.sort((prev, cur) => ascendingOrder(prev.rank, cur.rank));
  }
  return null;
}

export function formatCurrency(amount: number | string | undefined): string {
  if (typeof amount === 'undefined' || amount === null) {
    return '';
  }
  if (amount === 0) {
    return 'FREE';
  }
  // return Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(
  return `$${(typeof amount === 'string' ? Number.parseFloat(amount) : amount).toFixed(2)}`;
}
