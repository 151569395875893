export interface Coords {
  size: {
    height: string | number;
    width: string | number;
  };
  position: {
    x: number;
    y: number;
  };
}

export class CoordUtils {
  public static toNumber(val: string | number): number {
    return typeof val === 'string' ? Number(val.replace('px', '')) : Number(val);
  }
}
