import React from 'react';

// Input div ref and returns boolean regarding vertical overflow state
export const isDivOverflowing = (refElement: React.MutableRefObject<HTMLDivElement | null | undefined>): boolean => {
  if (!refElement.current) {
    return false;
  }

  return refElement.current.clientHeight < refElement.current.scrollHeight;
};
