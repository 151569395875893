/* eslint-disable camelcase */
import { User } from '@apiClient/gql-types';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import React, { useState } from 'react';

const DEBUG = false;

/* Export a component to provide the context to its children */
export const MeStateContext = React.createContext({
  me: (undefined as unknown) as User | undefined,
  setMe: (_: User) => {
    ClientLogger.error('MeStateContext', ' setMe ignored');
  },
  readMe: async (): Promise<User | undefined> => {
    return undefined;
  },
});

export const MeProvider = ({ children }: any) => {
  const [me, setMe] = useState<User | undefined>(undefined);
  const api = usePvepApi();
  async function readMe(): Promise<User | undefined> {
    const me = await api.getMe();
    if (me.data?.me) {
      setMe(me.data.me);
      return me.data.me;
    }
    return undefined;
  }
  ClientLogger.debug('NavStateProvider', 'called', DEBUG, { me });
  return <MeStateContext.Provider value={{ readMe, me, setMe }}>{children}</MeStateContext.Provider>;
};
