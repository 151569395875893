import React, { useState } from 'react';
import { branchTable, tableLink } from './styles.module.scss';

interface Props {
  rows: [{ title: string; url: string }];
  // eslint-disable-next-line @typescript-eslint/ban-types
  columns: {}[];
}
export const Table = ({ rows, columns }: Props) => {
  return (
    <table className={`table ${branchTable}`}>
      <thead>
        <tr>
          <th>
            <abbr title="develop">Brands</abbr>
          </th>
          {['serverBuildDate', 'buildId', 'commit'].map(key => (
            <th>
              <abbr key={key} title={key}>
                {key}
              </abbr>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <>
          {rows.map(({ title, url }, index) => (
            <tr>
              <td>
                <a className={tableLink} href={url}>
                  {title}
                </a>
              </td>
              {Object.values(columns[index] || false).map((val: any) => (
                <td key={val}>{val || 'N/A'}</td>
              ))}
            </tr>
          ))}
        </>
      </tbody>
    </table>
  );
};
