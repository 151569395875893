import { useProductData } from '@lib/shopify/useProductData';
import { ShopifyProduct } from '@sharedLib/product';
import React, { useState } from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import { Formik } from 'formik';
import { ErrorText, usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import { SubmitButton } from '@components/Buttons';
import { FormContainer, TextField, Checkbox } from '@components/Forms';
import { AlreadyMemberLogIn } from '@src/pageComponents/SignUp/AlreadyMemberLogIn';
import queryString from 'query-string';
import { BrandUtil } from '@sharedLib/util/Brand';
import { useSignUpResource } from '@src/pageComponents/SignUp/useSignUpResources';
import {
  formHeader,
  infoWrapper,
  emailSignUpForm,
  checkboxLabels,
  link,
  checkboxes,
  textFieldWrapped,
  formHeaderATExtra,
  emailSignUpFormATExtra,
} from './styles.module.scss';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  rememberMe: false,
};

interface SignUpFormErrors {
  emptyFirstNameField: string;
  emptyLastNameField: string;
  emptyEmailField: string;
  emptyPasswordField: string;
  minLimitPasswordField: string;
  invalidEmailError: string;
  emailAlreadyExistError: string;
}

interface Props {
  successCallback: () => void;
}

const validationSchema = (error: SignUpFormErrors) => {
  return yup.object().shape({
    firstname: yup.string().required(error.emptyFirstNameField),
    lastname: yup.string().required(error.emptyLastNameField),
    email: yup
      .string()
      .email(error.invalidEmailError)
      .required(error.emptyEmailField),
    password: yup
      .string()
      .required(error.emptyPasswordField)
      .min(8, error.minLimitPasswordField),
  });
};

interface FormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  rememberMe: boolean;
}

const DEBUG = false;
const REMEMBER_ME = false;

export const EmailSignUp = ({ successCallback }: Props) => {
  const brand = BrandUtil.getBrandInfoFromBranch().brandId;

  const [errors, setErrors] = useState<string[] | undefined | null>([]);
  const api = usePvepApi();
  const productData = useProductData();
  const content = useSignUpResource();

  return (
    <Formik
      onSubmit={async (values: FormData, { setSubmitting }) => {
        setSubmitting(true);
        ClientLogger.debug('EmailSignUp.onSubmit', '', DEBUG, values);
        const resp = await api.registerWithEmail(values.firstname, values.lastname, values.email, values.password);
        const { data } = resp;
        if (data) {
          if (data.signup.commonResponse.success) {
            if (queryString.parse(location.search) && queryString.parse(location.search).productId) {
              // when we implement variants in these tickets, this will need to be updated
              const shopifyId = queryString.parse(location.search).productId;
              const product = productData.getProduct(shopifyId as any) as ShopifyProduct;
              const info = product?.variants[0];

              const cartResp = await api.cartServices.addCartItem({
                email: values.email,
                description: product.description,
                productId: product.id,
                title: product.title,
                quantity: 1,
                price: String(product.maxVariantPrice),
                imageUrl: product.imageOriginalSrc,
                variantId: info.id,
                variantName: info.title,
              });

              ClientLogger.debug('ThankYouPage.addCartItemResp', 'resp =', DEBUG, cartResp);
            }
            successCallback();
          } else {
            setErrors(ErrorText(data.signup.commonResponse.errorMessages));
          }
        } else {
          setErrors([JSON.stringify(resp.errors)]);
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema(content.signUpEmailPage.errors)}
    >
      {props => {
        return (
          <FormContainer
            onSubmit={props.handleSubmit}
            className={classNames(emailSignUpForm, brand === 'at' ? emailSignUpFormATExtra : '')}
            errorMessage={errors && errors.length ? errors : null}
          >
            <h1 className={classNames(formHeader, brand === 'at' ? formHeaderATExtra : '')}>{content.signUpEmailPage.signUpEmailHeader}</h1>
            <div className={infoWrapper}>
              <TextField
                dataCY="signupFirstName"
                headerText="First Name"
                fieldType="name"
                fieldName="firstname"
                className={textFieldWrapped}
              />
              <TextField
                dataCY="signupLastName"
                headerText="Last Name"
                fieldType="name"
                fieldName="lastname"
                className={textFieldWrapped}
              />
            </div>
            <TextField dataCY="signupEmail" headerText="Email" fieldType="email" fieldName="email" />
            <TextField dataCY="signupPassword" headerText="Create a Password" fieldType="password" fieldName="password" />
            <div className={checkboxes}>
              {REMEMBER_ME ? (
                <Checkbox fieldName="rememberMe" checked={props.values.rememberMe} onChange={props.handleChange}>
                  <span className={checkboxLabels}>{content.signUpEmailPage.checkListOne}</span>
                </Checkbox>
              ) : (
                ''
              )}
            </div>
            <SubmitButton disabled={!(props.isValid && props.dirty)}>{content.signUpEmailPage.signUpEmailCTA}</SubmitButton>
            <AlreadyMemberLogIn content={content.mainPage.member.message} link="/login" linkText={content.mainPage.member.loginLink} />
          </FormContainer>
        );
      }}
    </Formik>
  );
};
