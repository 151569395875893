import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterContactUs } from '@pageComponents/ContactUs/model';
import { graphql, useStaticQuery } from 'gatsby';
import { Images } from '@src/components/Img/useFluidImage';
import { usePageImage } from '@src/lib/usePageImage';

const DEBUG = false;

interface IFrontMatterContactUsWithImages extends IFrontMatterContactUs {
  thankYouIconImage: Images;
}

export function useContactUsResource(): IFrontMatterContactUsWithImages {
  // alternatively, we could import the md file
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { slug: { eq: "contact-us" } }) {
        id
        frontmatter {
          mainHeader
          subHeader
          supportMessage
          supportEmail
          cta
          thankYouIcon
          thankYouHeader
          thankYouSubHeader
          additionalMessages {
            additionalMessage
            additionalEmail
          }
          errors {
            emptyNameField
            invalidNameError
            emptyMessageField
            emptyEmailField
            invalidEmailError
          }
        }
      }
    }
  `);
  const resource = data.markdownRemark.frontmatter;
  DEBUG && ClientLogger.debug('useContactUsResource', `render ${JSON.stringify({ resource }, null, 2)}`);

  const thankYouIconImage = usePageImage(resource.thankYouIcon);

  if (!resource) {
    ClientLogger.error(
      'useContactUsResource',
      `useContactUsResource - cannot find 'layout' debug: ${JSON.stringify({ resource }, null, 2)}`
    );
    return { thankYouIconImage: {} } as any;
  }

  return { ...resource, thankYouIconImage };
}
