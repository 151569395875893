// extracted by mini-css-extract-plugin
export var container = "styles-module--container--X6dJd";
export var infoWrapper = "styles-module--infoWrapper--1mGky";
export var hideTiny = "styles-module--hideTiny--2b4f0";
export var info = "styles-module--info--WJ-R-";
export var shadow = "styles-module--shadow--2Ad_k";
export var imageContainer = "styles-module--imageContainer--tW4qL";
export var imageBoth = "styles-module--imageBoth--2xmZb";
export var image = "styles-module--image--2Dbvu";
export var imageGradient = "styles-module--imageGradient--1P3Nf";
export var button = "styles-module--button--3s__N";
export var innerButton = "styles-module--innerButton--1vb1x";
export var buttonText = "styles-module--buttonText--3uERy";
export var header = "styles-module--header--ZAX8-";
export var subHeader = "styles-module--subHeader--iKmEL";
export var description = "styles-module--description--2q6pL";