import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import classNames from 'classnames';
import { usePvepApi } from '@apiClient/usePvepApi';
import { ClientLogger } from '@lib/ClientLogger';
import { FormContainer, TextField } from '@src/components/Forms';
import { Button, ButtonStyles, SubmitButton } from '@src/components/Buttons';
import { useMyAccountResource } from '@src/pageComponents/MyAccount/useMyAccountResources';
import { accountInfoForm } from './styles.module.scss';
import {
  infoWrapper,
  subHeaderText,
  editButtonWrapper,
  editButton,
  hideLinks,
  info,
  textFieldWrapped,
  tabContainer,
  submitButtonWrapper,
  submitButton,
} from '../styles.module.scss';

const DEBUG = false;

interface ProfileDetailsErrors {
  emptyFirstNameField: string;
  emptyLastNameField: string;
}
const validationSchema = (error: ProfileDetailsErrors) => {
  return yup.object().shape({
    firstname: yup.string().required(error.emptyFirstNameField),
    lastname: yup.string().required(error.emptyLastNameField),
  });
};

const initialValues = {
  firstname: '',
  lastname: '',
};

interface FormData {
  firstname: string;
  lastname: string;
}

const Name = () => {
  const api = usePvepApi();
  const user = api.state;
  const content = useMyAccountResource();

  initialValues.firstname = user.firstName;
  initialValues.lastname = user.lastName;

  const [updateUserDetails, setUpdateUserDetails] = useState<boolean>();

  return (
    <Formik
      onSubmit={async (values: FormData, { setSubmitting }) => {
        setSubmitting(false);

        if (updateUserDetails) {
          ClientLogger.debug('UserProfile.updateUserInfo', `fname = ${values.firstname} & lname = ${values.lastname}`, DEBUG);
          const resp = await api.updateUserIdentity(values.firstname, values.lastname, user.email);
          ClientLogger.debug('UserProfile.updateUserInfoResp', `resp = ${JSON.stringify(resp)}`, DEBUG);
          if (resp.data && resp.data.updateUserInfo.success) {
            setUpdateUserDetails(false);
            ClientLogger.debug('UserProfile.updateUserInfo', `first name and last name updated`, DEBUG);
          }
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema(content.profileTabErrors)}
    >
      {props => {
        return (
          <div className={classNames('column is-half', tabContainer)}>
            <FormContainer onSubmit={props.handleSubmit} className={accountInfoForm}>
              <div className={classNames(infoWrapper)}>
                <h1 className={subHeaderText}>Name</h1>
                <div className={updateUserDetails ? hideLinks : editButtonWrapper}>
                  <div
                    data-cy="editName"
                    onClick={() => {
                      setUpdateUserDetails(true);
                    }}
                    className={editButton}
                  >
                    Edit
                  </div>
                </div>
              </div>
              <div className={infoWrapper}>
                <TextField
                  dataCY="editFirstName"
                  placeholder="First Name"
                  fieldType="name"
                  fieldName="firstname"
                  className={classNames(info, textFieldWrapped)}
                  disabled={!updateUserDetails}
                />
                <TextField
                  dataCY="editLastName"
                  placeholder="Last Name"
                  fieldType="name"
                  fieldName="lastname"
                  className={classNames(info, textFieldWrapped)}
                  disabled={!updateUserDetails}
                />
              </div>
              <div className={submitButtonWrapper}>
                <div
                  className={updateUserDetails ? submitButton : hideLinks}
                  onClick={() => {
                    props.resetForm();
                    setUpdateUserDetails(false);
                  }}
                >
                  <Button style={ButtonStyles.Greyscale}>Cancel</Button>
                </div>
                <div data-cy="submitNameDiv" className={updateUserDetails ? submitButton : hideLinks}>
                  <SubmitButton disabled={!props.isValid}>Save</SubmitButton>
                </div>
              </div>
            </FormContainer>
          </div>
        );
      }}
    </Formik>
  );
};

export default Name;
