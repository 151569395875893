import { ErrorCode } from '@apiClient/gql-types';
import { usePvepApi } from '@apiClient/usePvepApi';
import { useAnalyticsCapture } from '@lib/AnalyticsCapture';
import { AuthClientUtil } from '@lib/AuthClientUtil';
import { isBrowser } from '@lib/build';
import { ClientLogger } from '@lib/ClientLogger';
import { Coords } from '@lib/Coords';
import { VideoSource, DatabaseVideo, AdWithProduct, AdStrategy } from '@sharedLib/index';
import React, { createContext, RefObject, useContext, useEffect, useReducer, useState } from 'react';

const DEBUG = false;

/* Video Player Boot Sequence
  Internal Load                      Step         External         Show
  Step                               Id           State           Player
  --------------------------------------------------------------------
  Check VHS library is loaded        LOAD_VHX     LOADING_HIDE      N
  Dispose of previous player         DISPOSE      LOADING_HIDE      N
  GetAuthCode                        GET_AUTH     LOADING_HIDE      N
  Render IFRAME                      FIRST_RENDER LOADING_SHOW      Y
  Wait for player ready              FIRST_RENDER LOADING_SHOW      Y
  Install event listeners            SETUP        LOADING_SHOW      Y
  Run Auto Play                      AUTO_PLAY    LOADING_SHOW      Y
  Ready to Play                      READY        READY             Y

*/

export enum InternalLoadStep {
  NOT_STARTED,
  LOAD_VHX,
  DISPOSE,
  GET_AUTH,
  FIRST_RENDER,
  SETUP,
  AUTO_PLAY,
  READY,
}

export enum PlayerReady {
  LOADING_HIDE,
  LOADING_SHOW,
  READY,
  UNKNOWN,
}

export enum VideoPlayerSize {
  NOT_SHOWN,
  SMALL_FLOAT,
  MEDIUM_EMBEDDED,
  LARGE_FULLSCREEN,
  SMALL_EMBEDDED,
}

export interface VideoLoadParams {
  video: DatabaseVideo;
  adPlacements?: AdWithProduct[];
  currentAdStrategy?: AdStrategy;
  adStrategies?: AdStrategy[];
}

/* Action Types */
type VideoPlayerAction =
  | { type: 'SET_SIZE'; newVideoSize: VideoPlayerSize; isManual: boolean }
  | { type: 'LOAD'; loadParams: VideoLoadParams; isLoggedIn: boolean }
  | { type: 'SET_COORDS'; coords: Coords }
  | { type: 'PLAY' }
  | { type: 'PAUSE' }
  | { type: 'SET_EL_REF'; elRef: RefObject<Element> }
  | { type: 'SET_PLAYER'; player: any }
  | { type: 'SHOW_PLAY_NEXT'; show: boolean }
  | { type: 'SET_LOADING_STATE'; internal: InternalLoadStep }
  | { type: 'SET_TOKEN'; token: string | undefined }
  | { type: 'PPV_NOT_PURCHASED'; purchaseNecessary: boolean; ppvInfo: PPVInfo }
  | { type: 'PPV_PURCHASE_START' }
  | { type: 'USER_NOT_MEMBER'; userNotMember: boolean }
  | { type: 'SET_ORIENTATION'; newOrientation: OrientationState }
  | { type: 'SET_EMBED_CART'; value: boolean }
  | { type: 'SET_PLACEHOLDER_POSITION'; value: number };

interface PPVInfo {
  ppvProductId: string;
  ppvProductTitle: string;
  ppvVariantId: string | null;
  ppvVariantTitle: string | null;
}

export interface VideoState {
  videoPlayerSize: VideoPlayerSize;
  video: DatabaseVideo | null;
  playing: boolean;
  adPlacements: AdWithProduct[];
  currentAdStrategy?: AdStrategy;
  adStrategies?: AdStrategy[];
  coordinates: Coords;
  lastManualSizeRequest: VideoPlayerSize | null;
  elRef: RefObject<Element> | null;
  singletonPlayer: SingletonPlayer;
  showingPlayNext: boolean;
  internalLoadStep: InternalLoadStep;
  readyStatus: PlayerReady;
  currentlyLoadedVideo: number | undefined;
  token: string | undefined;
  payPerViewNotPurchased: boolean;
  ppvInfo: PPVInfo | undefined;
  userNotMember: boolean | undefined;
  prevLoginStatus?: boolean;
  orientation: OrientationState;
  embeddedCartOpen: boolean;
  placeholderPosition: number;
}

interface VideoContext {
  state: VideoState;
  dispatch: (action: VideoPlayerAction) => void;
}

const initialVideoState: VideoState = {
  videoPlayerSize: VideoPlayerSize.MEDIUM_EMBEDDED,
  playing: false,
  video: null,
  adPlacements: [],
  coordinates: { position: { x: 0, y: 0 }, size: { height: 0, width: 0 } },
  lastManualSizeRequest: null,
  elRef: null,
  singletonPlayer: { player: undefined, videoSource: undefined },
  showingPlayNext: false,
  internalLoadStep: InternalLoadStep.NOT_STARTED,
  currentlyLoadedVideo: undefined,
  readyStatus: PlayerReady.LOADING_HIDE,
  token: undefined,
  payPerViewNotPurchased: false,
  ppvInfo: undefined,
  userNotMember: false,
  prevLoginStatus: undefined,
  orientation: { angle: 0, orientation: 'unknown' },
  embeddedCartOpen: false,
  placeholderPosition: 0,
};

const initialVideoContext: VideoContext = {
  state: initialVideoState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: (_action: VideoPlayerAction): void => {
    // ClientLogger.error('initialVideoContext.dispatch', 'Calling uninitialized', action);
    // output caused console pollution during build
  },
};

const calcReady = (internal: InternalLoadStep) => {
  switch (internal) {
    case InternalLoadStep.NOT_STARTED:
    case InternalLoadStep.LOAD_VHX:
    case InternalLoadStep.DISPOSE:
    case InternalLoadStep.GET_AUTH:
      return PlayerReady.LOADING_HIDE;
    case InternalLoadStep.FIRST_RENDER:
    case InternalLoadStep.SETUP:
    case InternalLoadStep.AUTO_PLAY:
      return PlayerReady.LOADING_SHOW;
    case InternalLoadStep.READY:
      return PlayerReady.READY;
    default: {
      ClientLogger.warning('useVideoState:calcReady', `Unexpected internal load step ${internal}`);
      return PlayerReady.UNKNOWN;
    }
  }
};

const videoStateReducer = (state: VideoState, action: VideoPlayerAction): VideoState => {
  ClientLogger.debug('videoStateReducer', `action=${action.type}`, DEBUG, { action, entryState: state });
  let retVal: VideoState;
  switch (action.type) {
    case 'SET_SIZE':
      retVal = {
        ...state,
        videoPlayerSize: action.newVideoSize,
        lastManualSizeRequest: action.isManual ? action.newVideoSize : state.lastManualSizeRequest,
      };
      break;
    case 'LOAD':
      retVal = {
        ...state,
        video: action.loadParams.video,
        adPlacements: action.loadParams.adPlacements || [],
        currentAdStrategy: action.loadParams.currentAdStrategy,
        adStrategies: action.loadParams.adStrategies,
        prevLoginStatus: action.isLoggedIn,
        showingPlayNext: false,
        internalLoadStep: InternalLoadStep.NOT_STARTED,
        readyStatus: calcReady(InternalLoadStep.NOT_STARTED),
        token: undefined,
        payPerViewNotPurchased: false,
        ppvInfo: undefined,
      };
      break;
    case 'SET_LOADING_STATE':
      action.internal;
      retVal = { ...state, internalLoadStep: action.internal, readyStatus: calcReady(action.internal) };
      break;
    case 'SET_COORDS':
      retVal = { ...state, coordinates: action.coords };
      break;
    case 'PLAY':
      retVal = { ...state, playing: true, showingPlayNext: false };
      if (state.singletonPlayer.player) {
        state.singletonPlayer.player.play(); // play method is shared by both youtube and vimeo players, can do a switch here in future if required
      }
      break;
    case 'PAUSE':
      retVal = { ...state, playing: false };
      if (state.singletonPlayer.player) {
        state.singletonPlayer.player.pause(); // pause method is shared by both youtube and vimeo players, can do a switch here in future if required
      }
      break;
    case 'SET_EL_REF':
      retVal = { ...state, elRef: action.elRef };
      break;
    case 'SET_PLAYER':
      retVal = { ...state, singletonPlayer: action.player };
      singletonPlayer = action.player;
      break;
    case 'SET_TOKEN':
      retVal = { ...state, token: action.token };
      break;
    case 'SHOW_PLAY_NEXT':
      retVal = { ...state, showingPlayNext: action.show };
      break;
    case 'PPV_NOT_PURCHASED':
      retVal = { ...state, payPerViewNotPurchased: action.purchaseNecessary, ppvInfo: action.ppvInfo };
      break;
    case 'PPV_PURCHASE_START':
      retVal = {
        ...initialVideoState,
        videoPlayerSize: VideoPlayerSize.NOT_SHOWN,
      };
      break;
    case 'USER_NOT_MEMBER':
      retVal = {
        ...state,
        userNotMember: action.userNotMember,
      };
      break;
    case 'SET_ORIENTATION':
      retVal = {
        ...state,
        orientation: action.newOrientation,
      };
      break;
    case 'SET_EMBED_CART':
      retVal = {
        ...state,
        embeddedCartOpen: action.value,
      };
      break;

    case 'SET_PLACEHOLDER_POSITION':
      retVal = {
        ...state,
        placeholderPosition: action.value,
      };
      break;
    default:
      ClientLogger.error('videoStateReducer', `'Unexpected action.type`);
      retVal = state;
  }
  ClientLogger.debug('videoStateReducer', 'exitState', DEBUG, retVal);
  return retVal;
};

/* Define a context and a reducer for updating the context */
export const VideoStateContext = createContext(initialVideoContext);
/* Export a component to provide the context to its children */

export const VideoStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(videoStateReducer, initialVideoState);
  ClientLogger.debug('VideoStateProvider', 'useReducer', DEBUG, { state, dispatch });
  return <VideoStateContext.Provider value={{ state, dispatch }}>{children}</VideoStateContext.Provider>;
};

function getBrowserFullscreenElementProp(): string | null {
  if (!isBrowser) {
    return 'fullscreenElement';
  }
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  }
  // @ts-ignore
  if (typeof document.mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  }
  // @ts-ignore
  if (typeof document.msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  }
  // @ts-ignore
  if (typeof document.webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  }
  return null;
}

export function getBrowserFullscreenElement(): Element | null {
  if (!isBrowser) {
    return null;
  }
  const elementName = getBrowserFullscreenElementProp();
  if (elementName) {
    // @ts-ignore
    return document[getBrowserFullscreenElementProp()];
  }
  return null;
}

type orientation = 'landscape-primary' | 'portrait-primary' | 'landscape-secondary' | 'portrait-secondary' | 'unknown';

export interface OrientationState {
  angle: number;
  orientation: orientation;
}

const unknownOrientation: OrientationState = { angle: 0, orientation: 'unknown' };

export async function lockScreenOrientation(mode: orientation): Promise<OrientationState> {
  if (mode === 'unknown' || !isBrowser) {
    return unknownOrientation;
  }

  const lockOrientationUniversal: any =
    // @ts-ignore
    screen.orientation || screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;
  ClientLogger.debug('lockScreenOrientation', 'entered', DEBUG, { lockOrientationUniversal, screen });
  if (!lockOrientationUniversal) {
    return unknownOrientation;
  }

  try {
    const lockResp = await lockOrientationUniversal.lock(mode);
    ClientLogger.debug('lockScreenOrientation', 'lockOrientationUniversal promise resolved', DEBUG, {
      lockResp,
      lockOrientationUniversal,
      screen,
    });
    return lockResp;
  } catch (e) {
    const err = e as any;
    if (e && err.code && err.code === 9) {
      // not supported.  That's fine
      return unknownOrientation;
    }
    ClientLogger.error('lockScreenOrientation', 'Catch error after lock', e);
    return unknownOrientation;
  }
}

export interface PlayerStatus {
  isReadyToPlay: boolean;
  isPlaying: boolean;
  isFinished: boolean;
  currentSrc: string;
  currentTime: number;
  getVideoDuration: number;
  paused: boolean;
  remainingTime: number;
  duration: number;
}

interface SingletonPlayer {
  player: any;
  videoSource?: VideoSource;
}

let singletonPlayer: SingletonPlayer = {
  player: undefined,
  videoSource: VideoSource.VIMEO_OTT,
};

function getPlayerStatus(singletonPlayer: SingletonPlayer, expectToBeThere = true): PlayerStatus | null {
  ClientLogger.debug('useVideoState.getPlayerStatus', 'entered', DEBUG, { singletonPlayer });

  // if player exists and is from vimeo ott
  if (singletonPlayer && singletonPlayer.player && singletonPlayer.videoSource === VideoSource.VIMEO_OTT) {
    const vhx = singletonPlayer.player;
    const result = {
      isReadyToPlay: vhx.isReadyToPlay(),
      isPlaying: vhx.isPlaying(),
      isFinished: vhx.isFinished(),
      currentSrc: vhx.currentSrc(),
      currentTime: vhx.currentTime(),
      getVideoDuration: vhx.getVideoDuration(),
      paused: vhx.paused(),
      remainingTime: vhx.remainingTime(),
      duration: vhx.duration(),
    };
    if ((result.isPlaying === undefined && expectToBeThere) || (result.currentTime === undefined && expectToBeThere)) {
      ClientLogger.error('useVideoState.getPlayerStatus', 'Player is returning undefined values');
    }
    ClientLogger.debug('useVideoState.getPlayerStatus', 'return value', DEBUG, { result, vhx });
    return result;
  }
  return null;
}

/*
Export a hook that provides a simple API for updating the video state.
*/
export interface VideoStateHook {
  state: VideoState;
  setVideoSize: (newVideoSize: VideoPlayerSize, isManual?: boolean) => void;
  load: (loadParams: VideoLoadParams) => Promise<void>;
  firstRenderDone: () => void;
  setupDone: () => Promise<boolean>;
  setCoords: (coords: Coords) => void;
  play(): void;
  pause(): void;
  setElRef(elRef: RefObject<Element>): void;
  showPlayNext(show: boolean): void;
  ppvPurchaseStart(): void;
  getPlayerStatus(singletonPlayer: SingletonPlayer, expectToBeThere?: boolean): PlayerStatus | null;
  waitForPlayerStatus(test: (status: PlayerStatus | null) => boolean, expectToBeThere?: boolean, maxWaitSeconds?: number): Promise<boolean>;
  setPlayer: (player: SingletonPlayer) => void;
  setOrientation: (newOrientation: OrientationState) => void;
  setEmbeddedCartOpen: (value: boolean) => void;
  setPlaceholderPosition: (value: number) => void;
}

export const useVideoState = (): VideoStateHook => {
  const { dispatch, state } = useContext(VideoStateContext);
  const api = usePvepApi();
  const analyticsCapture = useAnalyticsCapture();

  const setVideoSize = (newVideoSize: VideoPlayerSize, isManual = false) => {
    ClientLogger.debug('useVideoState', 'setVideoSize', DEBUG, { oldSize: state.videoPlayerSize, newVideoSize });
    if (newVideoSize === VideoPlayerSize.NOT_SHOWN) {
      analyticsCapture.clearVideoInView();
    }
    let intervalCount = 0;
    const interval = setInterval(() => {
      // To allow for transitions we use this hack for 2 seconds after event
      // The Resize detector does not detect changes in styling https://github.com/bootstarted/react-resize-observer#readme
      ClientLogger.debug('useVideoState.setVideoSize', 'setInterval', DEBUG, { intervalCount });
      document.body.dispatchEvent(new UIEvent('scroll'));
      intervalCount++;
      if (intervalCount > 20) {
        clearInterval(interval);
      }
    }, 100);
    if (state.videoPlayerSize !== newVideoSize) {
      dispatch({
        type: 'SET_SIZE',
        newVideoSize,
        isManual,
      });
      if (isBrowser) {
        window.setTimeout(() => {
          document.body.dispatchEvent(new UIEvent('scroll'));
          // Needed to force re-render. See PositionDetection: https://github.com/metalabdesign/react-resize-observer#readme
        });
        const el = document && document.getElementById('video-player');
        if (el) {
          newVideoSize === VideoPlayerSize.NOT_SHOWN ? (el.style.display = 'none') : (el.style.display = '');
          if (newVideoSize === VideoPlayerSize.NOT_SHOWN) dispatch({ type: 'PAUSE' });
        }
      }
    }
  };

  const waitForVHS = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.LOAD_VHX });
      let count = 0;
      const interval = setInterval(() => {
        // @ts-ignore
        if (window.VHX) {
          clearInterval(interval);
          resolve(true);
        }
        count++;
        if (count > 200) {
          reject(false);
        }
      }, 10);
    });
  };

  // get a new VHX player
  const newPlayer = () => {
    // @ts-ignore
    if (window.VHX) {
      singletonPlayer = {
        // @ts-ignore
        player: new window.VHX.Player('video'),
        videoSource: VideoSource.VIMEO_OTT,
      };
      setPlayer(singletonPlayer);
      return singletonPlayer;
    }
    ClientLogger.error('useVideoState', 'newPlayer could not find player');
    return undefined;
  };

  const getPlayer = (): SingletonPlayer => {
    ClientLogger.debug('useVideoState.getplayer', 'Called', DEBUG);

    // get new VHX player if one doesn't exist and source is vimeo
    // OR if the existing player is not from vimeo, but the new videosource is vimeo
    if (
      (!singletonPlayer.player && singletonPlayer.videoSource === VideoSource.VIMEO_OTT) ||
      (singletonPlayer.player && singletonPlayer.videoSource !== VideoSource.VIMEO_OTT && state.video?.type === VideoSource.VIMEO_OTT)
    ) {
      ClientLogger.debug('useVideoState.getplayer', 'Getting new VHX player', DEBUG);
      newPlayer();
    }
    return singletonPlayer; // state.player || (window.VHX ? new window.VHX.Player('video') : undefined); // Or should it use a new vhx?
  };

  // set the singleton player
  const setPlayer = (player: SingletonPlayer) => {
    dispatch({ type: 'SET_PLAYER', player });
  };

  const waitForPlayerStatus = (
    test: (status: PlayerStatus | null) => boolean,
    expectToBeThere = true,
    maxWaitSeconds = 5
  ): Promise<boolean> => {
    const checkFrequency = 50; // ms
    ClientLogger.debug('useVideoState.waitForPlayerStatus', 'STARTED', DEBUG, expectToBeThere);
    return new Promise<boolean>((resolve, reject) => {
      let count = 0;
      const interval = setInterval(() => {
        count++;
        // @ts-ignore
        // singletonPlayer = new window.VHX.Player('video')
        const singletonPlayer = getPlayer();
        const status = getPlayerStatus(singletonPlayer, expectToBeThere);
        ClientLogger.debug('useVideoState.waitForPlayerStatus', '', DEBUG, status);
        if (count > (1000 / checkFrequency) * maxWaitSeconds) {
          clearInterval(interval);
          reject('timeout');
        }
        if (test(status)) {
          clearInterval(interval);
          resolve(true);
        }
      }, checkFrequency);
    });
  };

  const dispose = async (): Promise<boolean> => {
    return new Promise(resolve => {
      ClientLogger.debug('useVideoState.dispose', 'waiting for disposal', DEBUG);
      waitForPlayerStatus(
        status => {
          const singletonPlayer = getPlayer();
          const { player } = singletonPlayer;
          ClientLogger.debug('useVideoState.dispose', 'callback', DEBUG, { player, status });
          if (player) {
            ClientLogger.debug('useVideoState.dispose', 'stopping previous and disposing', DEBUG);
            player.stop();
            player.dispose();
          }
          return status?.isReadyToPlay === undefined;
        },
        false,
        0.1
      )
        .then(result => {
          ClientLogger.debug('useVideoState.dispose', 'dispose called', DEBUG, result);
          setTimeout(() => {
            resolve(true);
          }, 500);
        })
        .catch(() => {
          ClientLogger.debug('useVideoState.dispose', 'dispose failed', DEBUG);
          resolve(true);
        });
    });
  };

  const getAuthCode = (
    videoId: string,
    retry = false
  ): Promise<{ token?: string; payPerViewNotPurchased?: boolean; ppvInfo?: PPVInfo; userNotMember?: boolean }> => {
    const location = 'useVideoState.getAuthCode';
    return new Promise((resolve, reject) => {
      DEBUG && ClientLogger.debug(location, 'Getting token', { videoId, retry });
      if (!videoId) {
        reject('useVideoState.getAuthCode called when no video set');
        return;
      }
      DEBUG && ClientLogger.debug(location, 'Getting token', { videoId });
      api
        .getVideoToken(Number(videoId)) // TODO: Change API to be string
        .then(getVideoToken => {
          DEBUG && ClientLogger.debug(location, 'Got token', { videoId, getVideoToken });
          const { data } = getVideoToken;
          if (data) {
            const { errorMessages, ppvProductId, ppvVariantTitle, ppvProductTitle, ppvVariantId } = data.getVideoToken;
            if (errorMessages && errorMessages?.length > 0) {
              // user has not purchased ppv
              if (errorMessages[0].errorCode === ErrorCode.PPV_UNAUTHORIZED) {
                if (!ppvProductId) {
                  ClientLogger.error(location, `Expected to get ppvProductId`);
                }
                const ppvInfo: PPVInfo = {
                  ppvProductId: ppvProductId || '',
                  ppvProductTitle: ppvProductTitle || '',
                  ppvVariantId: ppvVariantId || '',
                  ppvVariantTitle: ppvVariantTitle || '',
                };
                const retVal = { payPerViewNotPurchased: true, ppvInfo };
                DEBUG && ClientLogger.debug(location, 'detected PPV needed', { retVal });
                resolve(retVal);
                return;

                // user is not a member/subscriber
              }
              if (errorMessages[0].errorCode === ErrorCode.EMAIL_ADDRESS_INVALID) {
                const retVal = { userNotMember: true };
                resolve(retVal);
                return;
              }
              ClientLogger.error(location, `Unexpected errors $`, errorMessages);
            }
            const token = data.getVideoToken.token;
            DEBUG && ClientLogger.debug(location, 'Got token', { videoId });
            // when authenticated this response will contain the token to load the video
            resolve({ token });
          }
        })
        .catch((error: any) => {
          // This should be handled by token-refresh-link.  Why are we doing this?
          ClientLogger.log(location, `getVideoToken returned an error.  Triggering token refresh. ${JSON.stringify(error)}`);
          if (!retry) {
            reject('Could not obtain token');
            return;
          }
          api
            .getJwtFromRefresh(() => {
              AuthClientUtil.logout('/');
            })
            .then(_result => {
              getAuthCode(videoId, true)
                .then(value => {
                  resolve(value);
                })
                .catch(reason => {
                  reject(reason);
                });
            })
            .catch(e => {
              ClientLogger.error(location, 'Error attempting to renew JWT', e);
              reject(e);
            });
        });
    });
  };

  const autoPlay = async (): Promise<boolean> => {
    ClientLogger.debug('useVideoState.autoPlay', 'waiting for player status', DEBUG);
    return new Promise(resolve => {
      waitForPlayerStatus(
        status => {
          const singletonPlayer = getPlayer();
          const { player } = singletonPlayer;
          ClientLogger.debug('useVideoState.autoPlay', 'checking if player is playing', DEBUG, {
            player,
            status,
            size: state.videoPlayerSize,
          });

          // This hacky fix after extensive testing, and realizing solution was not perfect.
          const pathParts = window.location.pathname.split('/');
          if (isBrowser && (pathParts.includes('app') || pathParts.includes('series'))) {
            player.pause();
          } else if (player && state.videoPlayerSize !== VideoPlayerSize.NOT_SHOWN) {
            player.play();
          }
          return status?.isPlaying || false;
        },
        true,
        5
      )
        .then(result => {
          ClientLogger.debug('useVideoState.autoPlay', 'autoPlay succeeded', DEBUG, result);
          setTimeout(() => {
            resolve(true);
          }, 500);
        })
        .catch(() => {
          ClientLogger.debug('useVideoState.autoPlay', 'autoPlay failed', DEBUG);
          resolve(true);
        });
    });
  };

  return {
    setVideoSize,
    load: async (loadParams: VideoLoadParams) => {
      ClientLogger.debug('useVideoState.load', 'called', DEBUG, loadParams);
      const { isLoggedIn } = api.state;

      if (
        (loadParams.video.id && loadParams.video.id !== state?.video?.id) ||
        JSON.stringify(loadParams.adPlacements || []) !== JSON.stringify(state.adPlacements || []) ||
        isLoggedIn !== state.prevLoginStatus
      ) {
        const previousVideo = state.video;
        const { video } = loadParams;

        dispatch({ type: 'LOAD', loadParams, isLoggedIn });

        if (video.type === VideoSource.VIMEO_OTT) {
          const vhsLoadStatus = await waitForVHS();
          if (!vhsLoadStatus) {
            ClientLogger.error('useVideoState.load', 'Failed to load VHS library');
            throw new Error('Failed to load VHS library');
          }
        }
        if (previousVideo?.id) {
          if (previousVideo?.type === VideoSource.VIMEO_OTT) {
            const disposalStatus = await dispose();
            if (!disposalStatus) {
              ClientLogger.error('useVideoState.load', 'Error disposing of previous player');
            }
          }
        }

        if (video.type === VideoSource.VIMEO_OTT && !video.isFree) {
          // if user is logged in, get authorization for video
          if (isLoggedIn) {
            const tokenResp = await getAuthCode(loadParams.video.externalId || loadParams.video.id);
            if (tokenResp.token) {
              if (state.userNotMember) {
                dispatch({ type: 'USER_NOT_MEMBER', userNotMember: false });
              }
              dispatch({ type: 'SET_TOKEN', token: tokenResp.token });
              dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.FIRST_RENDER });

              // video is ppv and it hasn't been purchased
            } else if (tokenResp.payPerViewNotPurchased) {
              if (!tokenResp.ppvInfo) {
                throw new Error(`Expected ppvInfo from token response when PPV needed`);
              }
              dispatch({ type: 'PPV_NOT_PURCHASED', ppvInfo: tokenResp.ppvInfo, purchaseNecessary: true });

              // user is not a subscriber/member
            } else if (tokenResp.userNotMember) {
              dispatch({ type: 'USER_NOT_MEMBER', userNotMember: tokenResp.userNotMember });
            } else {
              throw new Error(`Unexpected response from getAuthCode`);
            }

            // user not logged in, just get ppv info
          } else {
            const ppvResponse = await api.getPPVData(Number(loadParams.video.externalId || loadParams.video.id));
            dispatch({ type: 'SET_TOKEN', token: undefined });
            const resdata = ppvResponse.data?.getPPVData;

            // if the request returns false, which it should as user not logged in
            if (resdata && !resdata?.success) {
              const errCode = resdata.errorMessages ? resdata.errorMessages[0].errorCode : null;

              // video is not a ppv, but not free either, so members only
              if (errCode === ErrorCode.REQUESTED_VIDEO_NOT_FOUND) {
                dispatch({ type: 'USER_NOT_MEMBER', userNotMember: true });

                // video is a ppv
              } else if (errCode === ErrorCode.PPV_UNAUTHORIZED) {
                const ppvInfo: PPVInfo = {
                  ppvProductId: resdata.ppvProductId || '',
                  ppvProductTitle: resdata.ppvProductTitle || '',
                  ppvVariantId: resdata.ppvVariantId || '',
                  ppvVariantTitle: resdata.ppvVariantTitle || '',
                };
                dispatch({ type: 'PPV_NOT_PURCHASED', ppvInfo, purchaseNecessary: true });
              } else {
                // unexpected failure
                ClientLogger.error(
                  'useVideoState:load',
                  `Unexpected response from getPPVData for logged out user`,
                  JSON.stringify(resdata?.errorMessages, null, 2)
                );
              }
            } else {
              ClientLogger.error(
                'useVideoState:load',
                `Unexpected response from getPPVData for logged out user`,
                JSON.stringify(resdata?.errorMessages, null, 2)
              );
            }
          }

          // video is free or from another source
        } else {
          dispatch({ type: 'SET_TOKEN', token: undefined });
          dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.FIRST_RENDER });
        }
      }
    },
    firstRenderDone: () => {
      //      newPlayer();
      dispatch({ type: 'SET_PLAYER', player: getPlayer() });
      dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.SETUP });
    },
    setupDone: async (): Promise<boolean> => {
      dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.READY });
      const result = await autoPlay();
      if (result) {
        ClientLogger.debug('useVideoState.setupDone', 'autoPlay done', DEBUG);
        dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.READY });
      } else {
        ClientLogger.error('useVideoState.setupDone', 'Auto play failed');
        dispatch({ type: 'SET_LOADING_STATE', internal: InternalLoadStep.READY });
      }
      return result;
    },
    setCoords: (coords: Coords) => {
      if (JSON.stringify(coords) !== JSON.stringify(state.coordinates)) {
        dispatch({ type: 'SET_COORDS', coords });
      }
    },
    play() {
      dispatch({ type: 'PLAY' });
    },
    pause() {
      dispatch({ type: 'PAUSE' });
    },
    setElRef(elRef: RefObject<Element>) {
      dispatch({ type: 'SET_EL_REF', elRef });
    },
    showPlayNext(show: boolean) {
      dispatch({ type: 'SHOW_PLAY_NEXT', show });
    },
    ppvPurchaseStart() {
      dispatch({ type: 'PPV_PURCHASE_START' });
    },
    setOrientation(newOrientation: OrientationState) {
      dispatch({ type: 'SET_ORIENTATION', newOrientation });
    },
    setEmbeddedCartOpen(value: boolean) {
      dispatch({ type: 'SET_EMBED_CART', value });
    },
    setPlaceholderPosition(value: number) {
      dispatch({ type: 'SET_PLACEHOLDER_POSITION', value });
    },
    setPlayer,
    getPlayerStatus,
    waitForPlayerStatus,
    state,
  };
};
