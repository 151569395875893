// extracted by mini-css-extract-plugin
export var overlayControlsContainer = "styles-module--overlayControlsContainer--2eQ6X";
export var overlayYoutubeContainer = "styles-module--overlayYoutubeContainer--3CAks";
export var overlayVimeoContainer = "styles-module--overlayVimeoContainer--2jlL_";
export var overlayVimeoOTTContainer = "styles-module--overlayVimeoOTTContainer--2o1gT";
export var overlayControlsFull = "styles-module--overlayControlsFull--261JB";
export var shopOverlayContainer = "styles-module--shopOverlayContainer--3_4q4";
export var shopOverlayContainerHidden = "styles-module--shopOverlayContainerHidden--7y0Ot";
export var bannerOverlayContainer = "styles-module--bannerOverlayContainer--3DU1W";
export var bannerOverlayImagePlaceholder = "styles-module--bannerOverlayImagePlaceholder--32eJI";
export var shopNowButtonContainer = "styles-module--shopNowButtonContainer--246FO";
export var shopOverlayImage = "styles-module--shopOverlayImage--3wTpG";
export var shopOverlayImageContainer = "styles-module--shopOverlayImageContainer--1m9VX";
export var shopOverlayImageButtonBorder = "styles-module--shopOverlayImageButtonBorder--19zqU";
export var shopButtonOverlayProductOpen = "styles-module--shopButtonOverlayProductOpen--3eL8O";
export var shopOverlayAnimation = "styles-module--shopOverlayAnimation--WuDuD";
export var previousAdOverlay = "styles-module--previousAdOverlay--1j6-m";
export var shopOverlayAnimationDarken = "styles-module--shopOverlayAnimationDarken--3bFH0";
export var previousAdOverlaySmall = "styles-module--previousAdOverlaySmall--30mbD";
export var shopOverlayAnimationSmall = "styles-module--shopOverlayAnimationSmall--3J1py";
export var previousAdImage = "styles-module--previousAdImage--1urlH";
export var shopTextHidden = "styles-module--shopTextHidden--2VNwG";
export var shopText = "styles-module--shopText--1ARmK";
export var bannerContainer = "styles-module--bannerContainer--f_pDP";
export var productOverlay = "styles-module--productOverlay--1pTt-";
export var productOverlayOpen = "styles-module--productOverlayOpen--3u9VF";
export var productOverlayForm = "styles-module--productOverlayForm--367ff";
export var productOverlayContainer = "styles-module--productOverlayContainer--3Io5-";
export var productOverlayContent = "styles-module--productOverlayContent--w1ex3";
export var productOverlayContentGroup = "styles-module--productOverlayContentGroup--12RDH";
export var productOverlayAffiliateContentGroup = "styles-module--productOverlayAffiliateContentGroup--1URQm";
export var productOverlayTitle = "styles-module--productOverlayTitle--69kWt";
export var productOverlaySubtitle = "styles-module--productOverlaySubtitle--uhMZB";
export var productOverlayPrice = "styles-module--productOverlayPrice--2P0Ag";
export var productOverlayVariantContainer = "styles-module--productOverlayVariantContainer--3WGK1";
export var productOverlayDescriptionContainer = "styles-module--productOverlayDescriptionContainer--2-LWh";
export var productOverlayVariant = "styles-module--productOverlayVariant--5uBVu";
export var lastVariant = "styles-module--lastVariant--_-zwy";
export var productOverlayButtonContainer = "styles-module--productOverlayButtonContainer--qHzGx";
export var productOverlayButton = "styles-module--productOverlayButton--2vuBS";
export var productOverlayShareIconContainer = "styles-module--productOverlayShareIconContainer--3YfB_";
export var productOverlayShareIcon = "styles-module--productOverlayShareIcon--2eFHQ";
export var bannerEndFade = "styles-module--bannerEndFade--2pt4b";
export var bannerStartFade = "styles-module--bannerStartFade--2cpRJ";
export var bannerContainerInternal = "styles-module--bannerContainerInternal--GGDg7";
export var bannerContainerHide = "styles-module--bannerContainerHide--uKJF8";
export var productImageContainer = "styles-module--productImageContainer--1smY_";
export var productImageContainerActive = "styles-module--productImageContainerActive--1kJMD";
export var productOverlayShare = "styles-module--productOverlayShare--zfAYE";
export var productOverlayShareContainer = "styles-module--productOverlayShareContainer--2HTvE";
export var productOverlayShareOpen = "styles-module--productOverlayShareOpen--gTyeX";
export var productOverlayShareIcons = "styles-module--productOverlayShareIcons--3ulXE";
export var productOverlayIcon = "styles-module--productOverlayIcon--2Vjg0";
export var variantSelector = "styles-module--variantSelector--3iyGI";
export var featuredProductsContainer = "styles-module--featuredProductsContainer--3djuZ";
export var customScrollButton = "styles-module--customScrollButton--1SFgE";
export var customCloseButton = "styles-module--customCloseButton--btn4W";
export var newItemNotification = "styles-module--newItemNotification--37LTo";
export var newItemNotificationHide = "styles-module--newItemNotificationHide--1VLkH";
export var itemNotificationOut = "styles-module--itemNotificationOut--3hlkg";
export var newItemNotificationShow = "styles-module--newItemNotificationShow--2Elym";
export var itemNotificationIn = "styles-module--itemNotificationIn--3LdJC";