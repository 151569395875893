// extracted by mini-css-extract-plugin
export var videoListContainer = "styles-module--videoListContainer--xJFNx";
export var videoListGrid = "styles-module--videoListGrid--3fMhl";
export var videoListContainerHorizontal = "styles-module--videoListContainerHorizontal--2Reuu";
export var textHeader = "styles-module--textHeader--2YPZd";
export var scrollButton = "styles-module--scrollButton--_Fm0p";
export var left = "styles-module--left--3sNJi";
export var right = "styles-module--right--2GBFH";
export var shiftUpLeft = "styles-module--shiftUpLeft--2YCnk";
export var shiftUpRight = "styles-module--shiftUpRight--3fybT";
export var buttonIcon = "styles-module--buttonIcon--14Apb";