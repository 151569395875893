// extracted by mini-css-extract-plugin
export var logo = "styles-module--logo--2kz3L";
export var landingGreeting = "styles-module--landingGreeting--1eMCk";
export var box = "styles-module--box--3kMB9";
export var tabsHeader = "styles-module--tabsHeader--2o4Id";
export var tabLink = "styles-module--tabLink--3M7vC";
export var isActive = "styles-module--isActive--3Zv9m";
export var videoList = "styles-module--videoList--1WL76";
export var videoListContainer = "styles-module--videoListContainer--10bSg";
export var seriesHeader = "styles-module--seriesHeader--17WuS";
export var seriesHeaderImage = "styles-module--seriesHeaderImage--1CNg7";
export var seriesHeaderImageThumbnail = "styles-module--seriesHeaderImageThumbnail--3HgiC";
export var seriesHeaderContent = "styles-module--seriesHeaderContent--2H4DR";
export var seriesHeaderTitle = "styles-module--seriesHeaderTitle--uwETD";
export var seriesHeaderDescription = "styles-module--seriesHeaderDescription--3vH-f";
export var seriesSeasonsList = "styles-module--seriesSeasonsList--2LoBv";
export var seriesVideoCardWrapper = "styles-module--seriesVideoCardWrapper--2hfh3";
export var seriesVideoCard = "styles-module--seriesVideoCard--rMzPO";
export var videoCardImage = "styles-module--videoCardImage--3BU4h";
export var videoThumbnail = "styles-module--videoThumbnail--gnldO";
export var seriesText = "styles-module--seriesText--OWf_n";
export var seriesEpisodeNumber = "styles-module--seriesEpisodeNumber--2BcEq";
export var videoTitle = "styles-module--videoTitle--1R9Ui";
export var videoDescription = "styles-module--videoDescription--iqWl7";
export var playButtonWrapper = "styles-module--playButtonWrapper--3Hn7m";
export var tabDropdown = "styles-module--tabDropdown--1HNGh";
export var overlayIcon = "styles-module--overlayIcon--30u_4";