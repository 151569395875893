// extracted by mini-css-extract-plugin
export var cardHeader = "styles-module--cardHeader--bdJja";
export var popupContainer = "styles-module--popupContainer--RuLva";
export var popupOverlay = "styles-module--popupOverlay--2wMCg";
export var popup = "styles-module--popup--2xrSL";
export var verifyForm = "styles-module--verifyForm--3ZMyh";
export var closeButton = "styles-module--closeButton--PCT4W";
export var verifyTitle = "styles-module--verifyTitle--jUVfD";
export var verifyText = "styles-module--verifyText--IZbsE";
export var popupBlockedCard = "styles-module--popupBlockedCard--NWcjh";
export var popupBlockedText = "styles-module--popupBlockedText--1XXe7";