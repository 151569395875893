import { useEffect, useState, RefObject } from 'react';
import { useWindowSize } from '@lib/responsive';
import { boolean } from 'yup';

// / Check if element is scrollable
export const isScrollable = (element: { scrollWidth: number; clientWidth: number; scrollHeight: number; clientHeight: number }) => {
  return {
    x: element && element.scrollWidth > element.clientWidth,
    y: element && element.scrollHeight > element.clientHeight,
  };
};

interface ScrollToDiv {
  scrollLeft: number;
  clientWidth: number;
  scrollWidth: number;
}

// scrollPercentage: number; // how much of the clientWidth to scroll.
// overScrollAtXLeft: number; // if scrolling and there would only be X of the client Width left, just scroll to end.
const defaultOptions = {
  scrollPercentage: 1,
  overScrollAtXLeft: 0.01,
  roundToNearest: 1,
};

export const calculateScrollToX = (shouldScrollLeft = false, { scrollLeft, clientWidth, scrollWidth }: ScrollToDiv, options = {}) => {
  const { scrollPercentage, overScrollAtXLeft, roundToNearest } = { ...defaultOptions, ...options };
  const currentScroll = scrollLeft;
  const scrollAmount = clientWidth * scrollPercentage * (shouldScrollLeft ? -1 : 1);
  let scrollToX = Math.max(currentScroll + scrollAmount, 0);

  // round to nearest increment
  scrollToX = Math.round(scrollToX / roundToNearest) * roundToNearest;

  // if only overScrollAtXLeft (a quarter) of scroll is left, just scroll to end.
  if (currentScroll + clientWidth === scrollWidth && !shouldScrollLeft) {
    scrollToX = 0;
  } else if (!shouldScrollLeft && scrollWidth - scrollToX - clientWidth <= clientWidth * scrollPercentage * overScrollAtXLeft) {
    scrollToX = scrollWidth;
  } else if (scrollToX <= clientWidth * scrollPercentage * overScrollAtXLeft) {
    scrollToX = 0;
  }

  return scrollToX;
};

// / Use ScrollButtons - Takes in a react ref from useRef.
// / Returns {
// /    showScrollButtons: boolean (whether to show scroll buttons),
// /    onScrollButtonClick: (scrollLeft: boolean (should scroll to the left, false for scroll right) => void
// /      Function to scroll element
// / }
export const useScrollButtons = (elementRef: RefObject<HTMLDivElement>, options?: any) => {
  const windowSize = useWindowSize();

  const onScrollButtonClick = (shouldScrollLeft: boolean) => {
    if (elementRef && elementRef.current) {
      const {
        current: { scrollTop },
      } = elementRef;
      const scrollToX = calculateScrollToX(shouldScrollLeft, elementRef.current, options);

      elementRef.current.scrollTo({
        left: scrollToX,
        top: scrollTop,
        behavior: 'smooth',
      });
    }
  };

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    if (elementRef && elementRef.current && isScrollable(elementRef.current).x) {
      setShowScrollButtons(true);
    } else {
      setShowScrollButtons(false);
    }
  }, [elementRef, windowSize]);

  return { showScrollButtons, onScrollButtonClick };
};

export default useScrollButtons;
