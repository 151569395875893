// extracted by mini-css-extract-plugin
export var titleText = "styles-module--titleText--Qr-rG";
export var subtitleText = "styles-module--subtitleText--f6TrB";
export var darkenedText = "styles-module--darkenedText--1xUxd";
export var listContainer = "styles-module--listContainer--1Hp1c";
export var listRow = "styles-module--listRow--3pN3V";
export var listTitleColumn = "styles-module--listTitleColumn--1JVGo";
export var experimentCard = "styles-module--experimentCard--3tfXZ";
export var experimentItemRow = "styles-module--experimentItemRow--dGm6h";
export var experimentAddContainer = "styles-module--experimentAddContainer--3eiDq";
export var experimentAdd = "styles-module--experimentAdd--21WAY";
export var experimentItemDelete = "styles-module--experimentItemDelete--2qHtD";
export var experimentInput = "styles-module--experimentInput--jSi6n";
export var experimentSelect = "styles-module--experimentSelect--2xN_A";
export var experimentTitleInput = "styles-module--experimentTitleInput--3pN22";
export var experimentPercentRow = "styles-module--experimentPercentRow--ghEAa";
export var experimentPercentContainer = "styles-module--experimentPercentContainer--1EArL";
export var experimentPercentLabel = "styles-module--experimentPercentLabel--2a8vK";
export var experimentEditorError = "styles-module--experimentEditorError--1Kqev";
export var experimentSave = "styles-module--experimentSave--UYekg";
export var experimentButtonRow = "styles-module--experimentButtonRow--MMa_d";
export var cancelButton = "styles-module--cancelButton--3BscI";
export var deleteButton = "styles-module--deleteButton--2rSdN";
export var deleteCard = "styles-module--deleteCard--377mp";