import React, { useContext } from 'react';
import classNames from 'classnames';
import { ClientLogger } from '@lib/ClientLogger';
import { NavStateContext } from '@lib/navContext';
import { SubmitButton } from '@components/Buttons';
import { FormContainer } from '@components/Forms';
import { thanks, formHeader, emailSignUpForm } from './styles.module.scss';
import { useSignUpResource } from './useSignUpResources';

const DEBUG = false;

interface Props {
  destinationUrl?: string;
}
export const Thanks = ({ destinationUrl }: Props) => {
  const nav = useContext(NavStateContext);
  const content = useSignUpResource();

  const goApp = () => {
    if (destinationUrl) {
      ClientLogger.debug('Thanks', `goApp  ${destinationUrl}`, DEBUG);
      nav.setRoot({ path: destinationUrl });
    } else {
      ClientLogger.debug('Thanks', `goApp  /app`, DEBUG);
      nav.setRoot({ path: '/app', title: 'Home' });
    }
  };

  return (
    <FormContainer onSubmit={goApp} className={classNames(emailSignUpForm, thanks)}>
      <h1 className={classNames('standard-form__header', formHeader)}>{content.thankYouPage.thankYouPageTitle}</h1>
      <p className="is-size-6 has-text-centered">{content.thankYouPage.thankYouPageDescription}</p>
      <SubmitButton>{content.thankYouPage.thankYouCTA}</SubmitButton>
    </FormContainer>
  );
};
