import { ClientLogger } from '@src/lib/ClientLogger';
import { IFrontMatterHome } from '@pageComponents/Home/model';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageImage } from '@src/lib/usePageImage';
import { Images } from '@src/components/Img/useFluidImage';

const DEBUG = false;

interface IFrontMatterHomeWithImages extends IFrontMatterHome {
  heroImage: Images;
}

export function useHomeResource(): IFrontMatterHomeWithImages {
  // alternatively, we could import the md file
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { slug: { eq: "home" } }) {
        id
        frontmatter {
          hero
          content
        }
      }
    }
  `);
  const resource = data.markdownRemark.frontmatter;
  DEBUG && ClientLogger.debug('useHomeResource', `render ${JSON.stringify({ resource }, null, 2)}`);

  const heroImage = usePageImage(resource.hero);

  if (!resource) {
    ClientLogger.error('useHomeResource', `useHomeResource - cannot find 'resource' debug: ${JSON.stringify({ resource }, null, 2)}`);
    return { heroImage: {} } as any;
  }

  return { ...resource, heroImage };
}
