import { MutableRefObject } from 'react';

export function useRefPair<T>(ref: MutableRefObject<T>): [() => T, (newValue: T) => void] {
  return [
    (): T => {
      return ref.current;
    },
    (newValue: T) => {
      ref.current = newValue;
    },
  ];
}
