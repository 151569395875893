import { ErrorDisplay } from '@components/Forms/ErrorDisplay';
import { VideoPlaceHolder } from '@components/VideoPlayer/VideoPlaceHolder';
import { ClientLogger } from '@lib/ClientLogger';
import rightOutlined from '@iconify/icons-ant-design/right-outline';
import leftOutlined from '@iconify/icons-ant-design/left-outline';
import { useVideoState, VideoPlayerSize, VideoStateContext } from '@lib/useVideoState';
import { useSeries, useVideosOfSeries } from '@lib/video-query/useVideoData';
import { SeriesPageTemplateProps } from '@templates/series/Series';
import { SeriesVideoCard } from '@templates/series/SeriesVideoCard';
import React, { useContext, useEffect, useRef, useState } from 'react';
import IndexLayout from '@layouts/index';
import { BrandUtil, Video, DatabaseSeries, DatabaseSeason, DatabaseVideo, ascendingOrder } from '@sharedLib/index';
import { Helmet } from 'react-helmet';
import { WidgetLib, widgetSize } from '@alibi-tech/widget-lib';
import {
  videoListContainer,
  videoList,
  box,
  embeddedCartContainer,
  closeCartButton,
  buttonIcon,
  left,
  right,
  scrollButton,
  seriesEmbeddedTitle,
} from './styles.module.scss';
import { DivButton } from '@src/components/Buttons';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import useScrollButtons from '@lib/useScrollButtons';
import CartWidget from '@components/Cart/CartWidget';
import { useLocation } from '@reach/router';
import { usePvepApi } from '@apiClient/usePvepApi';
import { NavStateContext } from '@lib/navContext';

const DEBUG = false;

const SeriesEmbedPageTemplatePage = (props: SeriesPageTemplateProps) => {
  const nav = useContext(NavStateContext);
  const { state } = useContext(VideoStateContext);
  const [size, setSize] = useState<widgetSize>('filmStrip');
  const [selectedVideo, setSelectedVideo] = useState<Video | DatabaseVideo | undefined>(undefined);
  const videoState = useVideoState();
  const brandInfo = BrandUtil.getSiteInfo();
  const [loading, setLoading] = useState(false);
  const [seriesData, setSeriesData] = useState<DatabaseSeries | undefined>(undefined);
  const [seasonData, setSeasonData] = useState<DatabaseSeason[]>([]);
  const [videoData, setVideoData] = useState<DatabaseVideo[]>([]);

  const api = usePvepApi();
  const userState = api.state;

  // determine collectionId based on static page context or dynamically from the path
  let collectionId = props.pageContext.collectionId || '';
  const location = useLocation();
  if (props.pageContext.matchPath) {
    const pathParts = location.pathname.split('/').reverse();
    for (const part of pathParts) {
      if (part) {
        collectionId = part;
        break;
      }
    }
  }

  useEffect(() => {
    if (props.pageContext.matchPath && !userState.useRuntimeData) {
      nav.setRoot({ path: '/not-found' });
    }
  }, []);

  useEffect(() => {
    checkUserWatchingSeries(collectionId);
    if (userState.useRuntimeData) {
      loadSeries(collectionId);
    }
  }, [collectionId]);

  /// check/update watching location stored
  const checkUserWatchingSeries = async (seriesId: string) => {
    if (userState.watching.seriesId !== seriesId) {
      await api.updateUserWatching({
        categoryId: '',
        seriesId,
        seasonId: '',
        videoId: '',
      });
    }
  };

  const loadSeries = async (seriesId: string) => {
    setLoading(true);
    const seriesResp = await api.getAllDatabaseSeriesInfo(seriesId);
    if (seriesResp.data?.getAllDatabaseSeriesInfo.success && seriesResp.data.getAllDatabaseSeriesInfo.series) {
      const seriesDataDb = seriesResp.data.getAllDatabaseSeriesInfo.series;
      const seasonsDataDb = seriesResp.data.getAllDatabaseSeriesInfo.seasons || [];
      const videosDataDb = seriesResp.data.getAllDatabaseSeriesInfo.videos || [];
      setSeriesData(seriesDataDb);
      setSeasonData(seasonsDataDb);

      // flatten and sort videos in seasons
      let allVideos: DatabaseVideo[] = [];
      for (const season of seasonsDataDb) {
        season.items
          .sort((a, b) => ascendingOrder(a.rank, b.rank))
          .map(item => {
            const video = videosDataDb.find(vid => vid.id === item.videoId);
            if (video) {
              allVideos.push(video);
            }
          });
      }
      setVideoData(allVideos);
    }
    setLoading(false);
  };

  const videoCollection = useSeries(collectionId);
  const videoListElement = useRef<HTMLDivElement>(null);
  const { onScrollButtonClick } = useScrollButtons(videoListElement);

  ClientLogger.debug('SeriesPageTemplatePage', 'render', DEBUG, { collection: videoCollection || 'undefined' });

  useEffect(() => {
    DEBUG &&
      ClientLogger.debug('SeriesEmbedPageTemplatePage.useEffect', 'called', {
        size,
        videoPlayerSize: state.videoPlayerSize,
        video: state.video,
      });

    // don't resize if fullscreen
    if (state.videoPlayerSize === VideoPlayerSize.LARGE_FULLSCREEN) {
      return;
    }

    switch (size) {
      case 'filmStrip':
        DEBUG && ClientLogger.debug('SeriesEmbedPageTemplatePage.useEffect', 'In filmStrip mode. Hiding player');
        videoState.setVideoSize(VideoPlayerSize.NOT_SHOWN);
        WidgetLib.setSize('filmStrip');
        break;
      case 'filmStripAndPlayer':
        DEBUG && ClientLogger.debug('SeriesEmbedPageTemplatePage.useEffect', 'In filmStripAndPlayer strip mode. Showing player');
        videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
        WidgetLib.setSize('filmStripAndPlayer');
        break;
      case 'filmStripPlayerCart':
        DEBUG && ClientLogger.debug('SeriesEmbedPageTemplatePage.useEffect', 'In filmStripPlayerCart strip mode. Showing player');
        videoState.setVideoSize(VideoPlayerSize.MEDIUM_EMBEDDED);
        WidgetLib.setSize('filmStripPlayerCart');
        break;
      default:
        ClientLogger.warning('SeriesEmbedPageTemplatePage.useEffect', `Unexpected size ${size}`);
    }
  }, [size, state.videoPlayerSize, state.video]);

  useEffect(() => {
    if (videoState.state.embeddedCartOpen) {
      setSize('filmStripPlayerCart');
    }
  }, [videoState.state.embeddedCartOpen]);

  const videos = useVideosOfSeries(collectionId);
  const onClickVideo = (video: Video | DatabaseVideo) => {
    DEBUG && ClientLogger.debug('SeriesEmbedPageTemplatePage', 'video click', video);
    setSelectedVideo(video);
    setSize('filmStripAndPlayer');
  };

  DEBUG && ClientLogger.debug('SeriesEmbedPageTemplatePage', 'render', { videos, videoCollection, collectionId });

  if (!videoCollection && !userState.useRuntimeData) {
    return <ErrorDisplay message={`Cannot find collection id ${props}`} />;
  }

  return (
    <IndexLayout
      hideScrollBar
      needsLogin={brandInfo.videoNeedsRegistration}
      needsSubscription={brandInfo.videoNeedsRegistration}
      hideHeader
      hideFooter
      embeddedMode
      hideFeedback
    >
      {!userState.useRuntimeData && videoCollection && (
        <>
          <Helmet>
            <title>{videoCollection.title}</title>
          </Helmet>
          <div className={`${box} embedBox`}>
            <div className={seriesEmbeddedTitle}>{brandInfo.embeddedPlayerConfig.title}</div>
            <div ref={videoListElement} className={`${videoListContainer} ${videoList}`}>
              {videos.map(video => {
                return (
                  <SeriesVideoCard
                    video={video}
                    key={video.id}
                    onClick={async vid => {
                      await api.updateUserWatching({ ...userState.watching, videoId: vid.id });
                      onClickVideo(vid);
                    }}
                  />
                );
              })}
            </div>
            <DivButton
              className={classNames(scrollButton, left)}
              onClick={() => {
                onScrollButtonClick(true);
              }}
            >
              <Icon icon={leftOutlined} className={buttonIcon} />
            </DivButton>
            <DivButton
              className={classNames(scrollButton, right)}
              onClick={() => {
                onScrollButtonClick(false);
              }}
            >
              <Icon icon={rightOutlined} className={buttonIcon} />
            </DivButton>
          </div>
        </>
      )}

      {loading && userState.useRuntimeData && <div>Loading...</div>}
      {!loading && userState.useRuntimeData && (
        <>
          {seriesData && (
            <Helmet>
              <title>{seriesData.title}</title>
            </Helmet>
          )}
          <div className={`${box} embedBox`}>
            <div className={seriesEmbeddedTitle}>{brandInfo.embeddedPlayerConfig.title}</div>
            <div ref={videoListElement} className={`${videoListContainer} ${videoList}`}>
              {videoData.map(video => {
                return (
                  <SeriesVideoCard
                    video={video}
                    key={video.id}
                    onClick={async vid => {
                      await api.updateUserWatching({ ...userState.watching, videoId: vid.id });
                      onClickVideo(vid);
                    }}
                  />
                );
              })}
            </div>
            <DivButton
              className={classNames(scrollButton, left)}
              onClick={() => {
                onScrollButtonClick(true);
              }}
            >
              <Icon icon={leftOutlined} className={buttonIcon} />
            </DivButton>
            <DivButton
              className={classNames(scrollButton, right)}
              onClick={() => {
                onScrollButtonClick(false);
              }}
            >
              <Icon icon={rightOutlined} className={buttonIcon} />
            </DivButton>
          </div>
        </>
      )}

      {(size === 'filmStripAndPlayer' || size === 'filmStripPlayerCart') && selectedVideo && (
        <VideoPlaceHolder videoId={selectedVideo.id} />
      )}
      {videoState.state.embeddedCartOpen && (
        <div className={embeddedCartContainer}>
          <div
            className={closeCartButton}
            onClick={() => {
              videoState.setEmbeddedCartOpen(false);
              setSize('filmStripAndPlayer');
            }}
          >
            <Icon icon="bi:x-lg" />
          </div>
          <CartWidget skipScroll noVideo noNav noBackground />
        </div>
      )}
    </IndexLayout>
  );
};

export default SeriesEmbedPageTemplatePage;
