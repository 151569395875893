// extracted by mini-css-extract-plugin
export var formContainer = "styles-module--formContainer--iWBAC";
export var formImage = "styles-module--formImage--LYJyV";
export var formGroup = "styles-module--formGroup--1X4Qe";
export var nameGroupContainer = "styles-module--nameGroupContainer--1_qFb";
export var nameGroup = "styles-module--nameGroup--urBS7";
export var firstName = "styles-module--firstName--2wzyU";
export var formLabel = "styles-module--formLabel--dFJWT";
export var textField = "styles-module--textField--1LfYL";
export var error = "styles-module--error--3X2HT";
export var messageField = "styles-module--messageField--3Fm1S";
export var contactButtonWrapper = "styles-module--contactButtonWrapper--50A9t";
export var successMessage = "styles-module--successMessage--15Ktm";