import { ClientLogger } from '@lib/ClientLogger';
import { useStaticQuery, graphql } from 'gatsby';

import { ShopifyProduct, ProductCollection, RECOMMENDED_PRODUCT_PLACEHOLDER, BrandUtil } from '@sharedLib/index';

// Must match ShopifyProduct
export function useProductData() {
  const data = useStaticQuery(graphql`
    query allProductsJson {
      allProductsJson {
        nodes {
          id
          availableForSale
          collectionIds
          description
          descriptionHtml
          handle
          localImageFileName
          collectionIds
          eCommerceType
          imageOriginalSrc
          maxVariantPrice
          productNumber
          productType
          tags
          adTags
          targetUrl
          targetUrlType
          title
          alternateCta
          vendor
          videoIds
          options {
            id
            name
            values
          }
          metafields {
            id
            key
            value
            type
          }
          variants {
            id
            imageOriginalSrc
            localImageFileName
            price
            title
            videoIds
            priceDetails {
              amount
              currencyCode
            }
            metafields {
              id
              key
              value
              type
            }
            availableForSale
            selectedOptions {
              name
              value
            }
          }
        }
      }
      allProductCollectionsJson {
        nodes {
          id
          title
        }
      }
    }
  `);
  const products: ShopifyProduct[] = data.allProductsJson.nodes;
  const collections: ProductCollection[] = data.allProductCollectionsJson?.nodes || [];

  function getAllProducts(): ShopifyProduct[] {
    const brandInfo = BrandUtil.getSiteInfo();
    let allProducts = [...products];
    if (brandInfo.recommendationConfig.hasRecommendations) {
      allProducts.push(RECOMMENDED_PRODUCT_PLACEHOLDER);
    }
    return allProducts;
  }

  function getAllProductCollections(): ProductCollection[] {
    return collections;
  }

  function getProduct(productId: string): ShopifyProduct | undefined {
    const brandInfo = BrandUtil.getSiteInfo();
    let allProducts = [...products];
    if (brandInfo.recommendationConfig.hasRecommendations) {
      allProducts.push(RECOMMENDED_PRODUCT_PLACEHOLDER);
    }
    return allProducts.find(p => p.id === productId);
  }

  function getProductByCollection(productCollectionId: string) {
    return products.filter(p => p.collectionIds.includes(productCollectionId));
  }
  function getLandingPageProduct(): ShopifyProduct | undefined {
    const productSearch = products.filter(p => p.tags && (p.tags.includes('landing_page') || p.tags.includes('headliner')));
    if (productSearch.length === 0) {
      return undefined;
    }
    if (productSearch.length > 1) {
      ClientLogger.warning('useProductData.getLandingPageProduct', `>1 landing_page / headliner found. Using first `);
    }
    return productSearch[0];
  }
  function getLandingPageProducts(): ShopifyProduct[] {
    return products.filter(p => p.tags && p.tags.includes('landing_page'));
  }

  return {
    getProduct,
    getAllProducts,
    getAllProductCollections,
    getProductByCollection,
    getLandingPageProduct,
    getLandingPageProducts,
  };
}
