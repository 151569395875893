import React, { useEffect, useState, useRef, TableHTMLAttributes } from 'react';
import { usePvepApi } from '@apiClient/usePvepApi';
import { Tabs } from '@components/Tabs';
import { ClientLogger } from '@lib/ClientLogger';
import { videoMetadataTabs, videoMetadataActiveTab, videoMetadataInactiveTab, videoMetadataContainer } from './styles.module.scss';
import { DatabaseVideoForm, DatabaseCollectionList, DatabaseVideoList, DatabaseCategoryList } from '@components/VideoAssignments';

type TabsHandle = React.ElementRef<typeof Tabs>;

export const VideoMetadata = () => {
  const api = usePvepApi();
  const tabsRef = useRef<TabsHandle>(null);

  return (
    <div className={videoMetadataContainer}>
      <Tabs
        ref={tabsRef}
        className={videoMetadataTabs}
        tabRootClassName={videoMetadataInactiveTab}
        activeClassName={videoMetadataActiveTab}
        labels={['Add Video', 'Videos', 'Collections', 'Sliders']}
      >
        <DatabaseVideoForm />
        <DatabaseVideoList />
        <DatabaseCollectionList />
        <DatabaseCategoryList />
      </Tabs>
    </div>
  );
};
